import { Dispatch } from "redux";
import { hideSpinner, SHOW_SPINNER, showSpinner } from "./SpinnerAction";

export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const OPEN_DIALOG = "OPEN_DIALOG";

export interface IdialogOptions {
  showMessageFromService?: boolean;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const serverDialogMessage = (message: string, header?: string) => {
  return {
    type: OPEN_DIALOG,
    isSuccess: false,
    message: message,
    header: header,
  };
};

export const serverDialog = (
  serverCallBack: any,
  successMessage: string,
  failMessage: string,
  options: IdialogOptions = {}
) => {
  return async (dispatch: Dispatch) => {
    dispatch(showSpinner());
    try {
      const objMsg = await serverCallBack();
      const msg = typeof objMsg === "object" ? objMsg.data.data : objMsg;
      dispatch(
        openDialog(true, successMessage, options.showMessageFromService && msg)
      );

      options!.onSuccess && options!.onSuccess();
    } catch (e) {
      dispatch(
        openDialog(
          false,
          failMessage,
          e.response && e.response.data && e.response.data.message
        )
      );
      options!.onFail && options!.onFail();
    } finally {
      dispatch(hideSpinner());
    }
  };
};

export const serverLoading = (
  callBack: () => any,
  options: IdialogOptions = {}
): any => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showSpinner());
      await callBack();
      options!.onSuccess && options!.onSuccess();

      dispatch({
        type: SHOW_SPINNER,
      });
    } catch (e) {
      options!.onFail && options!.onFail();
      console.log(
        "Fetch data from server Fail",
        e,
        e && e.response,
        e && e.response && e.response.data
      );
      dispatch(
        openDialog(
          false,
          "Fetch data from server Fail",
          e.response && e.response.data && e.response.data.message
        )
      );
    } finally {
      dispatch(hideSpinner());
    }
  };
};

export const openDialog = (
  isSuccess: boolean,
  message: string,
  description?: string
) => {
  return {
    type: OPEN_DIALOG,
    isSuccess: isSuccess,
    message: message,
    description: description,
  };
};
export const closeDialog = () => {
  return {
    type: CLOSE_DIALOG,
  };
};
