import * as React from "react";
import { useState } from "react";
import { Dialog, Tab, Tabs } from "@material-ui/core";
import { BTextField } from "../basic_componnets/Input";
import { MyCheckBox } from "../basic_componnets/CheckBox";
import "./add-employee-template.scss";
import { IdialogOptions } from "../../../appRedux/actions/ManageApiDialogAction";
import _ from "lodash";

interface IEmployeeTemplateProps {
  onClose: any;
  serverDialog: (
    callBack: any,
    successMsg: string,
    failMsg: string,
    options?: IdialogOptions
  ) => void;
  campaignId: string;
  currentUser: any;
  addUser: any;
  updateUser: any;
}

const fields = {
  "FIELD WORKER": 4,
  "BACK OFFICE": 2,
  "IMAGE ANALYST": 5,
};

export const EmployeeTemplate = React.memo((props: IEmployeeTemplateProps) => {
  const [tab, setTab] = useState(
    props.currentUser.role || fields["FIELD WORKER"]
  );
  const [name, setName] = useState(props.currentUser.fullName || "");
  const [phone, setPhone] = useState(props.currentUser.phoneNumber || "");
  const [verifyPhone, setVerify] = useState(
    props.currentUser.phoneNumber || ""
  );
  const [email, setEmail] = useState(props.currentUser.email || "");
  const [isIRC, setIsIRC] = useState(props.currentUser.irsCertification || false);
  const [isLarviciding, setIsLarviciding] = useState(
    props.currentUser.larvicidingCertification || false
  );
  const [isEntomologist, setisEntomologist] = useState(
    props.currentUser.enthomologyCertification || false
  );

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const handleAddUser = () => {
    const user = {
      fullName: name,
      phoneNumber: phone,
      ...getTheFields(),
    };
    if (_.isEmpty(props.currentUser))
      props.serverDialog(
        () =>
          props.addUser({
            ...user,
            role: tab,
            campaignIds: [props.campaignId],
          }),
        "Create Success",
        "Create Fail",
        { onSuccess: props.onClose }
      );
    else
      props.serverDialog(
        () =>
          props.updateUser(props.currentUser.id, {
            ...user,
            role: props.currentUser.role,
          }),
        "Create Success",
        "Create Fail",
        { onSuccess: props.onClose }
      );
  };

  const getTheFields = () => {
    // eslint-disable-next-line default-case
    switch (tab) {
      case fields["FIELD WORKER"]:
        return {
          isIrs: isIRC,
          isLarviciding: isLarviciding,
          isEntomologist: isEntomologist,
        };
      case fields["BACK OFFICE"]:
        return {
          email,
        };

      case fields["IMAGE ANALYST"]:
        return {
          email,
        };
    }
  };

  const getTheComponent = () => {
    // eslint-disable-next-line default-case
    switch (tab) {
      case fields["FIELD WORKER"]:
        return (
          <FiledWorker
            isIRC={isIRC}
            isLarviciding={isLarviciding}
            isEntomologist={isEntomologist}
            setIsIRC={setIsIRC}
            setIsLarviciding={setIsLarviciding}
            setisEntomologist={setisEntomologist}
          />
        );
      case fields["BACK OFFICE"]:
        return <BackOffice email={email} setEmail={setEmail} />;

      case fields["IMAGE ANALYST"]:
        return <ImageAnalyst email={email} setEmail={setEmail} />;
    }
  };
  const title = _.isEmpty(props.currentUser) ? "Add Employee" : "Edit Employee";

  return (
    <Dialog maxWidth={false} onClose={props.onClose} open={true}>
      <div className="employee-template">
        <div onClick={props.onClose} className="close-template">
          x
        </div>
        <div className="template-form">
          <div className="template-header">{title}</div>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {Object.keys(fields).map((f) => {
              // @ts-ignore
              const value = fields[f];
              return (
                <Tab
                  disabled={!_.isEmpty(props.currentUser)}
                  value={value}
                  key={f}
                  label={f}
                />
              );
            })}
          </Tabs>
          <div className="template-fields">
            <div className="main-fields">
              {BTextField("Full name", name, setName)}
              {BTextField(
                "Phone Number",
                phone,
                setPhone,
                !_.isEmpty(props.currentUser)
              )}
              {BTextField(
                "Verify Phone Number",
                verifyPhone,
                setVerify,
                !_.isEmpty(props.currentUser)
              )}
            </div>
            <div className="second-fields">{getTheComponent()}</div>
          </div>
        </div>
        <div
          onClick={handleAddUser}
          className={`done-btn ${
            !!name && !!phone && phone === verifyPhone
              ? "active-done-btn"
              : "disable-btn"
          }`}
        >
          Done
        </div>
      </div>
    </Dialog>
  );
});

const FiledWorker = React.memo(
  (props: {
    isIRC: boolean;
    setIsIRC: Function;
    isLarviciding: boolean;
    setIsLarviciding: Function;
    isEntomologist: boolean;
    setisEntomologist: Function;
  }) => {
    return (
      <div className="field-worker-tem">
        <MyCheckBox
          checked={props.isIRC}
          onChange={props.setIsIRC}
          label={"IRS Certification"}
        />
        <MyCheckBox
          checked={props.isLarviciding}
          onChange={props.setIsLarviciding}
          label="Larviciding Certification"
        />
        <MyCheckBox
          checked={props.isEntomologist}
          onChange={props.setisEntomologist}
          label={"Entomology Certification"}
        />
      </div>
    );
  }
);

const BackOffice = React.memo(
  (props: { email: string; setEmail: Function }) => {
    return (
      <div className="back-office-tem">
        {BTextField("Email", props.email, props.setEmail)}
      </div>
    );
  }
);

const ImageAnalyst = React.memo(
  (props: { email: string; setEmail: Function }) => {
    return (
      <div className="image-analyst-tem">
        {BTextField("Email", props.email, props.setEmail)}
      </div>
    );
  }
);
