import { convertChunkToPolygon } from "../createVillage/createVillageDrawer/utils";

export const chunkFillColor = (active: string, defined: string) => {
  return [
    "case",
    ["boolean", ["feature-state", "active"], false],
    active,
    defined,
  ];
};

export const getLayer = (sourceId: string, color: any) => {
  return {
    id: sourceId,
    minzoom: 15,
    maxzoom: 24,
    type: "fill",
    source: sourceId,
    paint: {
      "fill-opacity": ["get", "opacity"],
      "fill-color": color,
      "fill-outline-color": "rgb(256,256,256)",
    },
  };
};
export const getSelectedLayer = (sourceId: string) => {
  return {
    id: sourceId,
    minzoom: 15,
    maxzoom: 24,
    type: "line",
    source: sourceId,
    paint: {
      "line-width": 3,
      "line-color": "rgb(30, 203, 224)",
    },
  };
};

export const getSource = (chunks: any) => {
  const features = chunks.map((chunk: any) => toFeature(chunk));
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
};

export const toFeature = (chunk: any) => {
  const bbox = [convertChunkToPolygon(chunk.id)];
  return {
    type: "Feature",
    id: chunk.id,
    geometry: {
      type: "Polygon",
      coordinates: bbox,
    },
    properties: {
      coordinates: [...bbox[0], bbox[0][0]],
      chunkId: chunk.id,
      opacity: chunk.type === "core" ? 0.8 : 0.2,
    },
  };
};
