import React, { Component } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import "./loginComponent.scss";
import LoginForm from "./loginForm";

class LoginComponent extends Component<any, any> {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.context.router.history.push("/");
      return;
    }
  }

  render() {
    return (
      <div className="login-layout">
        <div className="zzapp-logo-text"></div>
        <LoginForm></LoginForm>
        <div className="zzapp-logo-corner">
          <div className="zzapp-logo-fly"></div>
        </div>
      </div>
    );
  }
}

export default injectIntl(LoginComponent);
