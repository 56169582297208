import * as React from "react";
import { useEffect, useState } from "react";
import { TopBar } from "./components/TopBar";
import "./CreateStationMode.scss";
import { StationPoint } from "./components/StationPoint";

interface ICreateStationModeProps {
  map: any;
  campaignId: any;
  villageId: any;
  createVillagePolygon: any;
  serverDialog: any;
  serverDialogMessage: any;
  drawNewVillage: any;
  serverLoading: any;
  addStation: any;
  handleBackFromCreationMode: any;
}
 
export const CreateStationMode = React.memo((props: ICreateStationModeProps) => {
  let description: any;
  let lat: any;
  let lng: any;
  useEffect(() => {
    props.map.on("click", createStationOnLngLat);
    return () => {
      props.map.off("click", createStationOnLngLat)
    }
  });
  
  const createStationOnLngLat = (event: any) => {
    //TODO prevent event propogation
    lat = event.lngLat.lat;
    lng = event.lngLat.lng;
  }


  const deleteStation = () => {
    lat = undefined;
    lng = undefined;
    description = undefined;
  };


  const cancelCreateStationMode = () => {
    deleteStation();
    props.handleBackFromCreationMode();
  };


  const handleCreateStation = () => {
    props.serverDialog(() => props.addStation(props.campaignId, lat, lng, description, () => {}), "Successfully added Station!", "Failed to add Station...");
  };


  const submitCreate = (desc: string) => {
    if (desc === undefined || desc === "") {
      props.serverDialogMessage("You must define a description first!", "Missing station description")
      return;
    }
    if (lat === undefined || lng === undefined) {
      props.serverDialogMessage("Press on the desired location on the map\n in order to assign the station's\n location.", "Missing station location")
      return;
    }
    description = desc;
    handleCreateStation();
    
    cancelCreateStationMode();
  };

  return (
    <div className="create-village-mode">
      <TopBar
        cancelCreate={cancelCreateStationMode}
      />
      <StationPoint
          submitStation={submitCreate}
          deleteStation={deleteStation}
        />    
    </div>
  );
});
