export interface CreatedBy {
  id: string;
  creatorId: string | null;
  campaignId: any;
  role: number;
  fullName: string;
  email: string;
  phoneNumber: number;
  phoneNumber2: number;
  timeCreated: number | null;
  timeUpdated: number | null;
  createdDate: number | null;
  updatedDate: number | null;
  organization: string | null;
  isIrs: boolean | null;
  isLarviciding: boolean | null;
  isEntomologist: boolean | null;
}

export interface Issue {
  id: string;
  createdBy: string;
  reportedOn: number;
  type: IssueType;
}

export enum IssueType {
  NotFound = "not_found",
  NoWater = "no_water",
  NoAccess = "no_access",
  NotPermitted = "not_permitted",
  RefusedAccess = "refused_access",
  Other = "other",
}

export enum WaterActionType {
  Issue,
  Sample,
  Spray
}

export interface WaterAction {
  type: WaterActionType,
  action: Issue | Sample | Spray;
}

export interface Sample {
  id: string;
  createdBy: CreatedBy;
  anopheles: number;
  culex: number;
  pupae: number;
  reportedOn: number;
  expired: number;
}

export enum Size {
  Tiny = "tiny",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export interface Spray {
  id: string;
  createdBy: string;
  reportedOn: number;
  expired: number;
}

export enum WATER_SOURCE_STATUS {
  NEW = "new",
  SPRAYED = "sprayed",
  SAMPLED = "sampled",
  ISSUE = "issue",
  TREATED = "treated",
}

export enum WaterSourceType {
  Agriculture = "agriculture",
  Channel = "channel",
  Construction = "construction",
  Footprint = "footprint",
  Fringe = "fringe",
  Other = "other",
  Pond = "pond",
  Pool = "pool",
  Puddle = "puddle",
  Rice = "rice",
  Swamp = "swamp",
  Tracks = "tracks",
  ContainerWithLid = "container with lid",
  Cistern = "cistern",
  OpenContainer = "open container",
  Rooftop = "rooftop",
  SewerManhole = "sewer manhole",
  Tank = "tank",
  Tire = "tire",
  Ditch = "ditch",
  ManMade = "manmade",
  Well = "well",
}

export interface WaterSource {
  id: string;
  createdBy: CreatedBy;
  label: string | null;
  chunk: string;
  hasWater: boolean;
  isShaded: boolean;
  isPolluted: boolean;
  createdOn: number;
  modifiedOn: number;
  size: Size;
  type: WaterSourceType;
  images: string[];
  center: [number, number];
  samples: Sample[];
  sprays: Spray[];
  issues: Issue[];
  visits: number;
  village: string;
  deleted: boolean | undefined;
  coordinates: any;
  // status: Status;
  // depth:
  // toBeSampled:
  // sourceBy:
  // temporary:
  // isVegetation:
  isSprayed: boolean;
  isSampled: boolean;
  isIssued: boolean;
  currentStatus: WATER_SOURCE_STATUS;
  lastSampledOn: number;
  lastSprayedOn: number;
}
