import {
  getAsciiCodeId,
  getLayerFill,
  getLayerLine,
  getVillagePolygonSource,
  VillageType,
} from "../villagePolygonUtils";
import { MapStyles, whatIsInitStyle, getEmptyGeoJson } from "../../../utils";

class Village_13_14_Drawer {
  map: any;
  villageSourceId = "villageSourcePolygon";
  lineVillageSourceId = "line" + this.villageSourceId;
  villageSource: any;
  villages: any;
  colorFill = {
    terrain: "#fcb07c",
    satellite: "#ffffff",
  };
  colorLine = {
    terrain: "#ffa61b",
    satellite: "#ffe5f5",
  };

  constructor(map: any) {
    this.map = map;
    this.drawVillage();
  }

  addSources = () => {
    this.map.addSource(this.villageSourceId, getEmptyGeoJson());
  };

  addLayers = () => {
    this.map.addLayer(
      getLayerFill(
        this.villageSourceId,
        this.villageSourceId,
        this.colorFill[whatIsInitStyle()]
      )
    );
    this.map.addLayer(
      getLayerLine(
        this.lineVillageSourceId,
        this.villageSourceId,
        this.colorLine[whatIsInitStyle()]
      )
    );
  };

  drawVillage = () => {
    this.addSources();
    this.addLayers();
  };

  onChangeCampaign = (villages: any) => {
    if (this.map.getSource(this.villageSourceId)) {
      this.villageSource = villages;
      this.map
        .getSource(this.villageSourceId)
        .setData(
          getVillagePolygonSource(Object.values(this.villageSource)).data
        );
    }
  };

  onAddVillage = (village: any) => {
    if (this.map.getSource(this.villageSourceId)) {
      this.villageSource[village.id] = village;
      this.map
        .getSource(this.villageSourceId)
        .setData(
          getVillagePolygonSource(Object.values(this.villageSource)).data
        );
    }
  };

  activeVillage = (villageId: string) => {
    this.map.setFeatureState(
      {
        source: this.villageSourceId,
        id: getAsciiCodeId(villageId + VillageType.BELT),
      },
      { active: true }
    );
    this.map.setFeatureState(
      {
        source: this.villageSourceId,
        id: getAsciiCodeId(villageId + VillageType.CORE),
      },
      { active: true }
    );
  };
  clickVillage = (
    villageSelect: (villageId: string) => void,
    features: any
  ) => {
    villageSelect(features[0].properties.id);
  };

  changeColors = (style: MapStyles) => {
    this.map.setPaintProperty(
      this.lineVillageSourceId,
      "line-color",
      this.colorLine[style]
    );
    this.map.setPaintProperty(
      this.villageSourceId,
      "fill-color",
      this.colorFill[style]
    );
  };
}

export default Village_13_14_Drawer;
