import { connect } from "react-redux";
import { HouseSideBar } from "./HouseSideBar";

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    currentHouse: state.houses.houses[ownProps.id],
    users: state.usersReducer.users,
  };
};

export default connect(mapStateToProps, null)(HouseSideBar);
