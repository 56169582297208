import { connect } from "react-redux";
import { ManageServerRequestDialog } from "./ManageServerRequestDialog";
import { Dispatch } from "redux";
import { closeDialog } from "../../../appRedux/actions/ManageApiDialogAction";
import {
  hideSpinner,
  showSpinner,
} from "../../../appRedux/actions/SpinnerAction";

const mapStateToProps = (state: any) => {
  return {
    isOpen: state.manageApiDialog.isOpen,
    isSuccess: state.manageApiDialog.isSuccess,
    message: state.manageApiDialog.message,
    description: state.manageApiDialog.description,
    header: state.manageApiDialog.header,
  };
};

const matchDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeDialog: () => dispatch(closeDialog()),
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ManageServerRequestDialog);
