import { FETCH_HOUSES } from "../actions/CampaignsActions";

const houses = (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_HOUSES:
      return {
        ...state,
        houses: action.houses,
      };
    default:
      return state;
  }
};

export default houses;
