import React, { ComponentClass, useEffect, useState } from "react";
import { Redirect } from "react-router";

export const withAuth = (ComponentToProtect: ComponentClass<any>) => {
  return (props: any) => {
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
      if (localStorage.getItem("token")) setLoading(false);
      else {
        setLoading(false);
        setRedirect(true);
      }
    }, []);

    if (loading) {
      return null;
    }
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <React.Fragment>
        <ComponentToProtect {...props} />
      </React.Fragment>
    );
  };
};
