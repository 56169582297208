import { CLOSE_DIALOG, OPEN_DIALOG } from "../actions/ManageApiDialogAction";

export interface IManageDialogState {
  isOpen: boolean;
  isSuccess: boolean;
  message: string;
  description: string;
  header?: string;
}

const manageApiDialog = (
  state: IManageDialogState = {
    isOpen: false,
    isSuccess: true,
    message: "",
    description: "",
  },
  action: any
) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        isOpen: true,
        isSuccess: action.isSuccess,
        message: action.message,
        description: action.description,
        header: action.header,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
        isSuccess: true,
        message: "",
        description: "",
      };
    default:
      return state;
  }
};

export default manageApiDialog;
