import { getCurrentOrigin } from "../../../../services/GlobalService";
import { getChunkOfWs, getEmptyGeoJson, hideLayout } from "../utils";
import { getFormatDate } from "../../../outsiders/trapResults/TrapResultDialog";
import { wsDrawStatus } from "../waterSource/WaterSourcesDrawer";

class ChunkWaterSourceDrawer {
  private map: any;
  chunkWaterSourceId = "chunkWaterSourceId";
  wss: any;
  statuses: any;
  hideDeleted: boolean;

  constructor(map: any) {
    this.map = map;
    this.statuses = null;
    this.hideDeleted = false;
    this.drawChunkWaterSourcesNo();
    this.initFilters();
  }

  drawChunkWaterSourcesNo = () => {
    this.map.loadImage(
      getCurrentOrigin() + "/images/sources/wss.png",
      (error: any, image: any) => {
        this.map.addImage(this.chunkWaterSourceId, image);
        this.map.addSource(this.chunkWaterSourceId, getEmptyGeoJson());
        this.map.addLayer(
          this.getChunkWaterSourceLayer(this.chunkWaterSourceId)
        );
      }
    );
  };

  onUpdate = (wss: any, saveWss = true) => {
    if (saveWss) this.wss = wss;

    if (this.map.getSource(this.chunkWaterSourceId)) {
      const filterByStatusWs = wss
        .filter((ws: any) => !this.statuses || this.statuses[ws.currentStatus])
        .filter((ws: any) => !this.hideDeleted || !ws.deleted);
      this.map
        .getSource(this.chunkWaterSourceId)
        .setData(
          this.getChunkSource(getChunkOfWs(filterByStatusWs) || []).data
        );
    }
  };
  onChangeCampaign = (chunkWsNumber: any) => {
    this.initFilters();
    const wss = Object.values(chunkWsNumber || []);
    this.onUpdate(wss);
  };

  onFilterByDate = (wss: any) => {
    this.onUpdate(wss);
  };

  onFilterByStatuses = (statuses: string[]) => {
    this.statuses = statuses;
    this.onUpdate(this.wss);
  };

  onFilterByDeletedWs = (mode: boolean) => {
    this.hideDeleted = !mode;
    this.onUpdate(this.wss);
  };

  initFilters = () => {
    this.wss = [];
    this.statuses = wsDrawStatus;
  };

  getChunkWaterSourceLayer = (layerId: string) => {
    return {
      id: layerId,
      minzoom: 14,
      maxzoom: 16,
      type: "symbol",
      source: layerId,
      layout: {
        visibility: "visible",
        "icon-image": layerId,
        "icon-size": 0.7,
        "text-field": ["get", "description"],
        "text-size": 16,
        "text-font": ["Open Sans Bold", "Arial Unicode MS Regular"],
        "text-anchor": "center",
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
        "text-line-height": 1,
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#000000",
        "text-halo-width": 0.5,
      },
    };
  };
  getChunkSource = (chunkWsNumber: any) => {
    const features = chunkWsNumber.map((wsNumber: any) =>
      this.getChunkWsFeature(wsNumber)
    );
    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features,
      },
    };
  };

  getChunkWsFeature = (wsNumber: any) => {
    return {
      type: "Feature",
      properties: {
        description: wsNumber.description,
      },
      geometry: {
        type: "Point",
        coordinates: wsNumber.center,
      },
    };
  };
}

export default ChunkWaterSourceDrawer;
