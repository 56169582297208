import {
  chunkFillColor,
  getLayer,
  getSelectedLayer,
  getSource,
  toFeature,
} from "./utils";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  hideLayout,
  MapStyles,
  showLayout,
  whatIsInitStyle,
} from "../utils";
import { getVillagePolygonSource } from "../village/village_polygon/villagePolygonUtils";

class ChunkPolygonDrawer {
  map: any;
  chunksSource: any;
  chunkSourceId = "chunkSourceId";
  chunkSelectedId = "chunkSelectedId";
  lastZoom: any;
  needToChangeByZoom = true;
  color = {
    terrain: "rgb(252,192,37)",
    satellite: "rgba(255,255,249,0.75)",
  };
  zoom16Color = {
    terrain: "rgba(252,192,37,0.1)",
    satellite: "rgba(251,251,255,0.16)",
  };
  style = whatIsInitStyle();

  constructor(map: any) {
    this.map = map;
    this.lastZoom = this.map.getZoom();
    this.map.on("zoom", this.onZoom15);
    addCursorIconToLayer(this.map, this.chunkSelectedId);
    addCursorIconToLayer(this.map, this.chunkSourceId);

    this.drawChunkPolygon();
  }

  drawChunkPolygon = () => {
    this.map.addSource(this.chunkSourceId, getEmptyGeoJson());
    this.map.addLayer(
      getLayer(this.chunkSourceId, this.color[whatIsInitStyle()])
    );
    this.drawSelected();
  };

  drawSelected = () => {
    this.map.addSource(this.chunkSelectedId, getEmptyGeoJson());
    this.map.addLayer(getSelectedLayer(this.chunkSelectedId));
    hideLayout(this.chunkSelectedId, this.map);
  };

  clickChunk = (chunkSelect: (chunkId: string) => void, features: any) => {
    chunkSelect(features[0].properties.chunkId);
    this.selectChunk(this.chunksSource[features[0].properties.chunkId]);
  };

  selectChunk = (chunk: any) => {
    this.map.getSource(this.chunkSelectedId).setData(toFeature(chunk));
    this.map.moveLayer(this.chunkSourceId, this.chunkSelectedId);
    showLayout(this.chunkSelectedId, this.map);
  };

  activeVillage = (villageId: string) => {
    this.map.setFeatureState(
      { source: this.chunkSourceId, id: villageId },
      { active: true }
    );
  };

  onChangeCampaign = (chunks: any) => {
    if (this.map.getSource(this.chunkSourceId)) {
      this.chunksSource = chunks;
      this.map
        .getSource(this.chunkSourceId)
        .setData(getSource(Object.values(chunks)).data);
      hideLayout(this.chunkSelectedId, this.map);
    }
  };
  onAddVillage = (chunks: any) => {
    if (this.map.getSource(this.chunkSourceId)) {
      this.chunksSource = { ...chunks, ...this.chunksSource };
      this.map
        .getSource(this.chunkSourceId)
        .setData(getSource(Object.values(this.chunksSource)).data);
    }
  };

  onZoom15 = () => {
    const zoom = Math.floor(this.map.getZoom());
    this.needToChangeByZoom = zoom - 15 === 0 || zoom - 16 === 0;
    if (
      zoom !== this.lastZoom &&
      this.needToChangeByZoom &&
      this.map.getLayer(this.chunkSourceId)
    ) {
      this.lastZoom = Math.floor(this.map.getZoom());

      if (this.lastZoom === 15)
        this.map.setPaintProperty(
          this.chunkSourceId,
          "fill-color",
          this.color[this.style]
        );
      else
        this.map.setPaintProperty(
          this.chunkSourceId,
          "fill-color",
          this.zoom16Color[this.style]
        );
    }
  };

  changeColors = (style: MapStyles) => {
    this.style = style;
    this.map.setPaintProperty(
      this.chunkSourceId,
      "fill-color",
      this.color[style]
    );
  };
}

export default ChunkPolygonDrawer;
