import axios from "axios";
import { UpdateUser, User } from "../appRedux/stores/users.interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  },
});

export const getAllUsersOfCampaign = (campaignId: string) => {
  return instance.get(`/users?campaignId=${campaignId}`);
};

export const getUser = (userId: string): Promise<any> => {
  return instance.get(`/users/${userId}`);
};
export const addUser = (newUserData: User): Promise<any> => {
  return instance.post(`/users/`, newUserData);
};

export const updateUser = (id: string, newUserData: UpdateUser) => {
  return instance.put(`/users/${id}`, newUserData);
};

export const deleteUser = (userId: string) => {
  return instance.delete(`/users/${userId}`);
};

export const uploadUsers = (users: FormData) => {
  const headers = {
    "content-type": "multipart/form-data",
    authorization: localStorage.getItem("token"),
    "Campaign-Id": localStorage.getItem("lastCampaign"),
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/upload`,
    users,
    {
      headers: headers,
    }
  );
};
