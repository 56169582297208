import React, { Component } from "react";
import "./mapComponent.scss";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "leaflet";
import "leaflet-graticule";
import PropTypes from "prop-types";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import DrawWaterSource from "./waterSource/WaterSourcesDrawer";
import DrawHouse from "./houses/HousesDrawer";
import { WaterSourcesFilters } from "./waterSource/WaterSourcesFilters";
import { TopBarWsFilter } from "./waterSource/TopBarWsFilter";
import ScanDrawer from "./scan/ScanDrawer";
import ChunkWaterSourceDrawer from "./chunks/ChunkWaterSourceDrawer";
import VillageIconDrawer from "./village/VillageIconDrawer";
import Village_13_14_Drawer from "./village/village_polygon/zoom_13_14/Village_13_14_Drawer";
import ChunkPolygonDrawer from "./chunks/ChunkPolygonDrawer";
import { CreateMode } from "./createVillage/CreateMode";
import CreateVillageShadow from "./createVillage/createVillageDrawer/shadowDrawer";
import { normalize } from "normalizr";
import { chunkSchema } from "../../../appRedux/schemas/Chunk";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@material-ui/core";
import Checkbox from "material-ui/Checkbox";
import {
  drawOptions,
  geocoderOptions,
  getZoom,
  initZoom,
  mapOptions,
  whatIsInitStyle,
  whatIsNotInitStyle,
  whatIsStyleUrl,
} from "./utils";
import { RightBanner } from "../rightBanner/RightBanner";
import { CreateStationMode } from "./stations/CreateStationMode";
import StationsDrawer from "./stations/StationsDrawer";
import SheltersDrawer from "./shelters/SheltersDrawer";

import { StationsFilters } from "./stations/StationsFilters";
import { SheltersFilters } from "./shelters/SheltersFilters";

export class MapComponent extends Component<any, any> {
  hoveredStateId = null;
  shouldReplaceMapStyle = false;
  map: any;
  draw: any;
  geocoder: any;
  waterSourcesDrawer: any;
  housesDrawer: any;
  stationsDrawer: any;
  sheltersDrawer: any;
  createVillagePolygon: any;
  scanDrawer: any;
  chunkWaterSourceDrawer: any;
  villageDrawer: any;
  chunksDrawer: any;
  villagePolygon: any;

  static contextTypes = { router: PropTypes.object };
  static childContextTypes = { muiTheme: PropTypes.object };

  getChildContext() {
    return { muiTheme: getMuiTheme() };
  }

  constructor(props: any) {
    super(props);
    this.state = {
      zoom: initZoom,
      styleMode: whatIsInitStyle(),
      createVillageMode: false,
      createStationMode: false,
      showBar: false,
      startDate: null,
      endDate: null,
    };
  }

  async componentDidMount() {
    mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    if (!this.map) {
      this.map = new mapboxgl.Map(mapOptions);
      this.map.addControl(
        new mapboxgl.NavigationControl({ showCompass: false })
      );

      this.draw = new MapboxDraw(drawOptions);

      this.geocoder = new MapboxGeocoder(geocoderOptions);
      this.map.addControl(this.geocoder);
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserLocation: true
        })
      , 'bottom-left');
      this.autoZoomByVillage(this.props.villageV1);
      await this.onEvents();
      this.loadMap(this.props);
      // @ts-ignore
      window.map = this.map;
    }
  }

  onEvents = () => {
    return new Promise((resolve) => {
      this.map.on("load", () => this.loadLayers(resolve));
      this.map.on("zoom", this.changeZoom);
      this.map.on("mousemove", this.onMouseMove);
      this.map.on("style.load", this.onStyle);
      // this.map.on('mousemove', this.mousemove);
      // this.map.on('mouseleave', this.mouseleave);
    });
  };

  mouseleave = () => {
    if (this.hoveredStateId) {
      this.map.setFeatureState(
        { source: "states", id: this.hoveredStateId },
        { hover: false }
      );
    }
    this.hoveredStateId = null;
  };

  mousemove = (e: any) => {
    if (e.features.length > 0) {
      if (this.hoveredStateId) {
        this.map.setFeatureState(
          { source: "states", id: this.hoveredStateId },
          { hover: false }
        );
      }
      this.hoveredStateId = e.features[0].id;
      console.log(this.hoveredStateId);
      this.map.setFeatureState(
        { source: "states", id: this.hoveredStateId },
        { hover: true }
      );
    }
  };

  loadLayers = (resolve: any) => {
    this.waterSourcesDrawer = new DrawWaterSource(this.map);
    this.housesDrawer = new DrawHouse(this.map);
    this.stationsDrawer = new StationsDrawer(this.map);
    this.sheltersDrawer = new SheltersDrawer(this.map);
    this.villageDrawer = new VillageIconDrawer(this.map);
    this.villagePolygon = new Village_13_14_Drawer(this.map);
    this.chunksDrawer = new ChunkPolygonDrawer(this.map);
    this.chunkWaterSourceDrawer = new ChunkWaterSourceDrawer(this.map);
    this.createVillagePolygon = new CreateVillageShadow(this.map);
    this.scanDrawer = new ScanDrawer(this.map, this.props.downloadVillageScan);
    this.villageDrawer.drawVillages().then(resolve);
  };

  autoZoomByVillage = (villageObj: any) => {
    const villages = Object.values(villageObj);
    if (villages.length > 0) {
      // @ts-ignore
      this.map.setCenter(villages![0].center);
    }
  };

  changeZoom = () => {
    this.setState((prevState: any) => {
      if (prevState.zoom === getZoom(this.map)) {
        return prevState;
      }
      return { ...prevState, zoom: getZoom(this.map) };
    });
  };

  onMouseMove = (e: any) => {
    document.getElementsByClassName(
      "display-long"
    )[0].innerHTML = e.lngLat.lng.toFixed(5);
    document.getElementsByClassName(
      "display-lat"
    )[0].innerHTML = e.lngLat.lat.toFixed(5);
  };

  drawNewVillage = (village: any) => {
    this.villageDrawer.onAddVillage(village);
    this.villagePolygon.onAddVillage(village);
    const chunks = normalize(village.chunks, [chunkSchema]);
    this.chunksDrawer.onAddVillage(chunks.entities.chunks);
  };

  handleCreateVillageMode = () => {
    this.setState({ createVillageMode: true, showBar: false });
    this.map.addControl(this.draw);
    this.map.setZoom(15);
  };

  handleCreateStationMode = () => {
    this.setState({ createStationMode: true, showBar: false });
    this.map.setZoom(15);
  };


  handleBackFromCreationMode = () => {
    this.setState({ createVillageMode: false, showBar: true });
    this.draw.deleteAll();
    this.map.removeControl(this.draw);
    this.createVillagePolygon.onCancelCreation();
  };

  handleBackFromCreationStationMode = () => {
    this.setState({ createStationMode: false, showBar: true });
    //TODO: Change
    this.createVillagePolygon.onCancelCreation();
  };

  getWsFilterComponent = () => {
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <div className="switch-style-switch">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={whatIsInitStyle() !== this.state.styleMode}
                  onChange={this.handleChangeMapStyle}
                />
              }
              label={whatIsNotInitStyle()}
            />
          </FormGroup>
        </div>
        {/* <div className="filter-map-by-date">
        <div className="">
          <DurationPicker endDate={this.state.endDate} startDate={this.state.startDate} onDatesChange={this.handleFilterMapByDate}/>
        </div>
        {this.state.startDate && <div onClick={this.cancelFilter} className="reset-filter">x</div>}
      </div> */}
        <TopBarWsFilter
          users={this.props.users}
          villages={this.props.villageV1}
          zoom={this.state.zoom}
          villageDrawer={this.villageDrawer}
          formatMessage={formatMessage}
        />
        {this.chunkWaterSourceDrawer && (
          <WaterSourcesFilters
            onFilterByDeletedWs={
              this.chunkWaterSourceDrawer.onFilterByDeletedWs
            }
            showDeletedSources={
              this.waterSourcesDrawer.setLayerDeletedWsVisibility
            }
            zoom={this.state.zoom}
            showChunkWsLayer={this.chunkWaterSourceDrawer.onFilterByStatuses}
            showScanLayers={this.scanDrawer.setLayerVisibility}
            showWSLayers={this.waterSourcesDrawer.setLayerVisibility}
            formatMessage={formatMessage}
          />
        )}
        {this.stationsDrawer && (
        <StationsFilters
          showStationsLayer={this.stationsDrawer.setLayerVisibility}
          zoom={this.state.zoom}
          formatMessage={formatMessage}
          />)}
        {this.sheltersDrawer && (
        <SheltersFilters
          showSheltersLayer={this.sheltersDrawer.setLayerVisibility}
          zoom={this.state.zoom}
          formatMessage={formatMessage}
          />)}
      </React.Fragment>
    );
  };

  cancelFilter = async () => {
    await this.props.removeRangeVillageAction();
    this.loadNewDraw(this.props);
  };

  handleCheckHouses = (event: any) => {};

  getShowHouses = () => {
    return (
      <div className="display-house-container">
        <Tooltip title={"Show houses"}>
          <Checkbox onCheck={this.handleCheckHouses} color="primary" />
        </Tooltip>
      </div>
    );
  };

  render() {
    return (
      <div className="map-page">
        <div className="map-container">
          <div className="map-layout-container">
            <div
              className={`map-layout ${
                this.state.createVillageMode || this.state.createStationMode ? "map-layout-create-mode" : ""
              }`}
              id="map-layout"
            >
              {this.getZoomDetails()}
              {this.state.createVillageMode ? (
                <CreateMode
                  campaignId={this.props.campaignId}
                  serverDialog={this.props.serverDialog}
                  handleBackFromCreationMode={this.handleBackFromCreationMode}
                  map={this.map}
                  serverLoading={this.props.serverLoading}
                  AddVillageAction={this.props.AddVillageAction}
                  serverDialogMessage={this.props.serverDialogMessage}
                  drawNewVillage={this.drawNewVillage}
                  createVillagePolygon={this.createVillagePolygon}
                  draw={this.draw}
                />
              ) :
              this.state.createStationMode ? (
                <CreateStationMode
                campaignId={this.props.campaignId}
                villageId={this.props.villageV1}
                serverDialog={this.props.serverDialog}
                handleBackFromCreationMode={this.handleBackFromCreationStationMode}
                map={this.map}
                serverLoading={this.props.serverLoading}
                addStation={this.props.addStation}
                serverDialogMessage={this.props.serverDialogMessage}
                drawNewVillage={this.drawNewVillage}
                createVillagePolygon={this.createVillagePolygon}
              />
              ) :
               (
                this.getWsFilterComponent()
              )}
            </div>
          </div>
          {this.state.showBar && this.props.campaignId && (
            <RightBanner
              intl={this.props.intl}
              campaignId={this.props.campaignId}
              villageV1={this.props.villageV1}
              map={this.map}
              waterSourcesDrawer={this.waterSourcesDrawer}
              housesDrawer={this.housesDrawer}
              stationsDrawer={this.stationsDrawer}
              villageDrawer={this.villageDrawer}
              villagePolygon={this.villagePolygon}
              chunksDrawer={this.chunksDrawer}
              scanDrawer={this.scanDrawer}
              handleCreateVillageMode={this.handleCreateVillageMode}
              handleCreateStationMode={this.handleCreateStationMode}
              assignmentId={this.props.assignmentId}
              assignmentTitle={this.props.assignmentTitle}
              taskType={this.props.taskType}
              taskSummary={this.props.taskSummary}
              scans={this.props.scans}
            />
          )}
        </div>
      </div>
    );
  }

  getZoomDetails = () => {
    return (
      <div className="display-zoom-level">
        <div className="display-long">
          {this.map && this.map.getCenter().lng.toFixed(5)}
        </div>
        <div className="display-lat">
          {this.map && this.map.getCenter().lat.toFixed(5)}
        </div>
        <div className="zoom">{this.state.zoom}</div>
      </div>
    );
  };

  handleChangeMapStyle = (event: any) => {
    const style = event.target.checked
      ? whatIsNotInitStyle()
      : whatIsInitStyle();
    this.setState({ styleMode: style });
    this.shouldReplaceMapStyle = true;
    this.map.setStyle(whatIsStyleUrl(style));
  };

  onStyle = () => {
    if (this.shouldReplaceMapStyle) {
      this.shouldReplaceMapStyle = false;

      const onLayerUpdated = () => {
        setTimeout(() => {
          this.loadNewDraw(this.props);
          this.changeMapColors();
        }, 500);
      };
      this.loadLayers(onLayerUpdated);
    }
  };

  changeMapColors = () => {
    this.chunksDrawer.changeColors(this.state.styleMode);
    this.villagePolygon.changeColors(this.state.styleMode);
  };

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.campaignId !== this.props.campaignId ||
      nextProps.assignmentId !== this.props.assignmentId ||
      Object.keys(nextProps.stations).length !== Object.keys(this.props.stations).length
    ) {
      if (nextProps.assignmentId) {
        this.map.setZoom(16);
      }
      this.loadMap(nextProps);
    }
  }

  loadMap = (props: any) => {
    if (props.villageV1) {
      this.setState({ showBar: true });
      this.loadNewDraw(props);
      if (Object.keys(props.stations).length === Object.keys(this.props.stations).length){
        this.autoZoomByVillage(props.villageV1);
      }
    }
  };

  loadNewDraw = ({
    villageV1,
    chunks_v2,
    waterSources,
    houses,
    stations,
    shelters
  }: any) => {
    if (this.villageDrawer) {
      this.villagePolygon.onChangeCampaign(villageV1);
      this.chunkWaterSourceDrawer.onChangeCampaign(waterSources);
      this.chunksDrawer.onChangeCampaign(chunks_v2);
      this.waterSourcesDrawer.onChangeCampaign(waterSources);
      this.props.refreshScanDownloaded();
      this.scanDrawer.onChangeCampaign();
      this.villageDrawer.onChangeCampaign(villageV1);
      this.housesDrawer.onChangeCampaign(houses);
      this.stationsDrawer.onChangeCampaign(stations);
      this.sheltersDrawer.onChangeCampaign(shelters);
    }
    this.setState({ startDate: null, endDate: null });
  };
}
