import * as React from "react";
import { useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";

import clsx from "clsx";
import {
  AlarmOn,
  AssignmentInd,
  AssignmentReturned,
  AvTimer,
  ContactMail,
  Done,
  Explore,
  FirstPage,
  Home,
  HourglassEmpty,
  LastPage,
  MyLocation,
  Schedule,
  Send,
  SupervisorAccount,
  Timer,
  TimerOff
} from "@material-ui/icons";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  getDateAndTime,
  getFullFormatDate,
  getSubctractedYearDateAndTime,
  getSubstractedYearFormatDate
} from "./TaskDialog";

import { StatusTask, WorkTypeTask } from "../../../Enums";
import { connect } from "react-redux";
import { cancelTaskAction, setAssignmentStatus } from "../../../appRedux/actions/TaskActions";
import { fetchAssignmentStats } from "../../../appRedux/actions/CampaignsActions";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { blue } from "@material-ui/core/colors";

import { ReactComponent as ScannedIcon } from "../../../../src/components/outsiders/tasks/ic_scanned.svg";
import { ReactComponent as NewWaterIcon } from "../../../../src/components/outsiders/tasks/ic_water_source_new.svg";
import {
  ReactComponent as SampledWaterIcon
} from "../../../../src/components/outsiders/tasks/ic_water_source_sampled.svg";
import {
  ReactComponent as SprayedWaterIcon
} from "../../../../src/components/outsiders/tasks/ic_water_source_sprayed.svg";
import {
  ReactComponent as IssuedWaterIcon
} from "../../../../src/components/outsiders/tasks/ic_water_source_issue.svg";
import { ReactComponent as HousesIcon } from "../../../../src/components/outsiders/tasks/ic_houses.svg";
import { capitalizeFirstLetter, formatDuration } from "../../../Formatting";
import YesOrNoTemplate from "../../templates/yes-or-no-template/yes-or-no-template";
import ErrorTemplate from "../../templates/error-template/error-template";
import moment from "moment";

interface ITasksTableProps {
  villages: any;
  users: any;
  tasks: any;
  campaignId: any;
  getVillageNameById: any;
  addAssignment: any;
  serverDialog: any;
  serverDialogMessage: any;
  handleTaskReview: any;
  history: any;
  error?: string;
  handleError: any;
}

interface TaskCardProps {
  taskId: any;
  index: number; //ui only
  campaignId: any;
  handleTaskReview: any;
  villageName: any;
  villageId: any;
  summary: any;
  workerName: any;
  status: StatusTask;
  taskType: WorkTypeTask;
  isExpiry: any;
  startOn: any;
  dueOn: any;
  completedOn: any;
  estimatedTime: any;
  modifiedOn: any;
  assignedDate: any;
  fetchedDate: any;
  startedDate: any;
  reviewState?: any;
  activeAssignment?: any;
  assignments?: any;
  users: any;
  serverDialog: any;
  serverDialogMessage: any;
  addAssignment: any;
  getUserFullName: any;
}

const statusButtonLabels: { [key in StatusTask]?: string } = {
  [StatusTask.COMPLETED]: "COMPLETE",
  [StatusTask.CANCELLED]: "CANCEL",
  [StatusTask.APPROVED]: "APPROVE",
};

const nextStatus: { [key in StatusTask]: StatusTask[] } = {
  [StatusTask.UNKNOWN]: [],
  [StatusTask.PENDING]: [StatusTask.CANCELLED],
  [StatusTask.WIP]: [],
  [StatusTask.COMPLETED]: [
    StatusTask.APPROVED,
    StatusTask.CONTINUE,
    StatusTask.REDO,
  ],
  [StatusTask.CANCELLED]: [],
  [StatusTask.QA]: [],
  [StatusTask.REDO]: [],
  [StatusTask.APPROVED]: [],
  [StatusTask.AUTO_APPROVED]: [],
  [StatusTask.CONTINUE]: [],
};

const statusDialogButton: { [key in StatusTask]?: any} = {
  [StatusTask.REDO]: "Redo will cancel all the work done in the task except addition of new water sources and new houses.\n Are you sure to proceed?",
  [StatusTask.APPROVED]: "Approve will accept the work done by the worker.\nAre you sure to proceed?",
  [StatusTask.CANCELLED]: "The task will be closed only if it wasn't downloaded by the worker.\nAre you sure to proceed?"
};


export const buttonColor = (color: string) => ({
  color: color,
  borderColor: color + "80",
  "&:hover": {
    color: color,
    borderColor: color,
    backgroundColor: color + "0a",
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      border: "0.5px solid grey",
      borderRadius: "2px",
      marginBottom: "10px",
      textAlign: "left",
      background: "#f8f8f8",
    },
    cardcontent: {
      "&:last-child": {
        paddingBottom: 5,
      },
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    button: {
      margin: theme.spacing(0.5),
      flex: 1,
      whiteSpace: "nowrap",
    },
    dividerContainer: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    border: {
      borderBottom: "2px solid lightgray",
      width: "100%",
    },
    content: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 500,
      fontSize: 22,
      color: "lightgray",
    },
    filter: {
      width: "120px",
      marginRight: theme.spacing(1),
    },
    mapButton: {
      color: blue[500],
      "&:hover": {
        color: blue[900],
      },
    },
    approve: buttonColor("#6dd400"),
    continue: buttonColor(blue[500]),
    cancel: buttonColor("#ff554b"),
    redo: buttonColor("#ff554b"),
  })
);


function SimpleCard(props: TaskCardProps) {
  const classes = useStyles();
  const [usersSelcted, setUsersSelcted] = useState<{ [key: number]: number }>(
    {}
  );
  const [reviewComment, setReviewCommentText] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [hasComment, setHasComment] = useState(undefined);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState<StatusTask | undefined>(undefined);
  const confirmDialogAction = useRef<Function | null>(null);

  const canExpand = props.assignments && props.assignments.length > 0;

  let expired = Date.now() > props.dueOn && props.isExpiry;
  let showMap = props.status != StatusTask.PENDING;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowConfirmDialog = (status: boolean) => {
    setShowConfirmDialog(status);
  }

  const handleConfirmStatus = (status?: StatusTask) => {
    setConfirmStatus(status);
  }
  const callConfirmDialog = (status: StatusTask | undefined, confirm: Function): any => {
    handleShowConfirmDialog(true);
    handleConfirmStatus(status);
    confirmDialogAction.current = confirm;
  }

  const resetReviewActionDialog = () => {
    handleConfirmStatus(undefined);
    handleShowConfirmDialog(false);
    confirmDialogAction.current = null;
  }

  const reviewButtons = (props: TaskCardProps) => {
    return (
      <Grid>
        <Grid container direction="row">
          <Grid item xs={12}>
            {showMap && (<Button
              className={`${classes.button}`}
              style={{ maxWidth: 170, width: 168, backgroundColor: '#FBC13B', color: 'black'}}
              variant="contained"
              color="primary"
              value="Review"
              onClick={(e) => {
                e.stopPropagation();
                props.handleTaskReview();
              }}>Review</Button>)}
          </Grid>
          <Grid item>
            {isReviewable(props) && renderReviewButtons(props)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const isReviewable = (props: TaskCardProps) => {
    if (
      [
        StatusTask.UNKNOWN,
        StatusTask.CANCELLED,
        StatusTask.REDO,
        StatusTask.APPROVED,
        StatusTask.CONTINUE,
        StatusTask.AUTO_APPROVED,
      ].includes(props.status)
    ) {
      return false;
    }
    if (props.status === StatusTask.PENDING) {
      return true;
    }
    return props.activeAssignment !== undefined;
  };

  const renderReviewButtons = (props: TaskCardProps) => {
    var indents: JSX.Element[] = [];
    var qaTask =
      props.taskType == WorkTypeTask.qaSurvey ||
      props.taskType == WorkTypeTask.qaSpray;

    nextStatus[props.status].map((status: StatusTask) => {
      if (status === StatusTask.QA && !qaTask) {
        return;
      }

      if (props.status === StatusTask.PENDING &&
        (props.completedOn !== "----" &&
          props.fetchedDate !== "----" &&
          moment(props.fetchedDate, "DD/MM/YY HH:mm").isAfter(moment(props.completedOn, "DD/MM/YY HH:mm")))) {
        return;
      }

      indents.push(
        <ButtonActionReview
          serverDialogMessage={props.serverDialogMessage}
          campaignId={props.campaignId}
          taskId={props.taskId}
          comment={reviewComment}
          confirmDialog={callConfirmDialog}
          actionType={status}
          assignmentId={
            [StatusTask.UNKNOWN, StatusTask.PENDING, StatusTask.WIP].includes(
              props.status
            )
              ? -1
              : props.activeAssignment.id
          }
          setHasComment={setHasComment}
        />
      );
    });
    return indents;
  };

  const reviewHistoryBlock = (props: TaskCardProps) => {
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          {/* TODO should only be supervisor account when it's a Team Leader variable set to true */}
          <CustomTypography icon={SupervisorAccount} body={"Reviewer Name: "} />
          <CustomTypography icon={SupervisorAccount} body={"Eugene"} />
        </Grid>
        <Grid item xs={12}>
          <CustomTypography body={"Review Date: "} />
          <CustomTypography body={"1/1/2023"} />
        </Grid>
        <Grid item xs={12}>
          <CustomTypography body={"Comment: "} />
          <CustomTypography
            wrap
            variant={"body"}
            style={{ fontStyle: "italic" }}
            body={"He did great"}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTypography body={"Recommendation: "} />
          <CustomTypography
            wrapx
            variant={"subtitle2"}
            body={
              "Recommend pay raise. Maybe also talk to him about promotion."
            }
          />
        </Grid>
      </Grid>
    );
  };

  const additionalInfoCompletedAssignment = (props: TaskCardProps, type: string) => {
    switch (type) {
      case "house":
        const newHouses = props.summary.newHouse.reduce((acc: number, curr: any) => acc + curr.count, 0);
        const existingHouses = props.summary.existingHouse.reduce((acc: number, curr: any) => acc + curr.count, 0)

        switch (props.taskType) {
          case WorkTypeTask.sample:
          case WorkTypeTask.spray:
          case WorkTypeTask.survey:
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <CustomTypography
                    svgicon={
                      <SvgIcon component={HousesIcon} viewBox="0 0 25 36" />
                    }
                    title={"Existing Houses (New Houses)"}
                    body={existingHouses + " (+" + newHouses + ")"}
                  />
                </Grid>
              </Grid>
            );
        }
        break;
      case "water":
        switch (props.taskType) {
          case WorkTypeTask.sample:
          case WorkTypeTask.spray:
          case WorkTypeTask.survey:
            /**
             * 1. Survey - New WS, sampled WS, sprayed WS, issued WS
              2. Spray - New WS, sprayed WS, issued WS
              3. Sample - New WS, sampled WS, issued WS
             */
            let existingWaterSourceCount = props.summary.waterSourceCountBefore + props.summary.newWaterSourceCount;
            let existingSampledWaterSourceCount = props.summary.sampledWaterSourceCountBefore + props.summary.sampleResult.sampledWaterSourceCount;
            let existingIssuedWaterSourceCount = props.summary.issuedWaterSourceCountBefore + props.summary.issuedWaterSource.reduce((acc: number, curr: any) => acc + curr.count, 0)
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <CustomTypography
                    svgicon={
                      <SvgIcon component={NewWaterIcon} viewBox="0 0 25 36" />
                    }
                    title={"Existing Water Sources (New Water Sources)"}
                    body={existingWaterSourceCount + " (+" + props.summary.newWaterSourceCount + ")"}
                  />
                </Grid>
                {(props.taskType === WorkTypeTask.spray || props.taskType === WorkTypeTask.survey) &&
                  <Grid item xs={12}>
                    <CustomTypography
                      svgicon={
                        <SvgIcon component={SprayedWaterIcon} viewBox="0 0 25 36" />
                      }
                      title={"Sprayed Water Sources"}
                      body={props.summary.sprayedWaterSourceCount + " (" + props.summary.sprayedWaterSourcePercentage.toFixed(2) + "%)"}
                    />
                  </Grid>
                }
                {(props.taskType === WorkTypeTask.sample || props.taskType === WorkTypeTask.survey) &&
                  <Grid item xs={12}>
                    <CustomTypography
                      svgicon={
                        <SvgIcon component={SampledWaterIcon} viewBox="0 0 28 42" />
                      }
                      title={"Sampled Water Sources"}
                      body={existingSampledWaterSourceCount + " (+" + props.summary.sampleResult.sampledWaterSourceCount + ")"}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <CustomTypography
                    svgicon={
                      <SvgIcon component={IssuedWaterIcon} viewBox="0 0 25 36" />
                    }
                    title={"Issued Water Sources"}
                    body={existingIssuedWaterSourceCount + " (+" + props.summary.issuedWaterSource.reduce((acc: number, curr: any) => acc + curr.count, 0) + ")"}
                  />
                </Grid>
              </Grid>
            );
        }
        break;
      case "survey":
        return (
          <Grid container direction="row">
            <Grid item xs={12}>
              <CustomTypography
                svgicon={
                  <SvgIcon component={ScannedIcon} style={{ color: "#FBC13B" }} viewBox="0 96 960 960" />
                }
                title={"Surveyed Area"}
                body={
                  props.summary.surveyedArea + "ha, " +
                  props.summary.surveyedPercentage.toFixed(2) +
                  "%"
                }
              />
            </Grid>
          </Grid>
        );
    }
  }

  const handleSelectedUser = (e: any, taskId: number) => {
    const userId = e.target.value;
    if (userId) {
      setUsersSelcted((prevState) => ({
        ...prevState,
        [taskId]: userId,
      }));
    }
  };

  const handleClickSend = (taskId: number) => {
    if (usersSelcted[taskId]) {
      props.serverDialog(() =>
        props.addAssignment({ userId: usersSelcted[taskId], taskId })
      );
    } else {
      props.serverDialogMessage(`Please select user to assign task`);
    }
  };

  const handleReviewTextAppend = (e: any) => {
    if (e == !undefined) {
      return;
    }
    const userReviewText = e.target.value;
    if (userReviewText !== undefined || userReviewText !== "") {
      setReviewCommentText(e.target.value);
    }
  };

  const assignmentSelect = (props: TaskCardProps) => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <FormControl>
            <InputLabel>Assign</InputLabel>
            <Select
              style={{ width: "120px" }}
              value={usersSelcted[props.taskId]}
              id="demo-simple-select"
              fullWidth
              onChange={(e) => handleSelectedUser(e, props.taskId)}
            >
              {props.users.map((user: any, i: number) => (
                <MenuItem key={i} value={user.id}>
                  {" "}
                  {user.fullName}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <IconButton onClick={(event: any) => handleClickSend(props.taskId)}>
            <Send fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const getColorForStatus = (status: StatusTask, expired: boolean) => {
    switch (status) {
      case StatusTask.PENDING:
        if (expired) return "#33FF554B";
        return "#ff554b";
      case StatusTask.WIP:
        return "#fbc13b";
      case StatusTask.CANCELLED:
        return "#000000";
      default:
        return "#6dd400";
    }
  };

  return (
    <>
    <YesOrNoTemplate
      open={showConfirmDialog}
      textMessage={confirmStatus ? statusDialogButton[confirmStatus] : ""}
      close={() => {
        resetReviewActionDialog();
      }}
      continue={() => {
        if (confirmDialogAction.current) {
          confirmDialogAction.current();
          resetReviewActionDialog();
        }
      }}
    />
    <Card
      className={`${classes.root}`}
      elevation={isRecent(props.modifiedOn) ? 3 : 1}
      onClick={canExpand ? handleExpandClick : undefined}
    >
      <CardContent className={`${classes.cardcontent}`}>
        {/* main container */}
        <Grid container>
          {/* the xs defines the width of the block */}
          <Grid item xs={2}>
            {/* first block container */}
            <Grid container direction="row" spacing={0}>
              <Grid item xs={6}>
                <CustomTypography
                  icon={showMap ? Explore : Home}
                  className={showMap && classes.mapButton}
                  onClick={showMap && (() => props.handleTaskReview())}
                  title={
                    props.villageName +
                    " (" +
                    props.taskId +
                    ", map=" +
                    showMap +
                    ")"
                  }
                  body={props.villageName}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTypography
                  icon={MyLocation}
                  body={WorkTypeTask[props.taskType]}
                  title="Task Type"
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTypography
                  icon={ContactMail}
                  body={props.workerName}
                  title="Worker Name"
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTypography
                  icon={Schedule}
                  body={formatStatus(StatusTask[props.status])}
                  style={{ color: getColorForStatus(props.status, expired) }}
                  title="Status"
                />
              </Grid>
              <Grid
                item
                style={{ marginLeft: "-15px", marginTop: "-10px" }}
                xs={6}
              >
                {canExpand && (
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <Tooltip title="Show Assignments">
                      <ExpandMoreIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* xs = width of the block */}
          <Grid item xs={1}>
            {/* second block container */}
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12}>
                <CustomTypography
                  icon={props.isExpiry ? Timer : TimerOff}
                  title={props.isExpiry ? "Will expire" : "Never expires"}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="ETA"
                  icon={HourglassEmpty}
                  body={props.estimatedTime}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="Duration"
                  icon={AvTimer}
                  body={
                    props.assignments
                      ? formatDuration(
                          sumAssignmentsStartedOn(props.assignments),
                          sumAssignmentsCompletedOn(props.assignments)
                        )
                      : "----"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/* xs = width of the block */}
          <Grid item xs={1}>
            {/* third block container */}
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12}>
                <CustomTypography
                  title="Start On"
                  icon={FirstPage}
                  body={getSubstractedYearFormatDate(props.startOn)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="Due On"
                  icon={LastPage}
                  body={getSubstractedYearFormatDate(props.dueOn)}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* xs = width of the block */}
          <Grid item xs={1}>
            {/* fourth block container */}
            <Grid container direction="row">
              <Grid item xs={12}>
                <CustomTypography
                  title="Assigned on"
                  icon={AssignmentInd}
                  body={getSubctractedYearDateAndTime(props.assignedDate)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="Fetched on"
                  icon={AssignmentReturned}
                  body={getSubctractedYearDateAndTime(props.fetchedDate)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="Started on"
                  icon={AlarmOn}
                  body={getSubctractedYearDateAndTime(props.startedDate)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTypography
                  title="Completed On"
                  icon={Done}
                  body={getSubctractedYearDateAndTime(props.completedOn)}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Task Statistics */}
          <Grid item xs={1}>
            {props.activeAssignment &&
              props.activeAssignment.status !== StatusTask.CANCELLED &&
              props.activeAssignment.status !== StatusTask.PENDING &&
              props.summary &&
              additionalInfoCompletedAssignment(props, "water")}
          </Grid>
          <Grid item xs={1}>
            {props.activeAssignment &&
              props.activeAssignment.status !== StatusTask.CANCELLED &&
              props.activeAssignment.status !== StatusTask.PENDING &&
              props.summary &&
              additionalInfoCompletedAssignment(props, "house")}
          </Grid>
          <Grid item xs={2}>
            {props.activeAssignment &&
              props.activeAssignment.status !== StatusTask.CANCELLED &&
              props.activeAssignment.status !== StatusTask.PENDING &&
              props.summary && (props.taskType === WorkTypeTask.qaSurvey || props.taskType == WorkTypeTask.survey) &&
              additionalInfoCompletedAssignment(props, "survey")}
          </Grid>
          {/* Review buttons */}
          <Grid item xs={2}>
            {reviewButtons(props)}
            {props.assignments &&
              props.assignments.length === 0 &&
              props.status !== StatusTask.CANCELLED &&
              assignmentSelect(props)}
          </Grid>
        </Grid>
        {/* Assignments history block */}
        {props.assignments && props.activeAssignment ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {expanded ? (
                <AssignmentsHistory
                  campaignId={props.campaignId}
                  villageId={props.villageId}
                  assignments={props.assignments}
                  activeAssignment={props.activeAssignment}
                  getUserFullName={props.getUserFullName}
                />
              ) : (
                undefined
              )}
            </CardContent>
          </Collapse>
        ) : (
          undefined
        )}
      </CardContent>
    </Card>
    </>
  );
}

export default React.memo((props: ITasksTableProps) => {
  const classes = useStyles();
  const [order, setOrder] = useState("");
  const [isAsc, setIsAsc] = useState(false);
  const [userFilterSelcted, setUserFilterSelcted] = useState("all");
  const [villageFilterSelcted, setVillageFilterSelcted] = useState("all");
  const [statusFilterSelcted, setStatusFilterSelcted] = useState("all");
  const [workTypeFilterSelcted, setWorkTypeFilterSelcted] = useState("all");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const handleSetOrder = (e: any) => {
    const role = e.target.getAttribute("role");
    setIsAsc((prevState) => (role === order ? !prevState : true));
    setOrder(role);
  };

  const getUserFullName = (userId: number) => {
    const user = props.users.find((user: any) => user.id == userId);
    if (user) return user.fullName;
    return "";
  };

  const compare = (a: any, b: any) => {
    if (a[order] === undefined) {
      return 1;
    }

    if (b[order] === undefined) {
      return -1;
    }

    if (a[order] === b[order]) {
      return 0;
    }

    return (a[order] < b[order] ? 1 : -1) * (isAsc ? -1 : 1);
  };

  const filterTasks = (task: any) => {
    let check = [];
    if (userFilterSelcted !== "all") {
      if (task.activeAssignment) {
        check.push(task.activeAssignment["userId"] == userFilterSelcted);
      } else {
        check.push(false)
      }
    }
    if (villageFilterSelcted !== "all") {
      check.push(task["villageId"] === villageFilterSelcted);
    }
    if (statusFilterSelcted !== "all") {
      check.push(task["status"] === statusFilterSelcted);
    }
    if (workTypeFilterSelcted !== "all") {
      check.push(task["workType"] === workTypeFilterSelcted);
    }

    if (endDateFilter !== "" && startDateFilter !== "") {
      let startDate = new Date(startDateFilter);
      let endDate = new Date(endDateFilter);
      let taskTimestamp = new Date(task.startOn);

      const dayAfterEndDate = new Date(endDate);
      dayAfterEndDate.setDate(endDate.getDate() + 1);

      check.push(taskTimestamp >= startDate && taskTimestamp < dayAfterEndDate);
    }

    return check.every((value: boolean) => value);
  };

  function createCard(task: any, index: number): any {
    const completedOn = task.activeAssignment ? task.activeAssignment.completedOn : undefined;

    return (
      <SimpleCard
        key={task.id}
        taskId={task.id}
        index={index}
        campaignId={props.campaignId}
        users={props.users}
        villageName={props.getVillageNameById(task.villageId)}
        villageId={task.villageId}
        summary={task.summary}
        workerName={
          task.activeAssignment && task.activeAssignment.userId
            ? getUserFullName(task.activeAssignment.userId)
            : ""
        }
        getUserFullName={getUserFullName}
        status={task.status}
        taskType={task.workType}
        isExpiry={task.isExpiry} //<Checkbox checked=
        startOn={getFullFormatDate(task.startOn)}
        dueOn={getFullFormatDate(task.dueOn)}
        completedOn={getDateAndTime(completedOn)}
        modifiedOn={task.modifiedOn}
        estimatedTime={task.estimatedTime}
        assignedDate={getDateAndTime(
          task.activeAssignment ? task.activeAssignment.assignedOn : undefined
        )}
        fetchedDate={getDateAndTime(
          task.activeAssignment ? task.activeAssignment.fetchedOn : undefined
        )}
        startedDate={getDateAndTime(
          task.activeAssignment ? task.activeAssignment.startedOn : undefined
        )}
        activeAssignment={task.activeAssignment}
        assignments={task.assignments}
        handleTaskReview={() =>
          props.handleTaskReview(
            props.history,
            props.campaignId,
            task.activeAssignment ? task.activeAssignment.id : -1,
            task.workType,
            task.summary
          )
        }
        serverDialog={props.serverDialog}
        serverDialogMessage={props.serverDialogMessage}
        addAssignment={props.addAssignment}
      />
    );
  }

  const Filter = (props: any) => (
    <FormControl className={classes.filter}>
      <InputLabel>Filter {props.label}</InputLabel>
      <Select
        value={props.value}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        <MenuItem key={"all"} value={"all"}>
          {" all "}
        </MenuItem>
        {props.items.map((item: any, i: number) => (
          <MenuItem key={i} value={item.id}>
            {" "}
            {item.fullName || item.name}{" "}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <MuiThemeProvider>
      <div className="task-table">
        <ErrorTemplate
          open={props.error !== undefined}
          errorMessage={props.error}
          close={() => {
            props.handleError();
          }}
        />
        <Filter
          label="User"
          value={userFilterSelcted}
          onChange={setUserFilterSelcted}
          items={props.users}
        />
        <Filter
          label="Village"
          value={villageFilterSelcted}
          onChange={setVillageFilterSelcted}
          items={props.villages}
        />
        <Filter
          label="Work Type"
          value={workTypeFilterSelcted}
          onChange={setWorkTypeFilterSelcted}
          items={Object.keys(WorkTypeTask)
            .filter((k) => isNaN(Number(k)))
            .map((k: any) => ({ id: WorkTypeTask[k], name: k }))}
        />
        <Filter
          label="Status"
          value={statusFilterSelcted}
          onChange={setStatusFilterSelcted}
          items={Object.keys(StatusTask)
            .filter((k) => isNaN(Number(k)))
            .map((k: any) => ({ id: StatusTask[k], name: formatStatus(k) }))}
        />
        <TextField
          className={classes.filter}
          id="status-filter-date-from"
          label="Start Date"
          type="date"
          value={startDateFilter}
          onChange={(e: any) => setStartDateFilter(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          className={classes.filter}
          id="status-filter-date-to"
          label="End Date"
          type="date"
          value={endDateFilter}
          onChange={(e: any) => setEndDateFilter(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <div className="task-cards">
          <DividerWithText>Recent 20 minutes</DividerWithText>
          {Object.values(props.tasks)
            .filter(filterTasks)
            .filter((task: any) => isRecent(task.modifiedOn))
            .sort(compare)
            //creation date filter
            .sort((a: any, b: any) => {
              return a.modifiedOn > b.modifiedOn ? -1 : 1;
            })
            .map((task: any, index: number) => createCard(task, index++))}
          <DividerWithText>All tasks</DividerWithText>
          {Object.values(props.tasks)
            .filter(filterTasks)
            .filter((task: any) => !isRecent(task.modifiedOn))
            .sort(compare)
            //creation date filter
            .sort((a: any, b: any) => {
              return a.modifiedOn > b.modifiedOn ? -1 : 1;
            })
            .map((task: any, index: number) => createCard(task, index++))}
        </div>
      </div>
    </MuiThemeProvider>
  );
});


/**
 * UTILITY FUNCTIONS
 */
const isRecent = (modifiedOn: any) => {
  var diff = Date.now() - new Date(modifiedOn).getTime();
  var diffMins = diff / 60000;
  return diffMins <= 20;
};

function sumAssignmentsStartedOn(assignments: any): any {
  if (assignments.length === 0) {
    return 0;
  }
  return assignments.reduce(
    (partialSum: number, assignment: any) =>
      partialSum + (assignment.startedOn ? assignment.startedOn : 0),
    0
  );
}

function sumAssignmentsCompletedOn(assignments: any): any {
  if (assignments.length === 0) {
    return 0;
  }
  return assignments.reduce(
    (partialSum: number, assignment: any) =>
      partialSum + (assignment.completedOn ? assignment.completedOn : 0),
    0
  );
}

function formatString(body: any) {
  if (typeof body === "string") {
    return capitalizeFirstLetter(body);
  }
  return body;
}

function formatStatus(k: string) {
  if (k == "WIP" || k == "QA") {
    return k;
  }
  return k
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (c: string) => c.toUpperCase());
}

/**
 * CUSTOM COMPONENTS
 */
export function CustomTypography(props: any) {
  let variant = props.variant || "subtitle1";
  return (
    <Tooltip title={props.title || undefined} placement="bottom-start" arrow>
      <Typography
        style={props.style}
        className={props.className}
        noWrap={!props.wrap}
        variant={variant}
        align="left"
        onClick={props.onClick}
      >
        {props.icon && <props.icon />}
        {props.svgicon}
        {formatString(props.body)}
      </Typography>
    </Tooltip>
  );
}

const DividerWithText = ({ children }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.dividerContainer}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

const VerticalDottedLine = () => {
  return <div className="task-vertical-dotted-line green"></div>;
};

const ButtonActionReview = connect(null, (dispatch: any) => {
  return {
    setStatus: (
      serverDialogMessage: any,
      campaignId: string,
      assignmentId: any,
      comment: string,
      status: string
    ) => {
      dispatch(
        setAssignmentStatus(
          serverDialogMessage,
          campaignId,
          assignmentId,
          comment,
          status
        )
      );
    },
    cancelTask: (campaignId: string, taskId: any) => {
      dispatch(cancelTaskAction(campaignId, taskId));
    },
  };
})(
  ({
    serverDialogMessage,
    setStatus,
    cancelTask,
    campaignId,
    taskId,
    confirmDialog,
    actionType,
    assignmentId,
    comment,
  }: any) => {
    const classes = useStyles();

    const classButtons: { [key in StatusTask]?: string } = {
      [StatusTask.APPROVED]: classes.approve,
      [StatusTask.CONTINUE]: classes.continue,
      [StatusTask.CANCELLED]: classes.cancel,
      [StatusTask.REDO]: classes.cancel,
    };

    let label =
      statusButtonLabels[actionType as StatusTask] || StatusTask[actionType];
    const className = classButtons[actionType as StatusTask] || "";

    const activateButton = () => {
      if (StatusTask[actionType] == "CANCELLED") {
        cancelTask(campaignId, taskId);
        return;
      }
      setStatus(
        serverDialogMessage,
        campaignId,
        assignmentId,
        comment,
        StatusTask[actionType]
      );
    }
    return (
      <Button
        className={`${classes.button} ${className}`}
        variant="outlined"
        color="secondary"
        style={{maxWidth:80, width: 80}}
        onClick={(e) => {
          e.stopPropagation();
          if (statusDialogButton[actionType as StatusTask] === undefined) {
            activateButton();
          } else {
            confirmDialog(actionType as StatusTask, () => activateButton());
          }
        }}
      >
        {label}
      </Button>
    );
  }
);


const AssignmentsHistory = connect(
  (state: any) => {
    return {
      assignmentStats: state.campaigns.assignmentStats,
      houses: state.houses.houses,
    };
  },
  (dispatch: any) => {
    return {
      fetchAssignmentStats: (campaignId: string, assignmentId: string) =>
        dispatch(fetchAssignmentStats(campaignId, assignmentId)),
    };
  }
)(
  ({
    houses,
    assignmentStats,
    fetchAssignmentStats,
    campaignId,
    villageId,
    assignments,
    activeAssignment,
    getUserFullName,
  }: any) => {
    if (!assignmentStats[activeAssignment.id]) {
      fetchAssignmentStats(campaignId, activeAssignment.id);
      return null;
    }
    let lastAssignmnentHistoryCompletedId = -1;
    for (let assignment of assignments) {
      if (assignment && assignment.assignmentHistories) {
        for (let history of assignment.assignmentHistories) {
          if (history.status === StatusTask.COMPLETED) {
            if (history.id > lastAssignmnentHistoryCompletedId) {
              lastAssignmnentHistoryCompletedId = history.id;
            }
          }
        }
      }
    }
    let totalHouses: number = 0;
    if (houses !== undefined && houses.length > 0) {
      const filteredHouses = Object.values(houses || {}).filter(
        (house: any) => house.villageId === villageId
      );
      totalHouses = filteredHouses.length;
    }
    if (assignments.length === 0) {
      return "No Assignments Found";
    }
    return assignments.map((assignment: any) => (
      <div className="assignment">
        {assignment.assignmentHistories &&
          assignment.assignmentHistories.length > 0 &&
          assignment.assignmentHistories
            .sort((a: any, b: any) => b.id - a.id)
            .map((history: any) => (
              <>
                <div className="task-data-from-field">
                  <div className="data-item">
                    <div className="data-shape-2 green"></div>
                    <span className="ws-sprayed pl-2">
                      <span className="bold">
                        {StatusTask[history.status]}
                        <span className="data-tab" />
                        <span className="pl-1">
                          <PersonOutlineIcon
                            fontSize="small"
                            className="_green"
                          />
                          {`By ${history.fullName}`}
                        </span>
                      </span>
                      <br />
                      <span>
                        {new Date(history.reportedOn).toLocaleString("en-GB")}
                      </span>
                      <br />
                      <span>Notes: {history.notes}</span>
                    </span>
                  </div>
                  {history.id === lastAssignmnentHistoryCompletedId &&
                  history.status === StatusTask.COMPLETED ? (
                    <>
                      <VerticalDottedLine />
                      <div className="data-item">
                        <div className="data-shape-1 azure"></div>
                        <span className="ws-sprayed pl-2">
                          <span className="bold">Water Sources</span>
                          <br />
                          <span>
                            {assignmentStats[assignment.id].sources}/
                            {assignmentStats[assignment.id].totalSources}
                          </span>
                        </span>
                      </div>
                      <VerticalDottedLine />
                      <div className="data-item">
                        <div className="data-shape-1 azure"></div>
                        <span className="ws-sprayed pl-2">
                          <span className="bold">Houses Visited</span>
                          <br />
                          <span>
                            {assignmentStats[assignment.id].houses}/
                            {totalHouses}
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    undefined
                  )}
                </div>
                <VerticalDottedLine />
              </>
            ))}
        <div className="task-data-from-field">
          <div className="data-item">
            <div className="data-shape-2 green"></div>
            <span className="ws-sprayed pl-2">
              <span className="bold">
                Assigned to {getUserFullName(assignment.userId)}
              </span>
              <br />
              <span>
                {new Date(assignment.assignedOn).toLocaleString("en-GB")}
              </span>
            </span>
          </div>
        </div>
      </div>
    ));
  }
);


