import { FETCH_SHELTERS } from "../actions/CampaignsActions";


const ShelterReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_SHELTERS:
      return action.payload;
    default:
      return state;
  }
};
export default ShelterReducer;