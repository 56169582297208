import { getCurrentOrigin } from "../../../../services/GlobalService";
import { WaterSource } from "../../../../appRedux/stores/waterSources.interface";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  hideLayout,
  showLayout,
} from "../utils";
import { getFeature, getLayer, getSource } from "../traps/utils";
import { getWaterSourceLayer, getWsSource } from "./utils/Icon";
import {
  getWaterSourcePolygonLayer,
  getWaterSourcePolygonSelectedLayer,
  getWsPolygonSource,
} from "./utils/Polygon";
import { Village } from "../../../../appRedux/stores/village.interface";
import turf from "turf";
import { getFormatDate } from "../../../outsiders/trapResults/TrapResultDialog";
interface IObjectKeys {
  [key: string]: string;
}
export const wsDrawStatus: IObjectKeys = {
  new: "new",
  issue: "issue",
  sampled: "sampled",
  sprayed: "sprayed",
  treated: "treated",
  selected: "selected",
};
class WaterSourcesDrawer {
  private map: any;
  private layerId = "wsDraw";
  private layerPolygonId = "wsPolygonDraw";
  private wsSelectedId = "wsSelectedMapId";
  private wsSelectedPolygonId = "wsSelectedPolygonId";
  private ws: any;
  private iconSize: number = 0.75;

  constructor(map: any) {
    this.map = map;
    this.drawWaterSource();

    addCursorIconToLayer(this.map, this.layerId);
    addCursorIconToLayer(this.map, this.wsSelectedId);
  }

  drawWaterSource = () => {
    this.loadImage().then(() => {
      this.addSources();

      this.map.addLayer(getWaterSourcePolygonLayer(this.layerPolygonId));
      this.map.addLayer(getWaterSourceLayer(this.layerId, this.iconSize));
      this.drawSelected();
    });
  };

  drawSelected = () => {
    this.map.addSource(
      this.wsSelectedId,
      getSource(getFeature(wsDrawStatus.selected, [0, 0]))
    );
    this.map.addLayer(
      getLayer(this.wsSelectedId, wsDrawStatus.selected, this.iconSize)
    );

    // this.map.addSource(this.wsSelectedPolygonId, getWsSelectedPolygonSource([0,0]));
    // this.map.addLayer(getWaterSourcePolygonSelectedLayer(this.wsSelectedPolygonId));

    this.hideSelected();
  };

  addSources = () => {
    // const waterSources :any = Object.values(this.ws).reduce((total: any, item: any) => {
    //     total[item.coordinates ? 'polygons' : 'icons'].push(item)
    //     return total
    // }, {icons: [], polygons: []})

    this.map.addSource(this.layerId, getEmptyGeoJson());
    this.map.addSource(this.layerPolygonId, getEmptyGeoJson());
  };

  private hideSelected = () => {
    hideLayout(this.wsSelectedId, this.map);
    hideLayout(this.wsSelectedPolygonId, this.map);
  };

  onChangeCampaign = (ws: any, saveWs = true) => {
    if (saveWs) this.ws = ws;
    if (this.map.getSource(this.layerId)) {
      const temp = getWsSource(Object.values(ws || [])).data;
      this.map.getSource(this.layerId).setData(temp);
      this.map
        .getSource(this.layerPolygonId)
        .setData(getWsPolygonSource(Object.values(ws || [])).data);
      this.hideSelected();
    }
  };

  selectWSIcon = (ws: WaterSource) => {
    // if (ws) {
    this.map
      .getSource(this.wsSelectedId)
      .setData(getFeature(wsDrawStatus.selected, ws.center));
    this.map.moveLayer(this.wsSelectedId, this.layerId);
    showLayout(this.wsSelectedId, this.map);
    // }
  };
  selectWSPolygon = (ws: WaterSource) => {
    const coordinate =
      ws.coordinates.length === 0
        ? turf.buffer(turf.point(ws.center), 0.05).geometry.coordinates
        : ws.coordinates;
    // this.map.getSource(this.wsSelectedPolygonId).setData(getWaterPolygonFeature(coordinate));
    // this.map.moveLayer(this.wsSelectedPolygonId, this.layerPolygonId);
    showLayout(this.wsSelectedPolygonId, this.map);
  };

  loadImage = async () => {
    return Promise.all(
      Object.values(wsDrawStatus).map((path: string) => {
        return new Promise((res: any) => {
          this.map.loadImage(
            `${getCurrentOrigin()}/images/sources/${path}.png`,
            (error: any, image: any) => {
              this.map.addImage(path, image);
              res();
            }
          );
        });
      })
    );
  };

  setLayerVisibility = (layerNames: string[]) => {
    if (this.map.getLayer(this.layerId)) {
      console.log(layerNames);
      const filterArray = ["in", "icon"];
      this.map.setFilter(this.layerId, filterArray.concat(layerNames));
      // this.map.setFilter(this.layerPolygonId, filterArray.concat(layerNames));
    }
  };
  setLayerDeletedWsVisibility = (show: boolean) => {
    if (this.map.getLayer(this.layerId)) {
      console.log(show);
      const filterArray = show
        ? ["!=", "deleted", ""]
        : ["==", "deleted", false];
      this.map.setFilter(this.layerId, filterArray);
      this.map.setFilter(this.layerPolygonId, filterArray);
      // this.map.setFilter(this.layerPolygonId, filterArray.concat(layerNames));
    }
  };

  clickWaterSourcesIcon = (
    handleSelectWs: (wsId: string, assignmentId?: string) => void,
    features: any,
    assignmentId?: string
  ) => {
    handleSelectWs(features[0].properties.wsId, assignmentId);
    this.selectWSIcon(this.ws[features[0].properties.wsId]);
  };

  clickWaterSourcesPolygon = (
    handleSelectWs: (wsId: string) => void,
    features: any
  ) => {
    // handleSelectWs(features[0].properties.wsId);
    this.selectWSPolygon(features[0].properties.wsId);
  };

  changeIconByZoom = (zoomNumber: number) => {
    if (this.map.getLayer(this.layerId)) {
      const newIconSize = 0.75 + 0.15 * (zoomNumber - 15);
      this.map.setLayoutProperty(this.layerId, "icon-size", newIconSize);
      this.map.setLayoutProperty(this.wsSelectedId, "icon-size", newIconSize);
    }
  };
  onFilterByDate = (ws: any) => {
    this.onChangeCampaign(ws, false);
  };
}

export default WaterSourcesDrawer;
