import React, { Component, CSSProperties } from "react";
import "./loginForm.scss";
import TextField from "material-ui/TextField";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import ErrorTemplate from "../templates/error-template/error-template";
import {
  forgotPasswordAction,
  getLoginAction,
} from "../../appRedux/actions/AuthActions";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { getCampaignsAction } from "../../appRedux/actions/CampaignsActions";
import { Enums } from "../../Enums";
import { serverLoading } from "../../appRedux/actions/ManageApiDialogAction";

interface LoginStyles {
  errorStyle: CSSProperties;
  underlineStyle: CSSProperties;
  floatingLabelStyle: CSSProperties;
  hidefloatingLabelStyle: CSSProperties;
  inputStyle: CSSProperties;
}

class LoginForm extends Component<any, any> {
  error_color = "#ff5b78";
  input_color = "#979797";
  underline_color = "#fff";

  static contextTypes = {
    router: PropTypes.object,
  };

  styles: LoginStyles = {
    errorStyle: {
      color: this.error_color,
      bottom: "unset",
      top: "5px",
      textAlign: "left",
    },
    underlineStyle: {
      display: "none",
    },
    floatingLabelStyle: {
      color: this.input_color,
      fontSize: "18px",
      top: "0px",
    },
    hidefloatingLabelStyle: {
      display: "none",
    },
    inputStyle: {
      padding: "0px 10px",
      margin: "0px",
    },
  };

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      openErrorModal: false,
      errorMessage: "",
      phone: "",
      password: "",
      token: "",
      userEmail: "",
      passwordErrorText: "",
      phoneErrorText: "",
      toSubmit: false,
      phoneReadyForLogin: false,
      passwordReadyForLogin: false,
      forgotPassword: false,
      sendNewPassword: false,
    };
  }

  private errorHandler = (errorMessage: string) => {
    this.setState(
      (prevState: any) => ({
        ...prevState,
        errorMessage: errorMessage,
      }),
      () => {
        this.setState({ openErrorModal: true });
      }
    );
  };

  handleChange = (name: string) => (event: any) => {
    const { formatMessage } = this.props.intl;
    const phoneRegex = /^\+?\d+$/;
    const isEmptyOrSpaces = /^\s+$/;
    switch (name) {
      case "phone":
        if (this.checkPhoneInputEmpty(isEmptyOrSpaces, event, formatMessage)) {
          break;
        }
        this.checkPhoneInputValidation(phoneRegex, event, formatMessage);
        break;

      case "password":
        if (
          this.checkPasswordInputEmpty(isEmptyOrSpaces, event, formatMessage)
        ) {
          break;
        }
        this.checkPasswordInputValidation(event, formatMessage);
        break;
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  private checkPasswordInputValidation(event: any, formatMessage: any) {
    if (event.target.value.length >= 5) {
      this.setState({ passwordErrorText: "" });
      this.setState({ passwordReadyForLogin: true });
      this.setState({ password: event.target.value });
    } else {
      this.setState({
        passwordErrorText: formatMessage({ id: "app.invalidPassword" }),
      });
      this.setState({ passwordReadyForLogin: false });
      this.setState({ password: "" });
    }
  }

  private checkPasswordInputEmpty(
    isEmptyOrSpaces: RegExp,
    event: any,
    formatMessage: any
  ) {
    if (isEmptyOrSpaces.test(event.target.value)) {
      this.setState({
        passwordErrorText: formatMessage({ id: "app.requiredFields" }),
      });
      this.setState({ passwordReadyForLogin: false });
      this.setState({ password: "" });
      return true;
    }
    return false;
  }

  private checkPhoneInputValidation(
    phoneRegex: RegExp,
    event: any,
    formatMessage: any
  ) {
    if (phoneRegex.test(event.target.value)) {
      this.setState({ phoneErrorText: "" });
      this.setState({ phoneReadyForLogin: true });
      this.setState({ phone: event.target.value });
    } else {
      this.setState({
        phoneErrorText: formatMessage({ id: "app.invalidPhoneNumber" }),
      });
      this.setState({ phoneReadyForLogin: false });
      this.setState({ phone: "" });
    }
  }

  private checkPhoneInputEmpty(
    isEmptyOrSpaces: RegExp,
    event: any,
    formatMessage: any
  ) {
    if (isEmptyOrSpaces.test(event.target.value)) {
      this.setState({
        phoneErrorText: formatMessage({ id: "app.requiredFields" }),
      });
      this.setState({ phoneReadyForLogin: false });
      this.setState({ phone: "" });
      return true;
    }
    return false;
  }

  handleSubmit = () => {
    //back to login
    if (this.checkForBackToLoginPress()) {
      return;
    }

    //send
    if (this.state.forgotPassword) {
      this.checkForSendPress();

      //submit
    } else {
      this.checkForSubmitPress();
    }
  };

  private checkForSubmitPress() {
    const body = this.constructServerLoginMassageBody();
    if (this.state.phoneReadyForLogin && this.state.passwordReadyForLogin) {
      this.setState({ toSubmit: true });
      this.postLoginToServer(body);
    } else {
      this.setState({ toSubmit: false });
    }
  }

  private checkForSendPress() {
    const body = this.constructServerMassageBody();
    if (this.state.phoneReadyForLogin) {
      this.setState({ sendNewPassword: true });
      this.setState({ forgotPassword: false });
      this.setState({ phone: "" });
      this.postForgotPasswordToServer(body);
    }
  }

  private checkForBackToLoginPress() {
    if (this.state.sendNewPassword) {
      this.setState({ sendNewPassword: false });
      this.setState({ forgotPassword: false });
      this.setState({ phoneReadyForLogin: false });
      this.setState({ passwordReadyForLogin: false });
      this.setState({ phone: "" });
      this.setState({ password: "" });
      return true;
    }
    return false;
  }

  private constructServerMassageBody() {
    return {
      userId: this.state.phone,
    };
  }

  private constructServerLoginMassageBody() {
    return {
      phoneNumber: this.state.phone,
      password: this.state.password,
    };
  }

  private postForgotPasswordToServer(body: any) {
    this.props
      .forgotPasswordAction(body)
      .then((res: any) => {
        if (
          res != null &&
          res.value != null &&
          res.value.data != null &&
          res.value.data.email != null
        ) {
          this.setState({ userEmail: res.value.data.email });
        }
      })
      .catch((err: any) => {
        console.log("SERVER POST FORGOT PASSWORD ERROR");
        this.errorHandler(err.response.data.message);
      });
  }

  private postLoginToServer(body: any) {
    this.onCallback({ start_spinner: true });
    this.props
      .getLoginAction(body)
      .then((res: any) => {
        if (res != null && res.value != null && res.value.data != null) {
          this.setState({ token: res.value.data.token });
          localStorage.setItem("token", res.value.data.token);
          const role = this.parseJwt(res.value.data.token).user.role;
          if (role === Enums.USER_TYPES.imageAnalyst)
            this.context.router.history.push("/images");
          else if (
            role === Enums.USER_TYPES.admin ||
            role === Enums.USER_TYPES.officeWorker
          ) {
            this.props.serverLoading(this.props.getCampaignsAction);
            this.context.router.history.push("/map");
          }
        }
      })
      .catch((err: any) => {
        if (err.response && err.response.status) {
          const { formatMessage } = this.props.intl;
          this.setState({
            passwordErrorText: formatMessage({ id: "app.loginPage.WrongUser" }),
          });
        }
      });
  }

  private handleForgotPassword() {
    this.setState({ forgotPassword: true });
  }

  private getHeader() {
    return this.state.sendNewPassword
      ? ""
      : !this.state.forgotPassword
      ? "Login"
      : "Forgot password";
  }

  private getUpperBox(formatMessage: any) {
    return !this.state.forgotPassword
      ? this.getPhoneBox(formatMessage)
      : LoginForm.getForgotPasswordText();
  }

  private static getForgotPasswordText() {
    return (
      <div className="forgot-pass-massage">
        <FormattedMessage id={"app.forgotMassage"} />
      </div>
    );
  }

  private getLowerBox(formatMessage: any) {
    return !this.state.forgotPassword
      ? this.getPasswordBox(formatMessage)
      : this.getPhoneBox(formatMessage);
  }

  private getPhoneBox(formatMessage: any) {
    return (
      <MuiThemeProvider>
        <TextField
          className="txt-field"
          id="phone"
          floatingLabelText={formatMessage({ id: "app.PhoneNumber" })}
          value={this.state.phone}
          onChange={this.handleChange("phone")}
          errorText={this.state.phoneErrorText}
          errorStyle={this.styles.errorStyle}
          underlineStyle={this.styles.underlineStyle}
          underlineFocusStyle={this.styles.underlineStyle}
          placeholder={formatMessage({ id: "app.PhoneNumber" })}
          floatingLabelStyle={
            this.state.phone === ""
              ? this.styles.hidefloatingLabelStyle
              : this.styles.floatingLabelStyle
          }
          floatingLabelFixed
          inputStyle={this.styles.inputStyle}
          autoFocus
        />
      </MuiThemeProvider>
    );
  }

  private getPasswordBox(formatMessage: any) {
    return (
      <MuiThemeProvider>
        <TextField
          className="txt-field-password"
          id="password"
          type="password"
          floatingLabelText={formatMessage({ id: "app.password" })}
          value={this.state.password}
          onChange={this.handleChange("password")}
          errorText={this.state.passwordErrorText}
          errorStyle={this.styles.errorStyle}
          underlineStyle={this.styles.underlineStyle}
          underlineFocusStyle={this.styles.underlineStyle}
          placeholder={formatMessage({ id: "app.password" })}
          floatingLabelStyle={
            this.state.password === ""
              ? this.styles.hidefloatingLabelStyle
              : this.styles.floatingLabelStyle
          }
          floatingLabelFixed
          inputStyle={this.styles.inputStyle}
          onKeyDown={this.handleKeyPress}
        />
      </MuiThemeProvider>
    );
  }
  handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  private getObjectUnderHeader(formatMessage: any) {
    return this.state.sendNewPassword
      ? this.getNewPasswordSentToMailText()
      : this.getUpperBox(formatMessage);
  }

  private getNewPasswordSentToMailText() {
    return (
      <div className="forgot-pass-massage-sent">
        <FormattedMessage id={"app.newPasswordSent"} />
        {this.state.userEmail}
      </div>
    );
  }

  private getForgotOrEmpty() {
    return this.state.forgotPassword || this.state.sendNewPassword ? (
      <FormattedMessage id={"app.null"} />
    ) : (
      <FormattedMessage id={"app.forgotPassword"} />
    );
  }

  private getBackToLoginOrSubmitSend() {
    return this.state.sendNewPassword ? (
      <FormattedMessage id={"app.backLogin"} />
    ) : (
      this.getSubmitOrSend()
    );
  }

  private getSubmitOrSend() {
    return this.state.forgotPassword ? (
      <FormattedMessage id={"app.send"} />
    ) : (
      <FormattedMessage id={"app.submit"} />
    );
  }

  render() {
    const { formatMessage } = this.props.intl;

    let closeErrorModal = () => {
      this.setState({ openErrorModal: false });
    };

    return (
      <div>
        <div className="login-page">
          <div className="right-section">
            <form className="login-frm" onSubmit={this.handleSubmit.bind(this)}>
              <div
                className={
                  "frm-header" +
                  (this.state.forgotPassword ? " frm-header-forgot-pass" : "")
                }
              >
                {this.getHeader()}
              </div>
              {this.getObjectUnderHeader(formatMessage)}
              {this.state.sendNewPassword ? (
                <div></div>
              ) : (
                this.getLowerBox(formatMessage)
              )}
              <div className="frm-btn" onClick={this.handleSubmit.bind(this)}>
                {this.getBackToLoginOrSubmitSend()}
              </div>
              <div
                className="forgot-pass"
                onClick={this.handleForgotPassword.bind(this)}
              >
                {this.getForgotOrEmpty()}
              </div>
            </form>
          </div>
        </div>
        <ErrorTemplate
          open={this.state.openErrorModal}
          close={closeErrorModal}
          errorMessage={this.state.errorMessage}
        ></ErrorTemplate>
      </div>
    );
  }

  onCallback = (data: any) => {
    if (this.props.onCallback) this.props.onCallback(data);
  };
  parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}

const mapStateToProps = (state: any) => {
  return {};
};
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { getLoginAction, forgotPasswordAction, getCampaignsAction, serverLoading },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginForm));
