import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import { uploadUsers } from "../../../../services/UsersService";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    color: "#fbc13b",
    borderColor: "#fbc13b",
    '&:hover': {
      borderColor: "#fbc13b",
      backgroundColor: "#FBC13B33",
    }
  }
})
const MenuPopupState: React.FC = () => {
  const classes = useStyles();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState<{ title: string, message: string } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-menu' });

  const handleUploadCSV = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleFileChange')
    const file = event.target.files && event.target.files[0];
    console.log('file', file)

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    console.log('formData', formData)
    try {
      const result = await uploadUsers(formData)
      setUploadResponse({
        title: "Upload Succeed",
        message: `${result.data.length} users created successfully`
      });
    } catch (e) {
      console.log('e', e)
      setUploadResponse({
        title: "Upload Failed",
        message: e.response.data.message
      });
    }
    setDialogOpen(true)

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  console.log('MenuPopupState')
  return (
        <React.Fragment>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".csv"
            onChange={handleFileChange}
          />
          <Dialog open={isDialogOpen}
                  onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              { uploadResponse && uploadResponse.title ? uploadResponse.title : '' }
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                { uploadResponse && uploadResponse.message ? uploadResponse.message : '' }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.button} onClick={() => setDialogOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Button className={classes.button} variant="outlined" color="primary" {...bindTrigger(popupState)} >
            Bulk Upload
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={ () => {
              handleUploadCSV()
              popupState.close()
            }}>Upload from CSV</MenuItem>
            <MenuItem onClick={popupState.close}>
              <a href='assets/users_template.csv' download style={{ textDecoration: 'none', color: 'inherit' }}>Download template</a>
            </MenuItem>
          </Menu>
        </React.Fragment>
  );
}

export default React.memo(MenuPopupState);
