import { getShadowLayer, getSource } from "./utils";
import { limitRasterTime } from "./calculateBorder";
import { hideLayout, showLayout } from "../../utils";

class CreateVillageShadow {
  map: any;
  villageSourceId = "CreateVillageId";
  villageSources: any = [];
  filterArray = ["in", "id"];

  constructor(map: any) {
    this.map = map;
  }

  addSources = async () => {
    const shadowChunks = await Promise.all(
      this.villageSources.map(async (villageSources: any, index: number) => {
        const chunks: any = await limitRasterTime(villageSources.village);
        return { ...chunks, index };
      })
    );

    const rasterBorder = getSource(shadowChunks);
    if (this.map.getSource(this.villageSourceId)) {
      this.map.getSource(this.villageSourceId).setData(rasterBorder.data);
      return;
    }
    this.map.addSource(this.villageSourceId, rasterBorder);
  };

  addLayers = () => {
    if (!this.map.getLayer(this.villageSourceId)) {
      this.map.addLayer(getShadowLayer(this.villageSourceId));
    }
  };

  drawShadowVillage = async (id: string, village: any) => {
    this.villageSources = [
      ...this.villageSources,
      { id: id, village: village },
    ];
    await this.addSources();
    this.addLayers();
    showLayout(this.villageSourceId, this.map);
  };

  onEditVillage = async (id: string, village: any) => {
    this.villageSources = this.villageSources.map((villageSource: any) => {
      return villageSource.id === id ? { id, village } : villageSource;
    });
    await this.addSources();
  };

  onDeleteVillage = (villageId: string) => {
    const filterArray = ["in", "id"];
    this.villageSources = this.villageSources.filter(
      (village: any) => village.id !== villageId
    );
    this.map.setFilter(
      this.villageSourceId,
      filterArray.concat(this.villageSources.map((village: any) => village.id))
    );
  };

  onCancelCreation = () => {
    this.villageSources = [];
    hideLayout(this.villageSourceId, this.map);
  };
}

export default CreateVillageShadow;
