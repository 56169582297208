export const getScanLayer = (scanId: string) => {
  return {
    id: scanId,
    minzoom: 16,
    maxzoom: 24,
    type: "fill",
    source: scanId,
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-opacity": 0.7,
      "fill-color": "rgba(49,127,255,0.55)",
    },
  };
};
export const toScanFeature = (coordinates: any) => {
  return {
    type: "Feature",
    geometry: {
      type: "MultiPolygon",
      coordinates: [coordinates],
    },
  };
};

export const getScanSource = (features: any) => {
  return {
    type: "geojson",
    data: {
      features,
      type: "FeatureCollection",
    },
  };
};
