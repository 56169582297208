import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/SpinnerAction";

export interface ISpinnerState {
  isSpinner: boolean;
}

const spinnerReducer = (
  state: ISpinnerState = { isSpinner: false },
  action: any
) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        ...state,
        isSpinner: true,
      };
    case HIDE_SPINNER:
      return {
        ...state,
        isSpinner: false,
      };
    default:
      return state;
  }
};

export default spinnerReducer;
