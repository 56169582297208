import * as React from "react";
import { WorkTypeTask } from "../../../Enums";
import WaterSource from "./campaign/village/waterSource/WaterSourceContainer";
import VillageSideBar from "./campaign/village/VillageSideBarContainer";
import VillageReviewSideBar from "./campaign/village/VillageReviewSideBarContainer";
import ChunkSideBarContainer from "./campaign/village/chunk/ChunkSideBarContainer";
import CampaignSideBar from "./campaign/CampaignSideBarContainer";
import { fetchWaterSourceById } from "../../../services/WaterSourceService";
import { fetchHouse } from "../../../services/HouseService";
import HouseSideBarContainer from "./campaign/village/house/HouseSideBarContainer";
import StationSideBarContainer from "./campaign/station/StationSideBarContainer";
import { capitalizeFirstLetter, formatDurationMS } from "../../../Formatting";

enum ESideBar {
  CAMPAIGN,
  VILLAGE,
  CHUNK,
  WS,
  HOUSE,
  STATION,
  HIDE,
}

interface IRightBannerProps {
  intl: any;
  villageV1: any;
  map: any;
  campaignId: any;
  waterSourcesDrawer: any;
  housesDrawer: any;
  stationsDrawer: any;
  villageDrawer: any;
  villagePolygon: any;
  chunksDrawer: any;
  scanDrawer: any;
  scans: any;
  handleCreateVillageMode: any;
  handleCreateStationMode: any;
  assignmentId?: string;
  assignmentTitle?: string;
  taskType?: WorkTypeTask;
  taskSummary: any;
}

interface IRightBannerState {
  showBar: ESideBar;
  wsSelectedId: number | string;
  houseSelectedId: number | string;
  stationSelectedId: number | string;
  villageSelectedId: number | string;
  chunkSelectedId: number | string;
  waterSource: any;
  house: any;
}

export class RightBanner extends React.PureComponent<
  IRightBannerProps,
  IRightBannerState
> {
  constructor(props: IRightBannerProps) {
    super(props);
    this.state = {
      showBar: ESideBar.CAMPAIGN,
      wsSelectedId: 0,
      villageSelectedId: 0,
      chunkSelectedId: 0,
      houseSelectedId: 0,
      stationSelectedId: 0,
      waterSource: null,
      house: null,
    };
  }

  componentDidMount(): void {
    this.props.map.on("click", this.onClick);
  }

  onClick = (e: any) => {
    const bbox = [
      [e.point.x - 0.00001, e.point.y - 0.00001],
      [e.point.x + 0.00001, e.point.y + 0.00001],
    ];
    const features = this.props.map.queryRenderedFeatures(bbox);

    if (features[0] && features[0].layer && features[0].layer.id) {
      console.log(features[0].properties);
      switch (features[0].layer.id) {
        case "wsDraw":
          this.props.waterSourcesDrawer.clickWaterSourcesIcon(
            this.handleSelectWs,
            features,
            this.props.assignmentId
          );
          return;

        case "stationsDraw":
          this.props.stationsDrawer.clickStationsIcon(
            this.handleSelectStation,
            features
          );
          return;

        case "housesDraw":
          this.props.housesDrawer.clickHousesIcon(
            this.handleSelectHouse,
            features
          );
          return;

        case "VillageSource":
          this.props.villageDrawer.clickVillage(
            this.handleSelectVillage,
            features
          );
          return;

        case "layerNamesId":
          this.props.villageDrawer.clickVillage(
            this.handleSelectVillage,
            features
          );
          return;

        case "villageSourcePolygon":
          this.props.villagePolygon.clickVillage(
            this.handleSelectVillage,
            features
          );
          return;

        case "chunkSourceId":
          this.props.chunksDrawer.clickChunk(this.handleSelectChunk, features);
          return;
      }
      // this.onDrawPolygonClick(features)
    }
  };

  handleSelectVillage = (villageId: string) => {
    this.setState({
      villageSelectedId: villageId,
      showBar: ESideBar.VILLAGE,
    });
  };
  handleSelectChunk = (chunkId: string) => {
    this.setState({ chunkSelectedId: chunkId, showBar: ESideBar.CHUNK });
  };
  handleSelectWs = (wsId: string, assignmentId?: string) => {
    this.fetchWaterSource(wsId, assignmentId);
    this.setState({ wsSelectedId: wsId, showBar: ESideBar.WS });
  };
  handleSelectHouse = (houseId: number) => {
    this.fetchHouse(houseId);
    this.setState({ houseSelectedId: houseId, showBar: ESideBar.HOUSE });
  };
  handleSelectStation = (stationId: number) => {
    this.setState({ stationSelectedId: stationId, showBar: ESideBar.STATION });
  };
  handleBackToCampaignSideBar = () => {
    this.setState({ showBar: ESideBar.CAMPAIGN });
  };
  fetchWaterSource = async (wsId: string, assignmentId?: string) => {
    try {
      const response = await fetchWaterSourceById(wsId, assignmentId);
      this.setState({ waterSource: response.data });
    } catch (e) {
      console.log(e);
    }
  };

  //TODO This actually does nothing. neither the state uses house or it is assigned at all
  fetchHouse = async (houseId: number) => {
    try {
      const response = await fetchHouse(houseId);
      this.setState({ house: response });
    } catch (e) {
      console.log(e);
    }
  };

  getVillageNameById = (villageId: string) => {
    if (this.props.villageV1[villageId]) {
      return this.props.villageV1[villageId].name;
    }
    return "Village";
  };

  handleFocusOnVillage = (villageId: string) => {
    this.props.map.setZoom(16);
    this.props.map.setCenter(this.props.villageV1[villageId].center);
    this.handleSelectVillage(villageId);
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className={"right-sidebar-container"}>
        {this.props.assignmentId ? (
          <div className="task-review">
            <div className="task-review-caption">
              {this.props.taskType !== undefined &&
                capitalizeFirstLetter(WorkTypeTask[this.props.taskType])}{" "}
              Review
            </div>
            <div>{this.props.assignmentTitle}</div>
            {this.props.taskSummary && (
              <div>
                Duration: {formatDurationMS(this.props.taskSummary.duration)}
              </div>
            )}
          </div>
        ) : null}
        <div className={"right-sidebar"}>
          {this.state.showBar === ESideBar.HOUSE && (
            <HouseSideBarContainer
              formatMessage={formatMessage}
              handleVillageOnSideBar={this.handleSelectVillage}
              getVillageNameById={this.getVillageNameById}
              id={this.state.houseSelectedId}
            />
          )}
          {this.state.showBar === ESideBar.WS && (
            <WaterSource
              formatMessage={formatMessage}
              handleVillageOnSideBar={this.handleSelectVillage}
              getVillageNameById={this.getVillageNameById}
              waterSource={this.state.waterSource}
            />
          )}
          {this.state.showBar === ESideBar.STATION && (
            <StationSideBarContainer
              formatMessage={formatMessage}
              getVillageNameById={this.getVillageNameById}
              backToCampaignSideBar={this.handleBackToCampaignSideBar}
              id={this.state.stationSelectedId}
            />
          )}
          {this.state.showBar === ESideBar.VILLAGE &&
            (this.props.assignmentId ? (
              <VillageReviewSideBar
                backToCampaignSideBar={this.handleBackToCampaignSideBar}
                villageId={this.state.villageSelectedId}
                activeVillage={this.props.villageDrawer.activeVillage}
                changeVillageName={this.props.villageDrawer.changeVillageName}
                assignmentId={this.props.assignmentId}
                assignmentTitle={this.props.assignmentTitle}
                taskType={this.props.taskType}
                taskSummary={this.props.taskSummary}
              />
            ) : (
              <VillageSideBar
                fetchVillageScan={this.props.scanDrawer.fetchScan}
                backToCampaignSideBar={this.handleBackToCampaignSideBar}
                villageId={this.state.villageSelectedId}
                activeVillage={this.props.villageDrawer.activeVillage}
                changeVillageName={this.props.villageDrawer.changeVillageName}
              />
            ))}

          {this.state.showBar === ESideBar.CHUNK && (
            <ChunkSideBarContainer
              chunkId={this.state.chunkSelectedId}
              getVillageNameById={this.getVillageNameById}
              handleVillageOnSideBar={this.handleSelectVillage}
            />
          )}

          {this.state.showBar === ESideBar.CAMPAIGN && (
            <CampaignSideBar
              createVillage={this.props.handleCreateVillageMode}
              createStation={this.props.handleCreateStationMode}
              handleFocusOnVillage={this.handleFocusOnVillage}
            />
          )}
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.villageV1 !== this.props.villageV1) {
      this.setState({ showBar: ESideBar.CAMPAIGN });
    }
    if (
      nextProps.assignmentId &&
      nextProps.assignmentId !== this.props.assignmentId
    ) {
      const villageId = Object.keys(nextProps.villageV1)[0];
      this.handleSelectVillage(villageId);
      if (nextProps.scans[villageId]) {
        nextProps.scanDrawer.fetchScan(villageId, nextProps.assignmentId);
      }
    }
  }
}
