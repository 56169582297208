import { LeftBannerFeature, SET_PRESSED } from "../actions/LeftBannerActions";

// INITIALIZE STATE
const initialLeftBannerState: { pressed: LeftBannerFeature } = {
  pressed: LeftBannerFeature.Map,
};

// REDUCER
const LeftBannerReducer = (
  state: any = initialLeftBannerState,
  action: any
) => {
  const payload = action.payload;
  switch (action.type) {
    case SET_PRESSED:
      console.log('payload:', payload);
      state.pressed = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default LeftBannerReducer;
