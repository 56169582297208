import * as React from "react";
import { FunctionComponent, useState } from "react";

interface IvillageDetailsBarProps {
  deleteVillage: () => void;
  submitVillage: (name: string) => void;
  isManualVillage: boolean;
}

export const VillageDetailsBar: FunctionComponent<IvillageDetailsBarProps> = (
  props
) => {
  const [villageName, setName] = useState("");

  if (props.isManualVillage) {
    return (
      <div className="bottom-tool-bar">
        <div className="bottom-bar-section">
          <div className="delete-path">
            <div className="delete-txt" onClick={props.deleteVillage}>
              <img src={"/images/delete-village-icon.svg"} alt="" />
              <span>Delete</span>
            </div>
          </div>
          <div className="village-name-section">
            <input
              type="text"
              placeholder={"village name"}
              className="input-village-name"
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div
            className={"create-btn"}
            onClick={() => props.submitVillage(villageName)}
          >
            <div className="create-btn-txt">create</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="auto-creation-options">
      <div
        onClick={props.deleteVillage}
        className={"delete-auto-create-village"}
      >
        Clear area
      </div>
      <div
        onClick={() => props.submitVillage("")}
        className="submit-auto-create"
      >
        Create villages
      </div>
    </div>
  );
};
