import axios from "axios";
import { IAssignment } from "../appRedux/stores/assignment.interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const createAssignment = (newAssignment: IAssignment) => {
  return instance.post(`/api/v1/assignments/create/`, newAssignment);
};

export const fetchAssignments = (campaignId: string) => {
  return instance.get(`/api/v1/assignments/${campaignId}`);
};
