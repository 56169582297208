import { Dispatch } from "redux";
import { normalize } from "normalizr";
import { IAssignment } from "../stores/assignment.interface";
import { assignmentSchema } from "../schemas/Assignment";
import {
  fetchAssignments,
  createAssignment,
} from "../../services/AssignmentService";
import { setActiveAssignment } from "./TaskActions";

export enum AssignmentActions {
  ADD_ASSIGNMENT = "ADD_ASSIGNMENT",
  FETCH_ASSIGNMENTS = "FETCH_ASSIGNMENTS",
}

export const addAssignmentAction = (newAssignmentData: IAssignment) => {
  return async (dispatch: Dispatch) => {
    const { data } = await createAssignment(newAssignmentData);
    
    setActiveAssignment(data);
  
    dispatch({
      type: AssignmentActions.ADD_ASSIGNMENT,
      id: data.id,
      task: data,
    });
  };
};

export const fetchAssignmentsAction = (campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const assignments = await fetchAssignments(campaignId);
    const normalizedAssignmentsData = normalize(assignments.data, [
      assignmentSchema,
    ]);

    dispatch({
      type: AssignmentActions.FETCH_ASSIGNMENTS,
      assignments: normalizedAssignmentsData.entities.assignments || {},
    });
  };
};
