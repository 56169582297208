import * as React from "react";
import { FunctionComponent, useState } from "react";
import { ECreateVillageMode } from "../CreateMode";

interface ITopBarProps {
  createMode: ECreateVillageMode;
  setCreateMode: (mode: ECreateVillageMode) => void;
  cancelCreate: () => void;
  addControl: () => void;
}

export const TopBar: FunctionComponent<ITopBarProps> = (props) => {
  const [state, setState] = useState("");

  const handleChangeMode = (mode: ECreateVillageMode) => {
    if (props.createMode !== mode) {
      props.setCreateMode(mode);
    }
  };

  return (
    <div className="cancelation-bar">
      <div
        className={`manual-create-btn ${
          props.createMode === ECreateVillageMode.MANUAL ? "active" : ""
        }`}
        onClick={() => {
          handleChangeMode(ECreateVillageMode.MANUAL);
        }}
      >
        Manual creation
      </div>
      <div
        className={`auto-create-btn ${
          props.createMode === ECreateVillageMode.UPLOAD ? "active" : ""
        }`}
        onClick={() => {
          props.setCreateMode(ECreateVillageMode.UPLOAD);
        }}
      >
        Upload
      </div>
      <div className="cancel-btn" onClick={props.cancelCreate}>
        Cancel creation
      </div>
    </div>
  );
};
