import * as React from "react";
import "./ManageServerRequestDialog.scss";
import { Dialog } from "@material-ui/core";

interface IProps {
  isOpen: boolean;
  isSuccess: boolean;
  message: string;
  header?: string;
  description: string;
  closeDialog: () => void;
}

interface IState {}

export class ManageServerRequestDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  getHeader = () => {
    if (this.props.header) return this.props.header;
    return this.props.isSuccess ? "Success" : "Error";
  };

  render() {
    if (this.props.isOpen) {
      return (
        <Dialog onClose={this.props.closeDialog} open={this.props.isOpen}>
          <div className="server-dialog">
            <div className="headline-dialog">{this.getHeader()}</div>
            <div className="message-dialog">{this.props.message}</div>
            <div className="message-dialog">{this.props.description}</div>
            <button
              className="btn-accept-dialog"
              onClick={this.props.closeDialog}
            >
              Ok
            </button>
          </div>
        </Dialog>
      );
    }
    return null;
  }
}
