import {
  House,
  HOUSE_STATUS,
} from "../../../../../appRedux/stores/houses.interface";
import { houseDrawStatus } from "../HousesDrawer";

export const getHouseLayer = (layerId: string, iconSize: number) => {
  return {
    id: layerId,
    minzoom: 16,
    maxzoom: 24,
    type: "symbol",
    source: layerId,
    layout: {
      visibility: "visible",
      "icon-image": ["get", "icon"],
      "icon-size": iconSize,
      "icon-allow-overlap": true,
    },
    filter: ["in", "icon"].concat(Object.keys(houseDrawStatus)),
  };
};

export const getHouses = (houses: House[]) => {
  const features: any = houses.map((house: House) => {
    return getHouseIconFeature(house);
  });
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
};

export const getHouseIconFeature = (house: House) => {
  return {
    type: "Feature",
    id: house.id,
    geometry: {
      type: "Point",
      coordinates: [house.location.longitude, house.location.latitude],
    },
    properties: {
      houseId: house.id,
      icon: HOUSE_STATUS[house.status],
    },
  };
};
