import React from "react";

const defaultStyles = {
  display: "inline-block",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "#fbc13b",
  marginRight: "5px",
  height: "12px",
};

interface Props {}

const Bar = (props: Props) => {
  const styles = {
    ...defaultStyles,
  };
  return <span style={styles}></span>;
};

export default Bar;
