import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const fetchHouses = (campaignId: string, assignmentId?: string) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  return instance.get(`/api/v1/houses/${campaignId}/all` + filter);
};

export const createHouse = (
  campaignId: string,
  assignmentId: number,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const body = [
    {
        "id": "af7c1fe6-d669-414e-b066-e9733f0de7a8",
        "location": {
            "latitude": 32.0770917,
            "longitude": 34.8938636
        },
        "reportedOn": 1671114324505,
        "villageId": 1216,
        "assignmentId": assignmentId
    }
];
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/v1/houses/${campaignId}`,
    body,
    {
      headers: headers,
    }
  );
};

export const fetchHouse = (houseId: number) => {};
