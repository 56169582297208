import { bindActionCreators } from "redux";
import {
  addStation
} from "../../../appRedux/actions/StationActions";
import {
  AddVillageAction,
  rangeVillageAction,
  removeRangeVillageAction,
} from "../../../appRedux/actions/VillagesActions";
import {
  serverDialog,
  serverDialogMessage,
  serverLoading,
} from "../../../appRedux/actions/ManageApiDialogAction";
import {
  downloadVillageScan,
  getCampaignsAction,
  refreshScanDownloaded,
  fetchCampaignAction,
} from "../../../appRedux/actions/CampaignsActions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { MapComponent } from "./mapComponent";

const mapStateToProps = (state: any) => {
  const {
    campaigns,
    campaignId,
    assignmentId,
    assignmentTitle,
    taskType,
    taskSummary,
    scans,
  } = state.campaigns;
  const { villages } = state.villages;
  const { stations } = state;
  const { shelters } = state;

  return {
    campaigns,
    scans,
    villages,
    stations,
    shelters,
    campaignId,
    assignmentId,
    assignmentTitle,
    taskType,
    taskSummary,
    campaign: state.campaign,
    waterSources: state.waterSource.waterSource,
    villageV1: state.villages.villageV1,
    chunks_v2: state.villages.chunks_v2,
    users: state.usersReducer.users,
    houses: state.houses.houses,
  };
};
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addStation,
      AddVillageAction,
      serverLoading,
      serverDialogMessage,
      downloadVillageScan,
      refreshScanDownloaded,
      serverDialog,
      getCampaignsAction,
      rangeVillageAction,
      removeRangeVillageAction,
      fetchCampaignAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MapComponent));
