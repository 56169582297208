import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import Checkbox from "material-ui/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

interface ISheltersFiltersProps {
  showSheltersLayer: (show: boolean) => void;
  zoom: number;
  formatMessage: any;
}

export const SheltersFilters: FunctionComponent<ISheltersFiltersProps> = (
  props
) => {
  const [showShelters, setShowShelter] = useState(true);

  const handleCheckShowShelters = (e: any) => {
    setShowShelter((prevState) => !prevState);
    props.showSheltersLayer(e.target.checked);
  };

  if (props.zoom < 14) return null;

  return (
    // TODO: why is it a dropdown
    <div id="ws-selector" className="dropdown">
      <Tooltip title={"Show Shelters"}>
        <Checkbox
          checked={showShelters}
          onCheck={handleCheckShowShelters}
          color="primary"
        />
      </Tooltip>
    </div>
  );
};
