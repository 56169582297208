import * as React from "react";
import { FunctionComponent, useState } from "react";
import { ECreateVillageMode } from "../CreateMode";
import { Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { VillageDetailsBar } from "./VillageDetailsBar";

const classes = {
  root: {
    width: "100%",
  },
  button: {
    marginTop: "40px",
    marginRight: "40px",
  },
  actionsContainer: {
    // marginBottom: "40px"
  },
  resetContainer: {
    padding: "5px 20px",
    borderTop: "1px solid black",
  },
};

function getManualSteps() {
  return ["Create village core", "Create village belt"];
}

function getManualStepContent(step: number) {
  switch (step) {
    case 0:
      return `Now you create the core of the village.`;
    case 1:
      return "Now you create the belt of the village.";
    default:
      return "Unknown step";
  }
}

function getAutoSteps() {
  return ["Create village"];
}

function getAutoStepContent(step: number) {
  switch (step) {
    case 0:
      return `Now you create the villages.`;
    default:
      return "Unknown step";
  }
}

interface IVillageShapeProps {
  showAddBelt: boolean;
  drawVillageIds: string[];
  disableDraw: () => void;
  deleteVillage: () => void;
  submitVillage: (name: string) => void;
  isManualVillage: boolean;
  addControl: () => void;
}

export const VillageShape: FunctionComponent<IVillageShapeProps> = (props) => {
  const [isSkipped, setSkip] = useState(false);
  const steps = props.isManualVillage ? getManualSteps() : getAutoSteps();
  const getStepContent = (number: number) =>
    props.isManualVillage
      ? getManualStepContent(number)
      : getAutoStepContent(number);

  const activeStep = props.drawVillageIds.length + (isSkipped ? 1 : 0);

  const handleSkip = () => {
    setSkip(true);
    props.disableDraw();
  };

  const handleDeleteVillage = () => {
    setSkip(false);
    props.deleteVillage();
  };

  return (
    <div className="create-village-shape">
      {activeStep !== steps.length && (
        <img
          onClick={props.addControl}
          src={"/images/create_village/draw-map-icon.svg"}
          className="draw-map-icon"
        />
      )}
      <div style={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div style={classes.actionsContainer}>
                  {activeStep === 1 && (
                    <Button onClick={handleSkip} style={classes.button}>
                      Skip
                    </Button>
                  )}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} style={classes.resetContainer}>
            <VillageDetailsBar
              isManualVillage={props.isManualVillage}
              submitVillage={props.submitVillage}
              deleteVillage={handleDeleteVillage}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};
