import * as React from "react";
import { FunctionComponent, useState } from "react";
import Tabs from "material-ui/Tabs";
import Tab from "material-ui/Tabs/Tab";
import { SourcesDetailsStatus } from "../village/VillageIconDrawer";
import { Village } from "../../../../appRedux/stores/village.interface";

interface IWaterSourcesFiltersProps {
  formatMessage: any;
  villageDrawer: any;
  zoom: number;
  villages: any;
  users: any;
}

export const TopBarWsFilter = React.memo((props: IWaterSourcesFiltersProps) => {
  const [tab, setTab] = useState(SourcesDetailsStatus.waterSource);

  const handleTabChange = (tab: SourcesDetailsStatus) => {
    setTab(tab);
    props.villageDrawer.setVillageFilterDetails(tab);
  };
  const getTextByCurrentTabVal = (village: Village, tabVal: any) => {
    village.numberOfWaterSources = village.numberOfWaterSources
      ? village.numberOfWaterSources
      : 0;
    village.surveyPercentage = village.surveyPercentage
      ? village.surveyPercentage
      : 0;
    village.numberOfSprayed = village.numberOfSprayed
      ? village.numberOfSprayed
      : 0;
    village.numberOfSampled = village.numberOfSampled
      ? village.numberOfSampled
      : 0;

    switch (parseInt(tabVal, 10)) {
      case 0:
        return village.numberOfWaterSources;
      case 1:
        return Math.round(village.surveyPercentage * 1000) / 10 + "%";
      case 2:
        return village.numberOfSprayed + "/" + village.numberOfWaterSources;
      case 3:
        return village.numberOfSampled + "/" + village.numberOfWaterSources;
      default:
        return "";
    }
  };
  if (props.zoom >= 14) return null;

  return (
    <Tabs
      onChange={handleTabChange}
      value={tab}
      className="tabs-layout"
      inkBarStyle={{ display: "none" }}
    >
      <Tab
        label={props.formatMessage({ id: "app.mapPage.WaterSources" })}
        value={SourcesDetailsStatus.waterSource}
        className={
          "filter-tab round-left" +
          (tab === SourcesDetailsStatus.waterSource ? " active-tab" : "")
        }
      />
      <Tab
        label={props.formatMessage({ id: "app.mapPage.AreaScanned" })}
        value={SourcesDetailsStatus.scanned}
        className={
          "filter-tab" +
          (tab === SourcesDetailsStatus.scanned ? " active-tab" : "")
        }
      />
      <Tab
        label={props.formatMessage({ id: "app.mapPage.AreaSprayed" })}
        value={SourcesDetailsStatus.sprayed}
        className={
          "filter-tab" +
          (tab === SourcesDetailsStatus.sprayed ? " active-tab" : "")
        }
      />
      {/* <Tab
        label={props.formatMessage({ id: "app.mapPage.AreaSampeld" })}
        value={SourcesDetailsStatus.sampled}
        className={
          "filter-tab round-right" +
          (tab === SourcesDetailsStatus.sampled ? " active-tab" : "")
        }
      /> */}
    </Tabs>
  );
});
