import {
  getCampaign,
  getCampaigns,
  addCampaign,
  updateCampaign,
  fetchCampaignFileScans,
  getAssignmentStats,
  getAnimalShelter,
} from "../../services/CampaignsService";
import { fetchHouses } from "../../services/HouseService";
import { Campaign } from "../stores/campaign.interface";
import { Dispatch } from "redux";
import { getAllUsersOfCampaign } from "../../services/UsersService";
import { UserActionsEnum } from "./UsersActions";
import { normalize } from "normalizr";
import { villageSchema } from "../schemas/Village";
import { userSchema } from "../schemas/Users";
import { chunkSchema } from "../schemas/Chunk";
import { WsSchema } from "../schemas/WaterSource";
import { SprayedStatus } from "../../components/main/mapComponent/village/VillageIconDrawer";
import _ from "lodash";
import { houseSchema } from "../schemas/House";
import { stationSchema } from "../schemas/Station";
import { getStations } from "../../services/StationService";
import { shelterSchema } from "../schemas/Shelter";

//ACTION NAMES
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SCANS_FILE = "GET_CAMPAIGNS_SCANS_FILE";
export const POST_NEW_CAMPAIGN_EMPLOYESS = "POST_NEW_CAMPAIGN_EMPLOYESS";
export const SET_CAMPAIGN_ID = "SET_CAMPAIGN_ID";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const FETCH_STATIONS = "FETCH_STATIONS";
export const FETCH_SHELTERS = "FETCH_SHELTERS";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPLOAD_CAMPAIGN_IMAGES = "UPLOAD_CAMPAIGN_IMAGES";
export const GET_CHUNKS_V2 = "GET_CHUNKS_V2";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const CLEAR_ASSIGNMENT = "CLEAR_ASSIGNMENT";
export const FETCH_ASSIGNMENT_STATS = "FETCH_ASSIGNMENT_STATS";
export const FETCH_WATER_SOURCE = "FETCH_WATER_SOURCE";
export const FETCH_VILLAGES = "FETCH_VILLAGES";
export const FETCH_HOUSES = "FETCH_HOUSES";
export const DOWNLOAD_SCANS_FILE = "DOWNLOAD_SCANS_FILE";
export const REFRESH_SCAN_FILE_DOWNLOADED = "REFRESH_SCAN_FILE_DOWNLOADED";

export const getCampaignsAction = () => {
  return async (dispatch: any) => {
    const { data } = await getCampaigns();
    dispatch({
      type: GET_CAMPAIGNS,
      campaigns: data,
    });
  };
};

export const fetchAssignmentStats = (
  campaignId: string,
  assignmentId: string
) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_ASSIGNMENT_STATS,
      id: assignmentId,
      stats: {},
    });
    const houses = await fetchHouses(campaignId, assignmentId);
    const { data } = await getAssignmentStats(campaignId, assignmentId);
    /*
    dispatch({
      type: FETCH_HOUSES,
      houses: normalizedHousesData.entities.houses || {},
    });
    */
   /**
    * Load it with data of total houses, which you can get from the redux state (Use #connect the same way we get assignmentStats from state (Look at AssignmentStats@TaskTable))
    * Over there, there's an action called FETCH_VILLAGES, then villageV1, then the id of village, then houses and you have the houses.length for the overall.
    */
    let summary = data.villages[0].summary;
    let sources = data.villages[0].sources;
    summary.houses = houses.data.length;
    summary.sources = sources.length;
    if (data.villages.length !== 1) {
      throw Error("getAssignmentStats returned wrong number of villages");
    }
    dispatch({
      type: FETCH_ASSIGNMENT_STATS,
      id: assignmentId,
      stats: data.villages[0].summary,
    });
  };
};


export const fetchCampaignAction = (
  campaignId: string,
  assignmentId?: string,
  taskType?: number,
  taskSummary?: any
) => {
  return async (dispatch: any) => {
    const users = await getAllUsersOfCampaign(campaignId);
    const normalizedUsers = normalize(users.data, [userSchema]);
    const houses = await fetchHouses(campaignId, assignmentId);
    const normalizedHousesData = normalize(houses.data, [houseSchema]);
    const { data } = await getCampaign(campaignId, assignmentId);

    const stations = await getStations(campaignId);
    const shelters = await getAnimalShelter(campaignId, assignmentId);

    const normalizeStations = normalize(stations.data, [stationSchema])
    const normalizeShelters = normalize(shelters.data, [shelterSchema])

    const normalizedWsData = normalize(
      data.villages
        .map((vil: any) => (vil.sources.length ? vil.sources : []))
        .flat(),
      [WsSchema]
    );
    const normalizedChunkData = normalize(
      data.villages
        .map((vil: any) =>
          vil.chunks.map((c: any) => ({ ...c, village: vil.id }))
        )
        .flat(),
      [chunkSchema]
    );
    const normalizedVillage = normalize(
      handleAddSemiExpired(data.villages, data.settings),
      [villageSchema]
    );
    const campaign = _.omit(data, "villages");

    dispatch({
      type: UserActionsEnum.GET_CAMPAIGN_EMPLOYESS,
      users: normalizedUsers.entities.users || {},
    });
    dispatch({
      type: FETCH_WATER_SOURCE,
      waterSource: normalizedWsData.entities.waterSources || {},
    });
    dispatch({
      type: FETCH_HOUSES,
      houses: normalizedHousesData.entities.houses || {},
    });
    dispatch({
      type: FETCH_STATIONS,
      payload: normalizeStations.entities.stations || [],
    });
    dispatch({
      type: FETCH_SHELTERS,
      payload: normalizeShelters.entities.shelters || [],
    });
    dispatch({
      type: GET_CHUNKS_V2,
      chunks: normalizedChunkData.entities.chunks || {},
    });
    dispatch({
      type: FETCH_VILLAGES,
      villages: normalizedVillage.entities.villages || {},
    });
    let assignmentTitle;
    if (assignmentId) {
      assignmentTitle = Object.values(
        normalizedVillage.entities.villages || {}
      )[0].name;
    }

    dispatch({
      type: FETCH_CAMPAIGN,
      campaign: campaign,
      campaignId: campaignId,
      assignmentId: assignmentId || null,
      assignmentTitle: assignmentTitle,
      taskType: taskType,
      taskSummary: taskSummary
    });
  };
};
export const handleAddSemiExpired = (villages: any, settings: any) => {
  //TODO change to real data
  return villages.map((v: any) => ({
    ...v,
    summary: { ...v.summary, ...getLastSprayedStatus(v, settings) },
  }));
};

const getLastSprayedStatus = (village: any, settings: any) => {
  const villageSprayStatus = getSprayedStatus(getLastSpray(village), settings);
  return { lastSprayedStatus: villageSprayStatus };
};

export const getLastSpray = (village: any) => {
  const sortedSpray = village.sources.sort((a: any, b: any) =>
    (a.lastSprayedOn != null ? a.lastSprayedOn : 0) >
    (b.lastSprayedOn != null ? b.lastSprayedOn : 0)
      ? -1
      : 1
  );
  return sortedSpray[0] ? sortedSpray[0].lastSprayedOn : null;
};

function getSprayedStatus(lastSpray: any, settings: any) {
  //
  if (lastSpray === null) return SprayedStatus.UN_SPRAYED;

  if (lastSpray + settings.sprayExpiration >= Date.now())
    return SprayedStatus.UNEXPIRED;

  if (lastSpray + settings.spraySemiExpiration >= Date.now())
    return SprayedStatus.SEMI_EXPIRED;

  return SprayedStatus.EXPIRED;
}

export const clearAssignmentAction = (
) => {
  return async (dispatch: any) => {
    dispatch({type: CLEAR_ASSIGNMENT});
  }
}

export const getCampaignEmployeesAction = (CampaignId: string) => {
  return async (dispatch: Dispatch) => {
    const { data } = await getAllUsersOfCampaign(CampaignId);
    const normalizedUsers = normalize(data, [userSchema]);
    dispatch({
      type: UserActionsEnum.GET_CAMPAIGN_EMPLOYESS,
      users: normalizedUsers.entities.users || {},
    });
  };
};

export const addCampaignAction = (newCampaignData: Campaign) => {
  return async (dispatch: Dispatch) => {
    const { data } = await addCampaign(newCampaignData);
    dispatch({
      type: ADD_CAMPAIGN,
      campaign: data,
    });
  };
};

export const updateCampaignAction = (
  newCampaignData: Campaign,
  campaignId: string
) => {
  return async (dispatch: Dispatch) => {
    await updateCampaign(newCampaignData, campaignId);
    dispatch({
      type: UPDATE_CAMPAIGN,
      id: campaignId,
      campaign: newCampaignData,
    });
  };
};
export const deleteCampaignAction = (newCampaignData: Campaign) => {
  return async (dispatch: Dispatch) => {};
};

export const fetchCampaignScansFileAction = (
  campaignId: string,
  assignmentId?: string
) => {
  return async (dispatch: any) => {
    const { data } = await fetchCampaignFileScans(campaignId, assignmentId);
    dispatch({
      type: GET_CAMPAIGNS_SCANS_FILE,
      scans: data.map((d: string) => d.replace("-scanned.geojson", "")),
    });
  };
};

export const refreshScanDownloaded = () => {
  return async (dispatch: any) => {
    dispatch({
      type: REFRESH_SCAN_FILE_DOWNLOADED,
    });
  };
};

export const downloadVillageScan = (village: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: DOWNLOAD_SCANS_FILE,
      scan: village,
    });
  };
};
