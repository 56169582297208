export interface IFormValidator {
  [field: string]: {
    message: string;
    method: any;
    value: string | number | boolean | any[];
    options?: any;
  };
}

interface IFormValidator2 {
  [field: string]: {
    message: string;
    method: any;
    value: string | number | boolean | any[];
    isValid: boolean;
    options?: any;
  };
}

export class FormValidator {
  validations: IFormValidator2;
  public isAllValidate: boolean = true;

  constructor(validations: IFormValidator) {
    const fields: IFormValidator2 = {};
    Object.keys(validations).forEach((field) => {
      fields[field] = {
        ...validations[field],
        isValid: validations[field].value
          ? validations[field].method(
              validations[field].value.toString(),
              validations[field].options
            )
          : true,
      };
      this.isAllValidate = this.isAllValidate && fields[field].isValid;
    });
    this.validations = fields;
  }

  getValidate(fieldName: string) {
    return this.validations[fieldName].isValid;
  }

  getMessage(fieldName: string) {
    return this.validations[fieldName].message;
  }

  setValidate(fieldName: string, value: any) {
    const field = this.validations[fieldName];
    field.value = value;
    field.isValid = field.method(value, field.options);
  }

  isFromValid = () => {
    let isValid = true;
    Object.entries(this.validations).forEach(
      ([field, { method, value, options }]) => {
        isValid = isValid && method(value, options);
        // if (!method(value, options)) {
        //   console.log("field", field, "value", value);
        // }
      }
    );
    return isValid;
  };

  showWhoUnValid = (forceRender: () => void) => {
    let isValid = true;
    Object.entries(this.validations).forEach(
      ([field, { method, value, options }]) => {
        if (!method(value, options)) {
          this.validations[field].isValid = false;
          isValid = false;
        }
      }
    );

    if (!isValid) {
      forceRender();
    }
  };
}

export const isArrayEmpty = (arr: any[]): boolean => {
  return !!arr.length;
};
export const isArrayNotEmpty = (arr: any[]): boolean => {
  return arr.length > 0;
};
export const isNumericPositive = (value: number): boolean => {
  return value > 0;
};
export const isNumericPositiveMin0 = (value: number): boolean => {
  return value >= 0;
};
