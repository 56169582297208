import { connect } from "react-redux";
import { WaterSource } from "./WaterSource";

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    waterSource: ownProps.waterSource,
    users: state.usersReducer.users,
  };
};

export default connect(mapStateToProps)(WaterSource);
