import React, { Component } from "react";
import "./employeeComponent.scss";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import EmployeesTable from "./employeesTable";
import { bindActionCreators } from "redux";
import {
  addUserAction,
  updateUserAction,
} from "../../../../appRedux/actions/UsersActions";
import { connect } from "react-redux";
import { User } from "../../../../appRedux/stores/users.interface";
import _ from "lodash";
import { Enums } from "../../../../Enums";
import { getCurrentUserProfile } from "../../../../services/GlobalService";
import { serverDialog } from "../../../../appRedux/actions/ManageApiDialogAction";
import { EmployeeTemplate } from "../../../templates/add-employee-template/EmployeeTemplate";
import MenuPopupState from "./dropDownButton";

class EmployeeComponent extends Component<any, any> {
  static contextTypes = {
    router: PropTypes.object,
  };

  private imageAnalystData: User[] = [];
  private backOfficeData: User[] = [];
  private fieldWorkersData: User[] = [];
  private imageAnalystHeadCells: any[] = [
    { id: "fullName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: true,
      label: "Phone number",
    },
    { id: "email", numeric: false, disablePadding: true, label: "Email" },
  ];
  private backOfficeHeadCells: any[] = [
    { id: "fullName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: true,
      label: "Phone number",
    },
    { id: "email", numeric: false, disablePadding: true, label: "Email" },
  ];
  private fieldWorkersHeadCells: any[] = [
    { id: "fullName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: true,
      label: "Phone number",
    },
    {
      id: "ceritification",
      numeric: false,
      disablePadding: true,
      label: "Ceritification",
    },
  ];

  private formatMessage: any;

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      minimizeIsPressed: false,
      openModal: false,
      currentUser: {},
      currentUserProfile: {
        role: getCurrentUserProfile().role,
      },
    };
    // this.handleCloseError = this.handleCloseError.bind(this);
  }

  render() {
    if (!this.props.campaignId) return null;
    this.formatMessage = this.props.intl.formatMessage;
    return (
      <div className="employee-main-wrapper">
        <div className="employee-main-container">
          {this.createEmployeeTitleBox()}
          {this.createSeparator()}
          {this.imageAnalystData.length +
            this.fieldWorkersData.length +
            this.backOfficeData.length ===
            0 && this.getNoEmployeeComp()}
          {this.imageAnalystData.length !== 0 &&
            this.createImageAnalystListElements()}
          {this.imageAnalystData.length !== 0 && this.createSeparator()}
          {this.backOfficeData.length !== 0 &&
            this.createBackOfficeListElements()}
          {this.backOfficeData.length !== 0 && this.createSeparator()}
          {this.fieldWorkersData.length !== 0 &&
            this.createFieldWorkersListElements()}
          {this.fieldWorkersData.length !== 0 && this.createSeparator()}
        </div>
        {this.state.openModal && (
          <EmployeeTemplate
            addUser={this.props.addUserAction}
            updateUser={this.props.updateUserAction}
            currentUser={this.state.currentUser}
            campaignId={this.props.campaignId}
            onClose={this.handleDialogMode}
            serverDialog={this.props.serverDialog}
          />
        )}
      </div>
    );
  }
  getNoEmployeeComp = () => {
    return (
      <div className="no-employee-border">
        <img src="images/mosquito.svg" className="no-employee-icon" />
        <div className="no-employee-header">
          It’s time to add your first employee
        </div>
        <span className="no-employee-txt" onClick={this.handleDialogMode}>
          +Add
        </span>
      </div>
    );
  };
  componentDidMount() {
    this.init(this.props);
  }

  componentDidUpdate(prevProps: any, prevState: any) {}

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps, true);
  }

  private init(props: any, receiveProps: boolean = false) {
    if (
      /*!_.isEmpty(props.users) &&*/ !_.isEmpty(props.campaigns) &&
      props.campaignId !== null
    ) {
      const users: User[] = Object.values(props.users);
      this.imageAnalystData = [];
      this.backOfficeData = [];
      this.fieldWorkersData = [];
      for (let i = 0; i < users.length; i++) {
        const user: User = users[i];
        if (user.role === Enums.USER_TYPES.imageAnalyst) {
          this.imageAnalystData.push({
            id: user.id,
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            email: user.email,
          });
        } else if (user.role === Enums.USER_TYPES.officeWorker) {
          this.backOfficeData.push({
            id: user.id,
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            email: user.email,
          });
        } else if (user.role === Enums.USER_TYPES.fieldWorker) {
          const ceritification: any = {
            irs: user.irsCertification,
            enthomology: user.enthomologyCertification,
            larviciding: user.larvicidingCertification,
          };
          this.fieldWorkersData.push({
            id: user.id,
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            ceritification: ceritification,
          });
        }
      }
      if (!receiveProps) {
        this.setState({});
      }
    }
  }

  private createSeparator() {
    return (
      <hr
        className={
          "separator-line" +
          (this.state.minimizeIsPressed ? " separator-line-minimized" : "")
        }
      ></hr>
    );
  }

  private createEmployeeTitleBox() {
    return (
      <div className="employees-title-strip">
        {this.createEmployeeTitle()}
        <div className="add-employee-container">
          {this.state.currentUserProfile.role !== Enums.USER_TYPES.observer
            ? this.createAddEmployee()
            : null}
          <MenuPopupState />
        </div>
      </div>
    );
  }

  private createEmployeeTitle() {
    return (
      <div className="employees-title-container">
        <div className="employees-title-icon-strip">
          <img className="employees-icon" src="/images/Group_10.svg"></img>
          <div className="employees-title">Employees</div>
        </div>
        <div className="employees-list">List of all your Employees</div>
      </div>
    );
  }

  private createAddEmployee() {
    return (
      <div className="zz-btn add-employee-btn" onClick={this.handleDialogMode}>
        {"+ " + this.formatMessage({ id: "app.addEmployeePage.AddEmployee" })}
      </div>
    );
  }

  handleDialogMode = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      currentUser: {},
      openModal: !prevState.openModal,
    }));
  };

  private createImageAnalystListElements() {
    return (
      <div className="back-office-workers-container">
        <EmployeesTable
          className="back-office-tbl-container"
          usersType={Enums.USER_TYPES.imageAnalyst}
          employees={this.imageAnalystData}
          columnsNames={this.imageAnalystHeadCells}
          formatMessage={this.formatMessage}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  private createBackOfficeListElements() {
    return (
      <div className="back-office-workers-container">
        <EmployeesTable
          className="back-office-tbl-container"
          usersType={Enums.USER_TYPES.officeWorker}
          employees={this.backOfficeData}
          columnsNames={this.backOfficeHeadCells}
          formatMessage={this.formatMessage}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  private createFieldWorkersListElements() {
    return (
      <div className="field-workers-container">
        <EmployeesTable
          className="field-workers-tbl-container"
          usersType={Enums.USER_TYPES.fieldWorker}
          employees={this.fieldWorkersData}
          columnsNames={this.fieldWorkersHeadCells}
          formatMessage={this.formatMessage}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  private handleEdit(userId: string) {
    this.setState({
      currentUser: this.getCurrentUserById(userId),
      openModal: true,
    });
  }

  private getCurrentUserById(userId: string): any {
    const users: User[] = Object.values(this.props.users);
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === userId) {
        return users[i];
      }
    }
    return null;
  }
}

const mapStateToProps = (state: any) => {
  const { users } = state.usersReducer;
  const { campaigns, campaignId } = state.campaigns;
  return { users, campaigns, campaignId };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { serverDialog, updateUserAction, addUserAction },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EmployeeComponent));
