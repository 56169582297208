import { getCurrentOrigin } from "../../../../services/GlobalService";
import {
  getVillageFeature,
  getVillageLayer,
  getVillageSource,
  getVillageTitleLayer,
} from "./utils";
import { Village } from "../../../../appRedux/stores/village.interface";
import { getFeature, getSource } from "../traps/utils";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  hideLayout,
  showLayout,
} from "../utils";
import { normalize } from "normalizr";
import { villageSchema } from "../../../../appRedux/schemas/Village";

export enum SprayedStatus {
  UN_SPRAYED = "unspray",
  EXPIRED = "expired",
  SEMI_EXPIRED = "semi",
  UNEXPIRED = "unexpired",
}

export enum SourcesDetailsStatus {
  waterSource = "waterSource",
  scanned = "scanned",
  sprayed = "sprayed",
  sampled = "sampled",
}

class VillageIconDrawer {
  map: any;
  source: any;
  sourceId = "VillageSource";
  layerNamesId = "layerNamesId";
  villageSelectId = "VillageSelectId";
  images = {
    defined: "defined",
    process: "working",
    selected_village: "selected_village",
  };

  constructor(map: any) {
    this.map = map;
    addCursorIconToLayer(this.map, this.layerNamesId);
    addCursorIconToLayer(this.map, this.sourceId);
    addCursorIconToLayer(this.map, this.villageSelectId);
  }

  drawVillages = () => {
    return new Promise((resolve) => {
      this.loadImages(Object.values(this.images), "/images/villages/").then(
        () => {
          this.map.addSource(this.sourceId, getEmptyGeoJson());
          this.map.addLayer(getVillageLayer(this.sourceId));
          this.map.addLayer(
            getVillageTitleLayer(this.layerNamesId, this.sourceId)
          );
          this.drawSelected();
          resolve();
        }
      );
    });
  };
  onChangeCampaign = (villages: any, saveSources = true) => {
    if (this.map.getSource(this.sourceId)) {
      if (saveSources) this.source = villages;
      this.map
        .getSource(this.sourceId)
        .setData(getVillageSource(Object.values(villages)).data);
      hideLayout(this.villageSelectId, this.map);
    }
  };
  onAddVillage = (village: any) => {
    if (this.map.getSource(this.sourceId)) {
      this.source[village.id] = village;
      this.map
        .getSource(this.sourceId)
        .setData(getVillageSource(Object.values(this.source)).data);
      hideLayout(this.villageSelectId, this.map);
    }
  };

  drawSelected = () => {
    this.map.addSource(
      this.villageSelectId,
      getSource(getFeature(this.images.selected_village, [0, 0]))
    );
    this.map.addLayer(getVillageLayer(this.villageSelectId));
    hideLayout(this.villageSelectId, this.map);
  };

  changeVillageName = (villageId: string, name: string) => {
    this.source[villageId].name = name;
    this.map
      .getSource(this.sourceId)
      .setData(getVillageSource(Object.values(this.source)).data);
  };

  setVillageFilterDetails = (tab: SourcesDetailsStatus) => {
    if (tab === SourcesDetailsStatus.sprayed) {
      this.onSelectSprayedMode();
      this.map.setLayoutProperty(this.sourceId, "icon-image", [
        "get",
        "sprayedIcon",
      ]);
    } else
      this.map.setLayoutProperty(this.sourceId, "icon-image", ["get", "icon"]);

    this.map.setLayoutProperty(this.layerNamesId, "text-field", ["get", tab]);

    if (tab !== SourcesDetailsStatus.sprayed) {
      console.log("numar");
      this.onChangeCampaign(this.source);
    }
  };

  activeVillage = (villageId: string) => {
    if (this.source[villageId].status === 1) {
      this.source[villageId].status = 2;
      this.map
        .getSource(this.sourceId)
        .setData(getVillageSource(Object.values(this.source)).data);
    }
  };

  loadImages = async (images: any, prefix: string) => {
    return Promise.all(
      images.map((path: string) => {
        return new Promise((resolve) => {
          this.map.loadImage(
            `${getCurrentOrigin()}${prefix}${path}.png`,
            (error: any, image: any) => {
              this.map.addImage(path, image);
              resolve();
            }
          );
        });
      })
    );
  };
  onSelectSprayedMode = () => {
    this.loadImages(
      Object.values(SprayedStatus),
      "/images/villages/sprays/"
    ).then(() => {});
  };

  clickVillage = (
    villageSelect: (villageId: string) => void,
    features: any
  ) => {
    villageSelect(features[0].properties.id);
    this.selectVillage(this.source[features[0].properties.id]);
  };

  selectVillage = (village: Village) => {
    this.map
      .getSource(this.villageSelectId)
      .setData(getVillageFeature(village, this.images.selected_village));
    this.map.moveLayer(this.villageSelectId, this.sourceId);
    showLayout(this.villageSelectId, this.map);
  };

  onFilterByDate = (villages: any, villagesSummary: any) => {
    const sources = villagesSummary.map((v: any) => ({
      ...villages[v.village],
      summary: { ...villages[v.village].summary, ...v.summary },
    }));

    this.onChangeCampaign(
      normalize(sources, [villageSchema]).entities.villages || {}
    );
  };
}

export default VillageIconDrawer;
