import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";
import ErrorTemplate from "./components/templates/error-template/error-template";

import AppLocale from "./lngProvider";
import "./App.scss";
import EmployeeComponent from "./components/main/leftBannerComponent/employeeComponent";
import campaignManagementComponent from "./components/main/leftBannerComponent/campaignManagementComponent";
import PropTypes from "prop-types";
import loginComponent from "./components/loginComponent";
import mapComponent from "./components/main/mapComponent/ContainerMap";
import LeftBannerComponent from "./components/main/leftBannerComponent";
import ImageAnalystComponent from "./components/outsiders/imageAnalystComponent";
import { getCampaignsAction } from "./appRedux/actions/CampaignsActions";
import ManageServerRequestDialog from "./components/templates/server-request/ServerRequestContainer";
import { bindActionCreators } from "redux";
import packageJson from "../package.json";
import { withAuth } from "./components/loginComponent/hoc/WithAuth";
import { serverLoading } from "./appRedux/actions/ManageApiDialogAction";
import SpinnerContainer from "./components/templates/spinner/SpinnerContainer";
import TaskContainer from "./components/outsiders/tasks/TaskContainer";

import { convertChunkToPolygon } from "./components/main/mapComponent/createVillage/createVillageDrawer/utils";
import mgrs from "mgrs";

// Configure JSS
const generateClassName = createGenerateClassName({
  productionPrefix: "jazzz",
});

export class App extends Component<any, any> {
  private unListen: any;

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      showLogoutMenu: false,
      isLoginPage: window.location.pathname === "/login",
      openErrorModal: false,
      errorMessage: "",
      isCampaignManagementPage:
        window.location.pathname === "/campaign-management",
      isObserverManagementPage:
        window.location.pathname === "/observer-management",
      currentPathName: window.location.pathname,
    };
  }

  componentWillMount() {
    console.log("App version: ", packageJson.version);
    // @ts-ignore
    window.ctp = convertChunkToPolygon;
    // @ts-ignore
    window.mgrs = mgrs;

    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];

    if (!navigator.onLine) {
      this.setState({
        errorMessage: currentAppLocale.messages["app.offline"],
        openErrorModal: true,
      });
    }
    window.addEventListener("offline", (e) => {
      this.setState({
        errorMessage: currentAppLocale.messages["app.offline"],
        openErrorModal: true,
      });
    });

    this.unListen = this.context.router.history.listen(
      (location: any, action: any) => {
        if (location.pathname === "/login") {
          if (localStorage.getItem("token") !== null) {
            this.context.router.history.push("/map");
            this.setState({ isLoginPage: false });
          } else {
            this.setState({ isLoginPage: true });
          }
        } else {
          this.ifUserNotLoggedIn(location.pathname);
        }
      }
    );

    if (!this.ifUserNotLoggedIn(window.location.pathname)) {
      this.init();
    }
  }

  private init() {
    this.props.serverLoading(this.props.getCampaignsAction);
  }

  private ifUserNotLoggedIn(pathname?: string): boolean {
    if (localStorage.getItem("token") === null) {
      this.context.router.history.push("/login");
      this.setState({ isLoginPage: true });
      return true;
    } else {
      this.setState({
        isLoginPage: false,
        isCampaignManagementPage: pathname === "/campaign-management",
        isObserverManagementPage: pathname === "/observer-management",
        currentPathName: pathname,
      });
      return false;
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    const closeErrorModal = () => {
      this.setState({ openErrorModal: false });
    };
    return (
      <StylesProvider generateClassName={generateClassName}>
        <ManageServerRequestDialog />
        <SpinnerContainer />
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className="App">
            {this.state.isLoginPage ? null : (
              <LeftBannerComponent
                currentPathName={this.state.currentPathName}
                isCampaignManagementPage={this.state.isCampaignManagementPage}
                isObserverManagementPage={this.state.isObserverManagementPage}
                className="left-banner-component-container"
              />
            )}
            <div
              className={
                "router-container" + (this.state.isLoginPage ? "" : " float-r")
              }
            >
              <Switch>
                <Route exact path="/login" component={loginComponent} />
                <Route
                  exact
                  path="/employees"
                  component={withAuth(EmployeeComponent)}
                />
                <Route
                  exact
                  path="/campaign-management"
                  component={withAuth(campaignManagementComponent)}
                />
                <Route path="/tasks" component={withAuth(TaskContainer)} />
                <Route exact path="/map" component={mapComponent} />
                <Route
                  exact
                  path="/images"
                  component={withAuth(ImageAnalystComponent)}
                />
                {/* <Route exact path="/observer-management" component={withAuth(observerManagementComponent)}/> */}
                <Redirect to="/login" />
              </Switch>
            </div>
            <ErrorTemplate
              open={this.state.openErrorModal}
              close={closeErrorModal}
              errorMessage={this.state.errorMessage}
              disableBackdropClick={true}
            />
          </div>
        </IntlProvider>
      </StylesProvider>
    );
  }
}
const mapStateToProps = (state: any) => {
  const { locale } = state.settings;
  const { campaigns } = state.campaigns;
  return { locale, campaigns };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ getCampaignsAction, serverLoading }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
