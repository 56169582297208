import React, { Component, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { injectIntl, FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import {
  getCurrentUserProfile,
  currentUserProfile,
} from "../../../services/GlobalService";
import { getUser } from "../../../services/UsersService";
import { User } from "../../../appRedux/stores/users.interface";
import moment from "moment";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./imageAnalystComponent.scss";
import ErrorTemplate from "../../templates/error-template/error-template";
import SuccessTemplate from "../../templates/success-template/success-template";
import { Enums } from "../../../Enums";
import PropTypes from "prop-types";
import {
  IdialogOptions,
  serverDialog,
} from "../../../appRedux/actions/ManageApiDialogAction";
import { uploadCampaignImages } from "../../../services/CampaignsService";
import InfiniteCalendar from "react-infinite-calendar";
import orange from "@material-ui/core/colors/orange";

// import { UploadServerResponse } from "../../appRedux/stores/campaign.interface";

const styles: any = {
  input: {
    display: "none",
  },
  textField: {
    width: 220,
    margin: 0,
  },
  button: {
    width: 220,
  },
};

const useStyles = makeStyles(() => styles);
let classes: any;

const LinearDeterminate = (props: any) => {
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
      <LinearProgress variant="determinate" value={completed} />
      {/*
      <br />
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={completed}
      />
      */}
    </div>
  );
};

const Something = (props: any) => {
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // return props.showBar ? <LinearProgress variant="determinate" /> : null;
  // return props.showProgress && <LinearProgress variant="determinate" />;
  return (
    props.showProgress && (
      <LinearProgress variant="determinate" value={completed} />
    )
  );
};

const LogoutLink = (props: any) => {
  if (props.toLoginRoute) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="logout-container">
      <div className="logout-icon" onClick={props.handleLogout}></div>
      <div className="logout-text" onClick={props.handleLogout}>
        Log out
      </div>
    </div>
  );
};

const InfoBar = (props: any) => {
  return (
    <div className="info-bar-display-grid">
      <div className="icon-and-username-grid-area">
        <div className="zzapp-text"></div>
        <h4>
          <FormattedMessage id="app.images.imageAnalyst" />
          {` ${props.userName}`}
        </h4>
      </div>
      <div className="logout-grid-area">
        <LogoutLink
          handleLogout={props.handleLogout}
          toLoginRoute={props.toLoginRoute}
        />
      </div>
    </div>
  );
};

const Title = () => (
  <h3 className="title mb-4">
    <FormattedMessage id="app.images.title" />
  </h3>
);

const Content = () => (
  <p className="content mb-4">
    <FormattedMessage id="app.images.content" />
  </p>
);

const GeoJsonUploadButton = (props: any) => {
  return (
    <div className="mb-4">
      <input
        id="geojson-file"
        name="geofile"
        accept=".geojson"
        style={styles.input}
        type="file"
        onChange={(event) => props.handleFileUpload(event)}
        onClick={(event: any) => {
          event.target.value = null;
        }}
      />
      <label htmlFor="geojson-file" className="mr-4">
        <Button
          color="primary"
          variant="outlined"
          component="span"
          style={styles.button}
        >
          <FormattedMessage
            id={
              props.geoJsonFileName
                ? "app.images.replaceButton"
                : "app.images.geoJsonButton"
            }
          />
          {props.geoJsonFileName && <SwapHorizIcon fontSize="small" />}
        </Button>
      </label>
      <span>{props.geoJsonFileName}</span>
    </div>
  );
};

const ZipUploadButton = (props: any) => {
  return (
    <div>
      <input
        id="zip-file"
        name="images"
        accept=".zip"
        style={styles.input}
        type="file"
        onChange={props.handleFileUpload}
        onClick={(event: any) => {
          event.target.value = null;
        }}
      />
      <label htmlFor="zip-file" className="mr-4">
        <Button
          color="primary"
          variant="outlined"
          component="span"
          style={styles.button}
        >
          <FormattedMessage
            id={
              props.zipFileName
                ? "app.images.replaceButton"
                : "app.images.zipButton"
            }
          />
          {props.zipFileName && <SwapHorizIcon fontSize="small" />}
        </Button>
      </label>
      <span>{props.zipFileName}</span>
    </div>
  );
};

const SendButton = (props: any) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={styles.button}
      onClick={props.uploadCampaignImages}
      disabled={!props.readyToUpload}
    >
      <FormattedMessage id="app.images.sendButton" />
    </Button>
  );
};

class imageAnalystComponent extends Component<any, any> {
  private _currentProfile: currentUserProfile | null = null;

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props: any, context: any) {
    super(props, context);
    this._currentProfile = getCurrentUserProfile();
    this.state = {
      readyToUpload: false,
      toLoginRoute: false,
      showProgress: false,
      openErrorModal: false,
      errorMessage: "",
      openSuccessModal: false,
      reported_at: "",
      dateError: false,
      dateErrorMessage: "",
      geofile: { name: "", value: {} },
      images: { name: "", value: {} },
      geoJsonFileUploadError: false,
      geoJsonFileUploadErrorMessage: "",
      zipFileUploadError: false,
      zipFileUploadErrorMessage: "",
      currentPathName: this.props.currentPathName,
      currentUserProfile: {
        role: this._currentProfile ? this._currentProfile.role : null,
        userName: "",
      },
      datePicker: "MM/DD/YYYY",
      openDatePicker: false,
    };
    this.handleReportedAtDateChange = this.handleReportedAtDateChange.bind(
      this
    );
    this.uploadCampaignImages = this.uploadCampaignImages.bind(this);
  }

  componentWillMount() {
    const profilePromise = new Promise((resolve, reject) => {
      resolve(getCurrentUserProfile());
    });
    profilePromise
      .then((profile: any) => {
        getUser(profile.id)
          .then((res: any) => {
            if (res) {
              if (res.data) {
                const currentUser: User = res.data;
                this.setState({
                  currentUserProfile: {
                    role: this._currentProfile
                      ? this._currentProfile.role
                      : null,
                    userName: currentUser.fullName,
                  },
                });
              }
            }
          })
          .catch((err: any) => {
            this.errorHandler(err.response.data.message);
          });
      })
      .catch((err: any) => {
        this.errorHandler(err.response.data.message);
      });
  }

  private handleLogout = (e: any) => {
    localStorage.clear();
    this.setState((prevState: any) => ({
      ...prevState,
      toLoginRoute: true,
    }));
    // this.props.logoutImageAnalyst();
  };

  private resetState = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      showProgress: false,
      readyToUpload: false,
      datePicker: "MM/DD/YYYY",
      geofile: {
        ...prevState.geofile,
        name: "",
        value: {},
      },
      images: {
        ...prevState.images,
        name: "",
        value: {},
      },
    }));
  };

  private successHandler = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      openSuccessModal: true,
    }));
  };

  private errorHandler = (errorMessage: string) => {
    this.setState(
      (prevState: any) => ({
        ...prevState,
        errorMessage: errorMessage,
      }),
      () => {
        this.setState({ openErrorModal: true });
      }
    );
  };
  handleDateChange = (date: Date) => {
    this.handleOpenDatePicker();
    this.setState(
      () => ({
        datePicker: date,
      }),
      () => {
        this.readyToUpload();
      }
    );
  };

  handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    console.log(e.target.files);
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const { name } = e.target;

    this.setState(
      (prevState: any) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          name: file.name,
          value: file,
        },
      }),
      () => {
        this.readyToUpload();
      }
    );
  };

  handleReportedAtDateChange(): void {}

  private readyToUpload(): void {
    if (
      this.state.geofile.name &&
      this.state.images.name &&
      typeof this.state.datePicker.getMonth === "function"
    ) {
      this.setState((prevState: any) => ({
        ...prevState,
        readyToUpload: true,
      }));
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        readyToUpload: false,
      }));
    }
  }

  uploadCampaignImages(): void {
    this.setState((prevState: any) => ({
      ...prevState,
      showProgress: true,
    }));

    this.props.serverDialog(
      () => this.extractWsFromUpload(),
      "Upload water sources success",
      "Upload fail",
      { showMessageFromService: true }
    );
    this.resetState();
  }
  extractWsFromUpload = async () => {
    const formData: any = new FormData();
    formData.append("geofile", this.state.geofile.value);
    formData.append("images", this.state.images.value);
    formData.append("reported_at", this.state.datePicker.getTime());
    const { data } = await uploadCampaignImages(
      this.props.campaignId,
      formData
    );
    return Object.entries(data)
      .join("\n")
      .replace(/,/g, ": ")
      .replace(/outsiders:/g, "outsiders:\n");
  };

  getFormatDate = (date: any) => {
    if (date === "MM/DD/YYYY") {
      return date;
    }
    const currentDate = new Date(date);
    return `${currentDate.getMonth() +
      1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  };

  handleOpenDatePicker = () => {
    this.setState({ openDatePicker: !this.state.openDatePicker });
  };
  render() {
    if (!this.props.campaignId) return null;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#fbc13b",
        },
        secondary: {
          main: "#d6d6d6",
          // pastel green
          // dark: will be calculated from palette.secondary.main,
          // contrastText: "#ffcc00"
        },
      },
      typography: {},
      overrides: {
        MuiButton: {
          containedSecondary: {
            color: "white",
          },
        },
      },
    });

    const { formatMessage } = this.props.intl;
    let closeErrorModal = () => {
      this.setState({ openErrorModal: false });
    };
    let closeSuccessModal = () => {
      this.setState({ openSuccessModal: false });
    };
    return (
      <MuiThemeProvider theme={theme}>
        <div className="image-analyst-page">
          <InfoBar
            handleLogout={this.handleLogout}
            toLoginRoute={this.state.toLoginRoute}
            userName={`${this.state.currentUserProfile.userName}(${this.props
              .campaign && this.props.campaign.name})`}
          />
          <div className="bg">
            <div className="upload-display-grid">
              <div className="upload-form-grid-area">
                <Title />
                <Content />
                <GeoJsonUploadButton
                  geoJsonFileName={this.state.geofile.name}
                  handleFileUpload={this.handleFileUpload}
                  geoJsonFileUploadError={this.state.geoJsonFileUploadError}
                  geoJsonFileUploadErrorMessage={
                    this.state.geoJsonFileUploadErrorMessage
                  }
                />
                <ZipUploadButton
                  zipFileName={this.state.images.name}
                  handleFileUpload={this.handleFileUpload}
                  zipFileUploadError={this.state.zipFileUploadError}
                  zipFileUploadErrorMessage={
                    this.state.zipFileUploadErrorMessage
                  }
                />
                <hr />
                <div className="date-reported">
                  <div
                    onClick={this.handleOpenDatePicker}
                    className="date-reported-data"
                  >
                    {this.getFormatDate(this.state.datePicker)}
                  </div>
                  {this.state.openDatePicker && (
                    <InfiniteCalendar
                      className="data-picker"
                      width={350}
                      height={280}
                      maxDate={new Date()}
                      selected={
                        this.state.datePicker === "MM/DD/YYYY"
                          ? new Date()
                          : this.state.datePicker
                      }
                      onSelect={(date: Date) => {
                        this.handleDateChange(date);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="send-button-grid-area">
                <SendButton
                  readyToUpload={this.state.readyToUpload}
                  uploadCampaignImages={this.uploadCampaignImages}
                />
              </div>
              <div className="progress-bar-grid-area">
                <Something showProgress={this.state.showProgress} />
              </div>
            </div>
          </div>
          <SuccessTemplate
            open={this.state.openSuccessModal}
            close={closeSuccessModal}
            lngProviderTitleKey="app.imageUploadSuccess.title"
            lngProviderButtonKey="app.imageUploadSuccess.button"
          ></SuccessTemplate>
          <ErrorTemplate
            open={this.state.openErrorModal}
            close={closeErrorModal}
            errorMessage={this.state.errorMessage}
          ></ErrorTemplate>
        </div>
      </MuiThemeProvider>
    );
  }
}

// /*
const mapStateToProps = (state: any) => {
  const { campaign, campaignId } = state.campaigns;
  return { campaign, campaignId };
};
// */

const mapDispatchToProps = (dispatch: any) => {
  return {
    serverDialog: (
      callBack: any,
      successMsg: string,
      failMsg: string,
      options?: IdialogOptions
    ) => dispatch(serverDialog(callBack, successMsg, failMsg, options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(imageAnalystComponent));
