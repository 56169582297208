import { UserActionsEnum } from "../actions/UsersActions";
import { User } from "../stores/users.interface";
import _ from "lodash";

export interface UsersState {
  users: { [field: string]: User };
}
// INITIALIZE STATE
export const initialUsersState: UsersState = {
  users: {},
};

const usersReducer = (state: UsersState = initialUsersState, action: any) => {
  switch (action.type) {
    case UserActionsEnum.GET_CAMPAIGN_EMPLOYESS:
      return {
        ...state,
        users: action.users,
      };
    case UserActionsEnum.ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: { id: action.userId, ...action.user },
        },
      };

    case UserActionsEnum.UPDATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: { id: action.userId, ...action.user },
        },
      };

    default:
      return state;
  }
};

export default usersReducer;
