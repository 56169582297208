// IMPORT PACKAGE REFERENCES
import { combineReducers, Reducer } from "redux";

// IMPORT REDUCERS
import LeftBannerReducer from "./LeftBannerReducer";
import AuthReducer from "./AuthReducer";
import CampaignsReducer from "./CampaignsReducer";
import VillagesReducer from "./VillagesReducer";
import ObserverReducer from "./ObserverReducer";
import settings from "./Settings";
import waterSource from "./WaterSourceReducer";
import usersReducer from "./UsersReducer";
import manageApiDialog, { IManageDialogState } from "./ManageApiDialogReducer";
import spinner from "./SpinnerReducer";
import houses from "./HouseReducer";
import tasks from "./TasksReducer";
import StationReducer from "./StationReducer";
import ShelterReducer from "./ShelterReducer";

// EXPORT APP REDUCER
const AppReducer: Reducer<any | IManageDialogState> = combineReducers({
  leftBanner: LeftBannerReducer,
  auth: AuthReducer,
  campaigns: CampaignsReducer,
  observers: ObserverReducer,
  villages: VillagesReducer,
  settings: settings,
  usersReducer,
  manageApiDialog: manageApiDialog,
  waterSource: waterSource,
  spinner: spinner,
  stations: StationReducer,
  shelters: ShelterReducer,
  houses,
  tasks,
});

export default AppReducer;
