import * as React from "react";
import { FunctionComponent, useState } from "react";

interface IvillageDetailsBarProps {
  deleteStation: () => void;
  submitStation: (name: string) => void;
}

export const StationDetailsBar: FunctionComponent<IvillageDetailsBarProps> = (
  props
) => {
  const [stationDescription, setDesc] = useState("");

  return (
    <div className="bottom-tool-bar">
      <div className="bottom-bar-section">
        <div className="delete-path">
          <div className="delete-txt" onClick={props.deleteStation}>
            <img src={"/images/delete-village-icon.svg"} alt="" />
            <span>Delete</span>
          </div>
        </div>
        <div className="village-name-section">
          <input
            type="text"
            placeholder={"Station Description"}
            className="input-village-name"
            onChange={(event) => setDesc(event.target.value)}
          />
        </div>
        <div
          className={"create-btn"}
          onClick={() => props.submitStation(stationDescription)}
        >
          <div className="create-btn-txt">Create</div>
        </div>
      </div>
    </div>
  );
};
