import * as React from "react";
import { FunctionComponent, useState } from "react";
import mgrs from "mgrs";

interface IChunkSideBarProps {
  currentChunk: any;
  handleVillageOnSideBar: any;
  getVillageNameById: any;
  currentChunkWaterSourcesCount: number;
  currentChunkSprayedWaterSourcesCount: number;
}

export const ChunkSideBar: FunctionComponent<IChunkSideBarProps> = (props) => {
  if (props.currentChunk)
    return (
      <div className="r-container chunk-info">
        <div className={"chunk-contain"}>
          <div
            className="chunk-back-btn"
            onClick={() => {
              props.handleVillageOnSideBar(props.currentChunk.village);
            }}
          >
            <div className={"back-icon"}>
              <img src="/images/back-icon.svg" alt="" />
            </div>
            <div className="back-text">
              {`Village ${props.getVillageNameById(
                props.currentChunk.village
              )}`}
            </div>
          </div>
          <div className="chunk-details">
            <div className="chunk-name">
              {"chunk " + props.currentChunk && props.currentChunk.id}
            </div>
            <div className="chunk-long-lat">
              <div className="chunk-long-lat-icon">
                <img
                  src="/images/coordinates.svg"
                  className="coordinates-icon"
                  alt="coordinates icon"
                />
              </div>
              <span>{`${mgrs
                .toPoint(props.currentChunk.id)[0]
                .toFixed(7)}, ${mgrs
                .toPoint(props.currentChunk.id)[1]
                .toFixed(7)}`}</span>
            </div>
          </div>
          <div className="tl-data-container scanner-data-container">
            <div className="chunk-approved pddt10">
              {(() => {
                switch (props.currentChunk.state) {
                  case "approved":
                    return "Approved";
                    break;
                  case "not_approved":
                    return "Not Approved";
                    break;
                  case "inaccessible":
                    return "Inaccessible";
                    break;
                }
              })()}
            </div>
          </div>
          <div className="data-from-field chunk-data-from-field">
            <div className="data-item">
              <div className="data-shape-1 azure"></div>
              <span>{`${props.currentChunkWaterSourcesCount} water bodies`}</span>
            </div>
            <div className="vertical-dotted-line azure"></div>
            <div className="data-item data-item-2">
              <div className="data-shape-2 green"></div>
              <span>{`${props.currentChunkSprayedWaterSourcesCount} sprayed (${
                props.currentChunkWaterSourcesCount
                  ? Math.round(
                      (props.currentChunkSprayedWaterSourcesCount * 100) /
                        props.currentChunkWaterSourcesCount
                    )
                  : 0
              }%)`}</span>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
};
