import * as React from "react";
import { ReactComponent as UnCheckedIcon } from "../../../assets/UnCheckBoxIcon.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/CheckBoxIcon.svg";
import { Checkbox, FormControlLabel } from "@material-ui/core";

// export const FilterCheckBox = React.memo(React.forwardRef((props, ref) => {
//     const {id, onChange, checked, label} = props
//     return <div className="pretty p-default p-curve">
//         <input ref={ref} onChange={() => onChange(id)}
//                checked={checked} type="checkbox"/>
//         <div className="state p-primary-o">
//             <label>{label}</label>
//         </div>
//     </div>
// }))

export const MyCheckBox = React.memo(
  (props: { onChange: any; checked: boolean; label?: string }) => {
    const { onChange, checked, label } = props;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            name="checkedB"
            color="primary"
          />
        }
        label={label}
      />
    );
  }
);
