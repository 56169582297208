import * as React from "react";
import { useState } from "react";
import { Dialog } from "@material-ui/core";
import "./styles.scss";

interface IImageDialogProps {
  onClose: any;
  src: string;
}

export const ImageDialog = React.memo((props: IImageDialogProps) => {
  return (
    <Dialog open={true} maxWidth={false} onClose={props.onClose}>
      <div className="image-dialog">
        <div className="exit-dialog" onClick={props.onClose}>
          X
        </div>
        <img className="image-box" src={props.src} />
      </div>
    </Dialog>
  );
});
