import * as React from "react";
import moment from "moment";

export function getFormatDate(dateStr: string | number) {
  if (isValidDate(new Date(dateStr)))
    return moment(new Date(dateStr)).format("DD.MM");
  return "----";
}
export function getFullFormatDate(dateStr: string | number) {
  if (dateStr) return moment(new Date(dateStr)).format("DD.MM.YY");
  return "----";
}

function isValidDate(d: any) {
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
}
