import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { wsDrawStatus } from "./WaterSourcesDrawer";
import Checkbox from "material-ui/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

import { green } from "@material-ui/core/colors";
import { WATER_SOURCE_STATUS } from "../../../../appRedux/stores/waterSources.interface";

interface IWaterSourcesFiltersProps {
  formatMessage: any;
  showWSLayers: (layerNames: string[]) => void;
  showChunkWsLayer: (layerNames: any) => void;
  showScanLayers: (show: boolean) => void;
  showDeletedSources: (show: boolean) => void;
  onFilterByDeletedWs: (show: boolean) => void;
  zoom: number;
}

interface Interface {
  [fieldName: string]: string[];
}

export const WaterSourcesFilters: FunctionComponent<IWaterSourcesFiltersProps> = (
  props
) => {
  const [filterCard, setFilterCard] = useState(false);
  const [filter, setFilter] = useState("ShowAll");
  const [showScan, setShowScan] = useState(true);
  const [showDeleted, setShowDeleted] = useState(true);

  const waterSourceFilter: Interface = {
    ShowAll: Object.keys(wsDrawStatus),
    ShowUnsprayed: ["new"],
    ShowSprayed: ["sprayed", "treated"],
    ShowSampled: ["sampled", "treated"],
    ShowIssues: ["issue"],
  };

  const updateStatuses = (allowed: string[]) => {
    const filtered = Object.keys(wsDrawStatus)
      .filter((key: string) => allowed.includes(key))
      .reduce((obj: any, key: string) => {
        return {
          ...obj,
          [key]: wsDrawStatus[key],
        };
      }, {});
    return filtered;
  };

  const handleFilterWs = (filterMode: string, layerNames: any) => {
    setFilter(filterMode);
    props.showWSLayers(layerNames);
    props.showChunkWsLayer(updateStatuses(layerNames));
  };

  const handleCheckScan = (e: any) => {
    setShowScan((prevState) => !prevState);
    props.showScanLayers(!e.target.checked);
  };
  const handleCheckDeletedWs = (e: any) => {
    setShowDeleted((prevState) => !prevState);
    props.showDeletedSources(!e.target.checked);
    props.onFilterByDeletedWs(!e.target.checked);
  };

  if (props.zoom < 14) return null;

  return (
    <div id="ws-selector" className="dropdown">
      <div onClick={() => setFilterCard(!filterCard)}>
        <div className="dropdown-btn">
          <span>{props.formatMessage({ id: "app." + filter })}</span>
          <img src={"/images/arrow-down.svg"} alt="" />
        </div>
        <div className={`dropdown-menu ${filterCard ? " show" : ""}`}>
          {Object.keys(waterSourceFilter).map((filterMode: string) => (
            <a
              className={`dropdown-item ${
                filter === filterMode ? " selected-ws-item" : ""
              }`}
              onClick={() =>
                handleFilterWs(filterMode, waterSourceFilter[filterMode])
              }
              key={filterMode}
            >
              {props.formatMessage({ id: "app." + filterMode })}
            </a>
          ))}
        </div>
      </div>
      <Tooltip title={"hide scan"}>
        <Checkbox
          checked={!showScan}
          onCheck={handleCheckScan}
          color="primary"
        />
      </Tooltip>
      <Tooltip title={"hide deleted sources"}>
        <Checkbox
          checked={!showDeleted}
          onCheck={handleCheckDeletedWs}
          color="primary"
        />
      </Tooltip>
    </div>
  );
};
