import * as React from "react";
import { Dialog } from "@material-ui/core";
import "react-infinite-calendar/styles.css";
import InfiniteCalendar from "react-infinite-calendar";
import { exportData } from "../../../../../services/CampaignsService";
import "./ExportData.scss";
import { getUser } from "../../../../../services/UsersService";
import { getCurrentUserProfile } from "../../../../../services/GlobalService";
import { serverDialog } from "../../../../../appRedux/actions/ManageApiDialogAction";
import { connect } from "react-redux";

export interface Props {
  openExportData: boolean;
  campaignName: string;
  serverDialog: (
    serverCallBack: () => void,
    successMessage: string,
    failMessage: string
  ) => void;
  campaignId: string;
  handleExportData: (status: boolean) => void;
}

export interface State {
  openDatePicker: boolean;
  datePicker: Date;
  email: string;
}

class ExportData extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDatePicker: false,
      datePicker: new Date(),
      email: "",
    };
  }

  componentDidMount() {
    getUser(getCurrentUserProfile().id).then((res: any) => {
      this.setState({ email: res.data.email });
    });
    this.setState({ openDatePicker: false });
  }

  handleDateChange = (date: Date) => {
    this.setState({ datePicker: date });
  };

  handleOpenDatePicker = () => {
    this.setState({ openDatePicker: !this.state.openDatePicker });
  };
  handleChangeEmail = (e: any) => {
    this.setState({ email: e.target.value });
  };
  getFormatDate = (date: Date) => {
    const currentDate = new Date(date);
    return `${currentDate.getMonth() +
      1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  };
  handleSendExportData = () => {
    this.props.serverDialog(
      () =>
        exportData(
          this.props.campaignId,
          this.state.email,
          this.state.datePicker.getTime()
        ),
      "Export data send to " + this.state.email,
      "Export data fail"
    );
    this.handleCloseCard();
  };

  handleCloseCard = () => {
    this.setState({ openDatePicker: false });
    this.props.handleExportData(false);
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        onClose={this.handleCloseCard}
        aria-labelledby="simple-dialog-title"
        open={this.props.openExportData}
      >
        <div className="data-exporting-dialog">
          <img
            className="close-data-export-dialog"
            onClick={() => this.props.handleExportData(false)}
            src="/images/delete-icon.svg"
          />
          <div className="main-export">
            <div className="data-exporting-header">
              {this.props.campaignName}
            </div>
            <div className="">
              Please pick the date of the exporting that you are interested in.
              The file will be sent to your mail.{" "}
            </div>
            <input
              onChange={this.handleChangeEmail}
              value={this.state.email}
              className="data-exporting-orders"
            />
            <div
              onClick={this.handleOpenDatePicker}
              className="data-exporting-date noselect"
            >
              {this.getFormatDate(this.state.datePicker)}
            </div>
            {this.state.openDatePicker && (
              <div className="calendar">
                <InfiniteCalendar
                  width={350}
                  height={280}
                  maxDate={new Date()}
                  selected={this.state.datePicker}
                  onSelect={(date: Date) => {
                    this.handleDateChange(date);
                  }}
                />
              </div>
            )}
          </div>
          <button
            className="export-data-button"
            onClick={this.handleSendExportData}
          >
            Export
          </button>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    serverDialog: (callBack: any, successMsg: string, failMsg: string) =>
      dispatch(serverDialog(callBack, successMsg, failMsg)),
  };
};
export default connect(null, mapDispatchToProps)(ExportData);
