export enum LeftBannerFeature {
  Map,
  Tasks,
  ImageAnalyst,
  Employee,
  CampaignManage,
  Observer,
  LabManage,
}

//ACTION NAMES
export const SET_PRESSED = "SET_PRESSED";

export const setPressedAction = (pressed: LeftBannerFeature) => ({
  type: SET_PRESSED,
  payload: pressed,
});
