import {
  addUser,
  updateUser,
} from "../../services/UsersService";
import { UpdateUser, User } from "../stores/users.interface";
import { Dispatch } from "redux";
import _ from "lodash";

export enum UserActionsEnum {
  GET_CAMPAIGN_EMPLOYESS = "GET_CAMPAIGN_EMPLOYESS",
  ADD_USER = "ADD_USER",
  UPDATE_USER = "UPDATE_USER",
}

export const addUserAction = (newUserData: User) => {
  return async (dispatch: Dispatch) => {
    const { data } = await addUser(newUserData);
    dispatch({
      type: UserActionsEnum.ADD_USER,
      userId: data,
      user: newUserData,
    });
  };
};

export const updateUserAction = (id: string, updateUserData: UpdateUser) => {
  return async (dispatch: Dispatch) => {
    const userData = _.omit(updateUserData, "phoneNumber");
    await updateUser(id, userData);
    dispatch({
      type: UserActionsEnum.UPDATE_USER,
      userId: id,
      user: updateUserData,
    });
  };
};
