import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addAssignmentAction } from "../../../appRedux/actions/AssignmentActions";
import {
  IdialogOptions,
  serverDialog,
  serverDialogMessage,
  serverLoading,
} from "../../../appRedux/actions/ManageApiDialogAction";
import {
  addTaskAction,
  fetchTasksAction,
  TaskActions
} from "../../../appRedux/actions/TaskActions";
import {
  LeftBannerFeature,
  setPressedAction,
} from "../../../appRedux/actions/LeftBannerActions";
import { IAssignment } from "../../../appRedux/stores/assignment.interface";
import { ITask } from "../../../appRedux/stores/task.interface";
import { Task } from "./Task";
import {
  fetchCampaignAction,
  clearAssignmentAction,
  fetchCampaignScansFileAction,
} from "../../../appRedux/actions/CampaignsActions";

const mapStateToProps = (state: any) => {
  const getVillageNameById = (villageId: string) => {
    if (state.villages.villageV1[villageId]) {
      return state.villages.villageV1[villageId].name;
    }
    return "Village";
  };
  return {
    users: Object.values(state.usersReducer.users).filter(
      (user: any) => user.role === 4
    ),
    tasks: state.tasks.tasks,
    villages: Object.values(state.villages.villageV1).sort((a: any, b: any) => a.name.localeCompare(b.name)),
    campaign: state.campaigns.campaign,
    getVillageNameById,
    error: state.tasks.error,
  };
};

const matchDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    serverDialog: (
      callBack: any,
      successMsg: string,
      failMsg: string,
      options?: IdialogOptions
    ) => dispatch(serverDialog(callBack, successMsg, failMsg, options)),
    serverLoading: (callBack: () => any, options?: IdialogOptions) =>
      dispatch(serverLoading(callBack, options)),
    serverDialogMessage: (msg: string, header: string) =>
      dispatch(serverDialogMessage(msg, header)),
    addTask: (campaignId: string, task: ITask) =>
      dispatch(addTaskAction(campaignId, task)),
    fetchTasks: (campaignId: string) => dispatch(fetchTasksAction(campaignId)),
    addAssignment: (newAssignmentData: IAssignment) =>
      dispatch(addAssignmentAction(newAssignmentData)),
    clearAssignment: () =>
      dispatch(clearAssignmentAction()),
    handleTaskReview: (
      history: any,
      campaignId: string,
      assignmentId: string,
      taskType: number,
      taskSummary: any
    ) => {
      dispatch(
        serverLoading(() =>
          dispatch(fetchCampaignAction(campaignId, assignmentId, taskType, taskSummary))
        )
      );
      dispatch(fetchCampaignScansFileAction(campaignId, assignmentId));
      localStorage.setItem("lastCampaign", campaignId);
      dispatch(setPressedAction(LeftBannerFeature.Map));
      history.push("/map");
    },
    handleError: () => {
      dispatch({
        type: TaskActions.TASK_ERROR,
        error: undefined,
      });
    }
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Task);
