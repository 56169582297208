import { fetchVillageScan } from "../../../../services/VillageService";
import { getEmptyGeoJson, hideLayout, showLayout } from "../utils";
import { getScanLayer, getScanSource } from "./utils";

class ScanDrawer {
  private map: any;
  private scanPolygon: any;
  private scansMapId = "scansMapId";
  private chunkSourceId = "chunkSourceId";

  private fetchForCampaign = "";
  private onDownloadedFile = null;

  constructor(map: any, onDownloadedFile: any) {
    this.map = map;
    this.scanPolygon = [];
    this.onDownloadedFile = onDownloadedFile;
    this.drawScan();
  }

  drawScan = () => {
    this.map.addSource(this.scansMapId, getEmptyGeoJson());
    this.map.addLayer(getScanLayer(this.scansMapId));
    this.map.on("zoom", this.onZoom16);
    this.map.moveLayer(this.scansMapId, this.chunkSourceId);
  };

  onZoom16 = () => {
    // if (this.fetchForCampaign && this.map.getZoom() > 16){
    //   this.fetchScan(this.fetchForCampaign)
    //   this.fetchForCampaign = ''
    // }
  };

  fetchScan = (villageId: string, assignmentId?: string) => {
    const source = this.map.getSource(this.scansMapId);
    if (source) {
      this.fetchScanSource(villageId, assignmentId).then((res) => {
        // if type Feature great, if FeatureCollection so take the first feature
        const add_features = res.type === "Feature" ? [res] : res.features;
        // sometimes scan generation wasn't triggered so we get an empty result here,
        // instead of crashing we should fail silently
        if (!add_features) {
          console.log("No features in scan, not displaying scan", res);
          return;
        }
        const features = [...source._data.features, ...add_features];
        this.map
          .getSource(this.scansMapId)
          .setData({ type: "FeatureCollection", features });
      });
    }
  };

  onChangeCampaign = () => {
    if (this.map.getSource(this.scansMapId)) {
      this.map.getSource(this.scansMapId).setData(getEmptyGeoJson().data);
    }
  };

  fetchScanSource = async (villageId: string, assignmentId?: string) => {
    // @ts-ignore
    const { data } = await fetchVillageScan(villageId, assignmentId);
    // @ts-ignore
    this.onDownloadedFile && this.onDownloadedFile(villageId);
    return data;
  };

  setLayerVisibility = (showScan: boolean) => {
    if (showScan) showLayout(this.scansMapId, this.map);
    else hideLayout(this.scansMapId, this.map);
  };
}

export default ScanDrawer;
