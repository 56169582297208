import { TaskActions } from "../actions/TaskActions";
import { ITask } from "../stores/task.interface";
import { StatusTask } from "../../Enums";
import { AssignmentActions } from "../actions/AssignmentActions";

export interface TasksState {
  tasks: { [field: string]: ITask };
}
// INITIALIZE STATE
export const initialTasksState: TasksState = {
  tasks: {},
};

const tasksReducer = (state: TasksState = initialTasksState, action: any) => {
  switch (action.type) {
    case TaskActions.ADD_TASK: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
           [action.task.id]: action.task
         },
      };
    }

    case TaskActions.FETCH_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      };

    case TaskActions.CANCEL_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...action.task,
            status: StatusTask.CANCELLED
          },
        },
      };
    case TaskActions.TASK_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case TaskActions.SET_STATUS_ASSIGNMENT:
      const assignment = action.assignment;
      const taskId = action.taskId;
      let assignments = state.tasks[taskId].assignments;
      const index = assignments.findIndex(
        (a: any) => a.id === assignment.id
      );
      assignments = assignments.slice();
      assignments[index] = {
        ...assignment,
        history: [
          ...assignment.history,
          ...assignments[index].history || [],
        ]
      };
      console.log(taskId, assignments[index], index, action)

      // Return new state
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [taskId]: {
            ...state.tasks[taskId],
            status: action.newTaskStatus,
            activeAssignment: assignments[index],
            assignments,
          }
        }
      };
      // return {
        // ...state,
        // tasks: action.tasks,
      // };
    case AssignmentActions.ADD_ASSIGNMENT: {
      console.log('action', action)
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.id]: {
            ...action.task,
          },
        },

        // tasks: {
          // ...state.tasks,
          // [action.id]: {
          //   task
            // userId: action.assignment.userId,
            // order: action.assignment.order,
            // isCanceled: action.assignment.isCanceled,
            // assignedOn: action.assignment.assignedOn,
        //   },
        // },
      };
    }
    default:
      return state;
  }
};

export default tasksReducer;
