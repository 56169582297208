import { WaterSource } from "../../../../../appRedux/stores/waterSources.interface";
import { wsDrawStatus } from "../WaterSourcesDrawer";

export const getWaterSourceLayer = (layerId: string, iconSize: number) => {
  return {
    id: layerId,
    minzoom: 16,
    maxzoom: 24,
    type: "symbol",
    source: layerId,
    layout: {
      visibility: "visible",
      "icon-image": ["get", "icon"],
      "icon-size": iconSize,
      "icon-allow-overlap": true,
    },
    filter: ["in", "icon"].concat(Object.keys(wsDrawStatus)),
  };
};

export const getWsSource = (waterSources: WaterSource[]) => {
  const features: any = waterSources.map((waterSource: WaterSource) => {
    return getWaterIconFeature(waterSource);
  });
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
};

export const getWaterIconFeature = (ws: WaterSource) => {
  return {
    type: "Feature",
    id: ws.id,
    geometry: {
      type: "Point",
      coordinates: ws.center,
    },
    properties: {
      wsId: ws.id,
      icon: ws.currentStatus,
      deleted: !!ws.deleted,
    },
  };
};
