import { connect } from "react-redux";
import { editVillageAction } from "../../../../../appRedux/actions/VillagesActions";
import { VillageSideBar } from "./VillageSideBar";
import {
  IdialogOptions,
  serverDialog,
  serverLoading,
} from "../../../../../appRedux/actions/ManageApiDialogAction";
import { getCurrentUserProfile } from "../../../../../services/GlobalService";

const mapStateToProps = (state: any, ownProps: any) => {
  const village = state.villages.villageV1[ownProps.villageId];
  const campaign = state.campaigns.campaign;

  const chunksBelt = village
    ? village.chunks.filter((chunk: any) => chunk.type === "belt").length
    : 0;
  const chunksLength = village ? village.chunks.length : 0;
  const currentChunksOfVillageData = {
    chunksBelt,
    chunksCore: chunksLength - chunksBelt,
    chunksLength,
  };
  const { users } = state.usersReducer;

  return {
    village: village,
    currentChunksOfVillageData,
    users,
    scanFiles: state.campaigns.scans,
    currentUserProfileRole: getCurrentUserProfile().role,
    campaign: campaign,
    trapGraph: village.summary.totalTraps > 0, //TODO: i realy nead flag for trap result
    sampleGraph: village.summary.totalSampled > 0,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    editVillage: (body: any, campaignId: string, villageId: string) =>
      dispatch(editVillageAction(body, campaignId, villageId)),
    serverLoading: (callBack: () => any, options?: IdialogOptions) =>
      dispatch(serverLoading(callBack, options)),
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(VillageSideBar);
