import React, { Component } from "react";
import "./leftBannerComponent.scss";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import Bar from "./Bar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchCampaignAction,
  fetchCampaignScansFileAction,
} from "../../../appRedux/actions/CampaignsActions";
import {
  getCurrentUserProfile,
  currentUserProfile,
} from "../../../services/GlobalService";
import { getUser } from "../../../services/UsersService";
import { User } from "../../../appRedux/stores/users.interface";
import { Enums } from "../../../Enums";
import {
  serverDialog,
  serverLoading,
} from "../../../appRedux/actions/ManageApiDialogAction";
import {
  setPressedAction,
  LeftBannerFeature,
} from "../../../appRedux/actions/LeftBannerActions";
import { triggerScansFiles } from "../../../services/CampaignsService";

const hiddenStyle = {
  visibility: "hidden",
} as React.CSSProperties;

const visibleStyle = {
  visibility: "visible",
} as React.CSSProperties;

class LeftBannerComponent extends Component<any, any> {
  static contextTypes = {
    router: PropTypes.object,
  };

  private minimizeCounter = 0;
  private _currentProfile: currentUserProfile | null = null;

  constructor(props: any, context: any) {
    super(props, context);
    this._currentProfile = getCurrentUserProfile();
    this.state = {
      openErrorModal: false,
      errorMessage: "",
      isCampaignManagementPage: this.props.isCampaignManagementPage,
      isObserverManagementPage: this.props.isObserverManagementPage,
      currentPathName: this.props.currentPathName,
      currentUserProfile: {
        role: this._currentProfile ? this._currentProfile.role : null,
        userName: "",
      },
    };
  }

  private errorHandler = (errorMessage: string) => {
    this.setState(
      (prevState: any) => ({
        ...prevState,
        errorMessage: errorMessage,
      }),
      () => {
        this.setState({ openErrorModal: true });
      }
    );
  };

  componentWillMount() {
    if (this._currentProfile) {
      getUser(this._currentProfile.id)
        .then((res: any) => {
          if (res) {
            if (res.data) {
              const currentUser: User = res.data;
              this.setState({
                currentUserProfile: {
                  role: this._currentProfile ? this._currentProfile.role : null,
                  userName: currentUser.fullName,
                },
              });
            }
          }
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response) {
            this.errorHandler(err.response.data.message);
          }
        });
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.state.minimizeIsPressed) {
      window.dispatchEvent(new Event("resize"));
    }
    if (
      this.state.isCampaignManagementPage !==
      this.props.isCampaignManagementPage
    ) {
      this.setState({
        isCampaignManagementPage: this.props.isCampaignManagementPage,
      });
    }
    if (
      this.state.isObserverManagementPage !==
      this.props.isObserverManagementPage
    ) {
      this.setState({
        isObserverManagementPage: this.props.isObserverManagementPage,
      });
    }
    //if we have just a single campaign or a lastCampaign, load it automatically.
    if (
      this.props.campaigns &&
      this.props.campaigns.length >= 1 &&
      this.props.campaigns !== prevProps.campaigns
    ) {
      const lastCampaign = localStorage.getItem("lastCampaign");
      if (lastCampaign !== null || this.props.campaigns.length === 1) {
        const lastCampaignId: number =
          lastCampaign !== null ? parseInt(lastCampaign) : -1;
        const campaignId =
          this.props.campaigns.length === 1
            ? this.props.campaigns[0].id
            : lastCampaignId;
        if (campaignId >= 0) {
          this.handleSelectCampaign(campaignId);
        }
      }
    }
  }

  render() {
    return (
      <div
        className={
          "left-banner gray-scroller" +
          (this.state.minimizeIsPressed && " minimize-arrow-pressed")
        }
      >
        {this.createHeader()}
        <div
          className={
            "upper-area" +
            (this.state.minimizeIsPressed ? " upper-area-minimized" : "")
          }
        >
          {this.createMapItem()}
          {this.createEmployeeItem()}
          {this.createTasksItem()}
          {this.createImageAnalystItem()}
          {this.createCampaignsItems()}
        </div>
        <div
          className={
            "lower-area" +
            (this.state.minimizeIsPressed ? " lower-area-minimized" : "")
          }
        >
          <hr
            className={
              "mb-2rem separator-line" +
              (this.state.minimizeIsPressed ? " separator-line-minimized" : "")
            }
          />
          {/* {this.state.currentUserProfile.role === 1
            ? this.createLabManagementItem()
            : null} */}
          {this.state.currentUserProfile.role === 1
            ? this.createCampaignManagementItem()
            : null}
          {/* {this.state.currentUserProfile.role === 1 ? this.createObserversItem() : null} */}
          {this.createLogoutItem()}
        </div>
      </div>
    );
  }

  private createLogoutItem() {
    return (
      <div
        className={
          "logout-container" +
          (this.state.minimizeIsPressed ? " logout-container-minimized" : "")
        }
      >
        <div
          className={
            "logout-icon" +
            (this.state.minimizeIsPressed ? " logout-icon-minimized" : "")
          }
          onClick={this.handleLogOut.bind(this)}
        />
        <div
          className={
            "logout-text" +
            (this.state.minimizeIsPressed ? " logout-text-minimized" : "")
          }
          onClick={this.handleLogOut.bind(this)}
        >
          Log out
        </div>
      </div>
    );
  }

  private createObserversItem() {
    return (
      <div
        className={
          "observers-container" +
          (this.state.minimizeIsPressed ? " observers-container-minimized" : "")
        }
      >
        <div
          className={
            "observers-icon" +
            (this.state.pressed == LeftBannerFeature.Observer
              ? " observers-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " observers-icon-minimized" : "")
          }
          onClick={this.handleObserverPress.bind(this)}
        />
        <div
          className={
            "observers-text" +
            (this.state.pressed == LeftBannerFeature.Observer
              ? " observers-text-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " observers-text-minimized" : "")
          }
          onClick={this.handleObserverPress.bind(this)}
        >
          Observers
        </div>
      </div>
    );
  }
  private createLabManagementItem() {
    return (
      <div
        className={
          "lab-management-container btm-space" +
          (this.state.minimizeIsPressed
            ? " lab-management-container-minimized"
            : "")
        }
      >
        <div
          className={
            "lab-management-icon" +
            (this.state.pressed == LeftBannerFeature.LabManage
              ? " lab-management-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed
              ? " lab-management-icon-minimized"
              : "")
          }
          onClick={this.handleLabManagePress.bind(this)}
        />
        <div
          className={
            "lab-management-text" +
            (this.state.pressed == LeftBannerFeature.LabManage
              ? " lab-management-text-pressed"
              : "") +
            (this.state.minimizeIsPressed
              ? " lab-management-text-minimized"
              : "")
          }
          onClick={this.handleLabManagePress.bind(this)}
        >
          Lab management
        </div>
      </div>
    );
  }

  private createCampaignManagementItem() {
    return (
      <div
        className={
          "campaign-management-container" +
          (this.state.minimizeIsPressed
            ? " campaign-management-container-minimized"
            : "")
        }
      >
        <div
          className={
            "campaign-management-icon" +
            (this.state.pressed == LeftBannerFeature.CampaignManage
              ? " campaign-management-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed
              ? " campaign-management-icon-minimized"
              : "")
          }
          onClick={this.handleCampaignManagePress.bind(this)}
        />
        <div
          className={
            "campaign-management-text" +
            (this.state.pressed == LeftBannerFeature.CampaignManage
              ? " campaign-management-text-pressed"
              : "") +
            (this.state.minimizeIsPressed
              ? " campaign-management-text-minimized"
              : "")
          }
          onClick={this.handleCampaignManagePress.bind(this)}
        >
          Campaign management
        </div>
      </div>
    );
  }

  private filterObjectArrayForIntersection(
    idArray: number[],
    idObjectArray: any
  ): any {
    return idObjectArray.filter((idObject: any) => {
      return idArray.includes(idObject.id);
    });
  }

  handleSelectCampaign = (campaignId: string) => {
    if (
      !(
        this.state.isCampaignManagementPage ||
        this.state.isObserverManagementPage
      )
    ) {
      this.props.serverLoading(() =>
        this.props.fetchCampaignAction(campaignId)
      );
      this.props.fetchCampaignScansFileAction(campaignId);
      localStorage.setItem("lastCampaign", campaignId);
    }
  };

  private createCampaignsItems() {
    const hasCampaignsAccess =
      this.state.currentUserProfile.role === Enums.USER_TYPES.admin ||
      this.state.currentUserProfile.role === Enums.USER_TYPES.observer ||
      this.state.currentUserProfile.role === Enums.USER_TYPES.officeWorker;
    const isMin = this.state.minimizeIsPressed;
    const viewableCampaigns = this.props.campaigns;
    return (
      <div>
        {hasCampaignsAccess && (
          <div
            style={
              this.state.isCampaignManagementPage ||
              this.state.isObserverManagementPage
                ? hiddenStyle
                : visibleStyle
            }
            className={
              "campaigns-container" +
              (this.state.minimizeIsPressed
                ? " campaigns-container-minimized"
                : "")
            }
          >
            <div className={`campaigns-header ${isMin ? "header-min" : ""}`}>
              {isMin ? "C" : "Campaigns"}
            </div>

            <div
              className={`campaigns-name-list ${
                this.state.minimizeIsPressed
                  ? "hide-scroll-bar is-min-margin"
                  : "gray-scroller is-wide-margin"
              }`}
            >
              {viewableCampaigns
                .sort((prop1: any, prop2: any) =>
                  prop1.name > prop2.name ? 1 : -1
                )
                .map((campaign: any, index: number) => {
                  return (
                    <div
                      key={campaign.id}
                      onClick={() => this.handleSelectCampaign(campaign.id)}
                      className={`campaigns-text ${
                        isMin ? "text-hidden" : ""
                      } ${
                        campaign.id === this.props.campaignId
                          ? " campaigns-pressed"
                          : ""
                      }`}
                    >
                      {isMin ? <Bar /> : null}
                      {campaign.name}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  }

  private createEmployeeItem() {
    return (
      <div
        className={
          "employee-container" +
          (this.state.minimizeIsPressed ? " employee-container-minimized" : "")
        }
      >
        <div
          className={
            "employee-icon" +
            (this.state.pressed == LeftBannerFeature.Employee
              ? " employee-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " employee-icon-minimized" : "")
          }
          onClick={this.handleEmployeePress.bind(this)}
        />
        <div
          className={
            "employee-text" +
            (this.state.pressed == LeftBannerFeature.Employee
              ? " employee-text-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " employee-text-minimized" : "")
          }
          onClick={this.handleEmployeePress.bind(this)}
        >
          Employees
        </div>
      </div>
    );
  }

  createTasksItem = () => {
    if (
      this.state.currentUserProfile.role === Enums.USER_TYPES.admin ||
      this.state.currentUserProfile.role === Enums.USER_TYPES.officeWorker
    ) {
      return (
        <div
          className={
            "employee-container" +
            (this.state.minimizeIsPressed
              ? " employee-container-minimized"
              : "")
          }
        >
          <div
            className={
              "trap-results-icon" +
              (this.state.pressed == LeftBannerFeature.Tasks
                ? " trap-results-pressed"
                : "") +
              (this.state.minimizeIsPressed ? " trap-results-minimized" : "")
            }
            onClick={this.handleTasksPress}
          />
          <div
            className={
              "employee-text" +
              (this.state.pressed == LeftBannerFeature.Tasks
                ? " employee-text-pressed"
                : "") +
              (this.state.minimizeIsPressed ? " employee-text-minimized" : "")
            }
            onClick={this.handleTasksPress}
          >
            Tasks
          </div>
        </div>
      );
    }
  };

  private createMapItem() {
    return (
      <div
        className={
          "map-container" +
          (this.state.minimizeIsPressed ? " map-container-minimized" : "")
        }
      >
        <div
          className={
            "map-icon" +
            (this.state.pressed == LeftBannerFeature.Map
              ? " map-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " map-icon-minimized" : "")
          }
          onClick={this.handleMapPress.bind(this)}
        />
        <div
          className={
            "map-text" +
            (this.state.pressed == LeftBannerFeature.Map
              ? " map-text-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " map-text-minimized" : "")
          }
          onClick={this.handleMapPress.bind(this)}
        >
          Map
        </div>
      </div>
    );
  }
  private createImageAnalystItem() {
    return (
      <div
        className={
          "map-container image-analyst-container" +
          (this.state.minimizeIsPressed ? " map-container-minimized" : "")
        }
      >
        <div
          className={
            "drone-icon " +
            (this.state.pressed == LeftBannerFeature.ImageAnalyst
              ? "drone-icon-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " map-icon-minimized" : "")
          }
          onClick={this.handleImageAnalystPress.bind(this)}
        />
        <div
          className={
            "map-text " +
            (this.state.pressed == LeftBannerFeature.ImageAnalyst
              ? " map-text-pressed"
              : "") +
            (this.state.minimizeIsPressed ? " map-text-minimized" : "")
          }
          onClick={this.handleImageAnalystPress.bind(this)}
        >
          Image Analyst
        </div>
      </div>
    );
  }

  createHeader = () => {
    return (
      <div
        className={
          "header" + (this.state.minimizeIsPressed ? " header-minimized" : "")
        }
      >
        <div className="zzapp-text-container">
          <div
            onClick={this.handleTriggerScanVillages}
            className={
              "zzapp-text" +
              (this.state.minimizeIsPressed
                ? " zzapp-text-minimize-screen"
                : "")
            }
          />
          {this.state.minimizeIsPressed ? null : (
            <div className="zzapp-user">
              {this.state.currentUserProfile.userName}
            </div>
          )}
        </div>
        <div
          className={
            "minimize-arrow-left" +
            (this.state.minimizeIsPressed ? " minimize-arrow-right" : "")
          }
          onClick={this.handleMinimizePress.bind(this)}
        />
      </div>
    );
  };

  handleTriggerScanVillages = () => {
    if (this.props.campaignId !== "")
      this.props.serverDialog(
        () => triggerScansFiles(this.props.campaignId),
        "Trigger Pass",
        "Trigger Failed",
        { showMessageFromService: true }
      );
  };

  private handleMinimizePress() {
    this.minimizeCounter++;
    if (this.minimizeCounter % 2 === 1) {
      this.setState({ minimizeIsPressed: true });
    } else {
      this.setState({ minimizeIsPressed: false });
    }
  }

  private handleObserverPress() {
    this.onPressActionHandler(LeftBannerFeature.Observer);
    this.context.router.history.push("/observer-management");
  }

  private handleCampaignManagePress() {
    this.onPressActionHandler(LeftBannerFeature.CampaignManage);
    this.context.router.history.push("/campaign-management");
  }

  private handleLabManagePress() {
    this.onPressActionHandler(LeftBannerFeature.LabManage);
  }

  handleEmployeePress = () => {
    this.onPressActionHandler(LeftBannerFeature.Employee);
    this.context.router.history.push("/employees");
  };

  handleTasksPress = () => {
    this.onPressActionHandler(LeftBannerFeature.Tasks);
    this.context.router.history.push("/tasks");
  };

  private handleMapPress() {
    this.onPressActionHandler(LeftBannerFeature.Map);
    this.context.router.history.push("/map");
  }

  private handleImageAnalystPress() {
    this.onPressActionHandler(LeftBannerFeature.ImageAnalyst);
    this.context.router.history.push("/images");
  }

  private handleLogOut() {
    localStorage.clear();
    this.context.router.history.push("/login");
  }

  private onPressActionHandler(leftBannerFeature: LeftBannerFeature) {
    if (this.props.assignmentId) {
      // if we had an assignmentId filter, we need to re-fetch the entire unfiltered campaign
      this.handleSelectCampaign(this.props.campaignId);
    }
    this.props.setPressedAction(leftBannerFeature);
  }
}
// CONFIGURE REACT REDUX
const mapStateToProps = (state: any) => {
  const { campaigns, campaignId, assignmentId } = state.campaigns;
  const campaign = state.campaign;
  const pressed = state.leftBanner.pressed;
  return { campaigns, campaignId, assignmentId, campaign, pressed };
};
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchCampaignAction,
      serverLoading,
      serverDialog,
      fetchCampaignScansFileAction,
      setPressedAction,
    },
    dispatch
  );
// EXPORT COMPONENT
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LeftBannerComponent));
