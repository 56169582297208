import axios from "axios";
import JwtDecode from "jwt-decode";

export const getCurrentOrigin = (): string => {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
};

export const getControlsSetting = (
  color: string = "rgba(30, 203, 224,1)"
): any => {
  return [
    {
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_class_id"], 1],
          color,
          ["==", ["get", "user_class_id"], 2],
          color,
          color,
        ],
        "fill-outline-color": color,
        "fill-opacity": 0,
      },
    },
    {
      id: "gl-draw-polygon-fill-active",
      type: "fill",
      filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": color,
        "fill-outline-color": color,
        "fill-opacity": 0,
      },
    },
    {
      id: "gl-draw-polygon-midpoint",
      type: "circle",
      filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
      paint: {
        "circle-radius": 3,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-polygon-stroke-active",
      type: "line",
      filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-dasharray": [0.2, 2],
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-line-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "LineString"],
        ["!=", "mode", "static"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-line-active",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-dasharray": [0.2, 2],
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 3,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-point-point-stroke-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Point"],
        ["==", "meta", "feature"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-opacity": 0.41,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-point-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Point"],
        ["==", "meta", "feature"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 3,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-point-stroke-active",
      type: "circle",
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["==", "active", "true"],
        ["!=", "meta", "midpoint"],
      ],
      paint: {
        "circle-radius": 7,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-point-active",
      type: "circle",
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["!=", "meta", "midpoint"],
        ["==", "active", "true"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-polygon-fill-static",
      type: "fill",
      filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": color,
        "fill-outline-color": color,
        "fill-opacity": 0.41,
      },
    },
    {
      id: "gl-draw-polygon-stroke-static",
      type: "line",
      filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-line-static",
      type: "line",
      filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-point-static",
      type: "circle",
      filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
      paint: {
        "circle-radius": 5,
        "circle-color": color,
      },
    },
    {
      id: "gl-draw-polygon-color-picker",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["has", "user_portColor"]],
      paint: {
        "fill-color": ["get", "user_portColor"],
        "fill-outline-color": ["get", "user_portColor"],
        "fill-opacity": 0.25,
      },
    },
    {
      id: "gl-draw-line-color-picker",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["has", "user_portColor"]],
      paint: {
        "line-color": ["get", "user_portColor"],
        "line-width": 2,
      },
    },
    {
      id: "gl-draw-point-color-picker",
      type: "circle",
      filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
      paint: {
        "circle-radius": 3,
        "circle-color": ["get", "user_portColor"],
      },
    },
  ];
};

export const isEmptyOrSpaces = (str: string): boolean => {
  if (!str) {
    return true;
  }
  return str.match(/^ *$/) !== null;
};

export const truncateCoord = (s_coord: number, cut_len: number): number => {
  let ratio: number = 10;
  for (let i = 1; i < cut_len; i++) {
    ratio = ratio * 10;
  }
  return parseInt("" + s_coord * ratio) / ratio;
};

export const getCountries = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  return axios.get("https://restcountries.eu/rest/v2/all", {
    headers: headers,
  });
};

export interface currentUserProfile {
  campaignIds: string[];
  id: string;
  phoneNumber: string;
  role: number;
}

export const getCurrentUserProfile = (): currentUserProfile => {
  const decoded: any = localStorage.getItem("token");
  const userData: any = JwtDecode(decoded);
  return userData.user;
};
