import {
  FETCH_VILLAGES,
  ADD_VILLAGE,
  GET_CHUNKS_V2,
  FILTER_VILLAGE_SUMMARY,
  REMOVE_SUMMARY_FILTER,
} from "../actions/VillagesActions";
import { normalize } from "normalizr";
import { villageSchema } from "../schemas/Village";

export interface VillageState {
  villageV1: any;
  snapShotVillage: any;
  chunks_v2: any;
}
// INITIALIZE STATE
const initialVillagesState: VillageState = {
  villageV1: [],
  snapShotVillage: [],
  chunks_v2: {},
};

// REDUCER
const VillagesReducer = (
  state: VillageState = initialVillagesState,
  action: any
) => {
  switch (action.type) {
    case FETCH_VILLAGES:
      return {
        ...state,
        villageV1: action.villages,
      };

    case ADD_VILLAGE:
      const village = action.village;
      const villagesObj = {
        ...state.villageV1,
        [village.id]: { ...village },
      };
      return {
        ...state,
        villageV1: villagesObj,
      };

    case GET_CHUNKS_V2:
      return {
        ...state,
        chunks_v2: action.chunks,
      };

    case FILTER_VILLAGE_SUMMARY:
      const villageSummary = action.villagesSummary.map((v: any) => ({
        ...state.villageV1[v.village],
        summary: { ...state.villageV1[v.village].summary, ...v.summary },
      }));
      return {
        ...state,
        snapShotVillage: state.villageV1,
        villageV1:
          normalize(villageSummary, [villageSchema]).entities.villages || {},
      };
    case REMOVE_SUMMARY_FILTER:
      return {
        ...state,
        villageV1: state.snapShotVillage,
      };

    default:
      return state;
  }
};

export default VillagesReducer;
