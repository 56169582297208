import * as React from "react";
import "./VillageSideBar.scss";
import moment from "moment";
import {
  Village,
  VillageStatus,
} from "../../../../../appRedux/stores/village.interface";
import { User } from "../../../../../appRedux/stores/users.interface";
import { Campaign } from "../../../../../appRedux/stores/campaign.interface";
import { IdialogOptions } from "../../../../../appRedux/actions/ManageApiDialogAction";
import { getFixedPercent } from "../CampaignSideBar";
import { GroupGraph } from "./VillagesTrapsGraph";
import {
  fetchVillageSampleResults,
} from "../../../../../services/TrapService";
import { getLastSpray } from "../villages_table/VillageTable";
import { getFormatDate } from "../../../../outsiders/trapResults/TrapResultDialog";

interface IVillageSideBarProps {
  users: any;
  village: any;
  currentUserProfileRole: any;
  currentUser: User;
  currentChunksOfVillageData: any;
  campaign: Campaign;
  editVillage: (body: any, campaignId: string, villageId: string) => void;
  activeVillage: () => void;
  changeVillageName: (id: string, name: string) => void;
  serverLoading: (callBack: () => any, options?: IdialogOptions) => void;
  backToCampaignSideBar: () => void;
  fetchVillageScan: (village: string, assignmentId?: string) => void;
  scanFiles: any;
  trapGraph: boolean;
  sampleGraph: boolean;
}

interface IVillageSideBarState {
  villageName: string;
  editVillageNameMode: boolean;
  trapGraphMode: boolean;
  sampleGraphMode: boolean;
  villageId: string;
}

export class VillageSideBar extends React.Component<
  IVillageSideBarProps,
  IVillageSideBarState
> {
  private nameInput: any;

  constructor(props: IVillageSideBarProps) {
    super(props);
    initState(props);
    this.state = initState(props);
  }

  static getDerivedStateFromProps(
    props: IVillageSideBarProps,
    state: IVillageSideBarState
  ) {
    if (props.village && props.village.id !== state.villageId) {
      return initState(props);
    }
    return null;
  }
  backToCampaign = () => {
    this.props.backToCampaignSideBar();
  };
  styles: any = {
    tlListItemStyle: {
      padding: "7px 14px 0px 14px",
    },
  };

  render() {
    const { village, currentChunksOfVillageData } = this.props;
    if (village)
      return (
        <div key={village.id} className="r-container village">
          <div className={"v-contain"}>
            <div className="village-back-btn" onClick={this.backToCampaign}>
              <div className={"back-icon"}>
                <img src="/images/back-icon.svg" alt="" />
              </div>
              <div className="back-text">
                {"Campaign " + this.props.campaign.name}
              </div>
            </div>
            <div className="v-details">
              <div className="vd-l-side">
                <input
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  onClick={this.handleEditVillageMode}
                  onKeyDown={this.handleSubmitEditName}
                  onBlur={this.changeVillageName}
                  onChange={this.handleEditVillageName}
                  value={this.state.villageName}
                  className="v-name"
                />
                <div className="v-status">
                  {this.getStatusActivityType(village.status)}
                </div>
              </div>
              <div className="vd-r-side">
                <div className="vd-send-mail">
                  {this.props.scanFiles[village.id] && (
                    <img
                      src={
                        !this.props.scanFiles[village.id].downloaded
                          ? "/images/download-scan.svg"
                          : "/images/downloaded-scan.svg"
                      }
                      onClick={this.downloadScanFile}
                      className={`${
                        !this.props.scanFiles[village.id].downloaded
                          ? "download-scan"
                          : "downloaded-scan"
                      }`}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="more-info">
              {`${village.status}, visited at ${moment(
                village.timeUpdated
              ).format("DD.MM.YYYY")}`}
            </div>
            {currentChunksOfVillageData ? (
              <div className="area-data-container">
                <div className="area-data-l">
                  <div className="area-km area-item">
                    <img src="/images/distance-icon.svg" alt="" />
                    <span>
                      {getFixedPercent(village.summary.totalChunks / 100) +
                        "km"}
                      <sup>2</sup>
                    </span>
                  </div>
                  <div
                    className={
                      "vertical-dotted-line vertical-dotted-line-" +
                      this.props.village.status
                    }
                  />
                  <div className="area-scanned area-item">
                    <div
                      className={
                        "area-scanned-icon area-scanned-icon-" + village.status
                      }
                    />
                    <span>
                      {getFixedPercent(village.summary.totalScanned * 100, 0) +
                        "% scanned"}
                    </span>
                  </div>
                </div>
                <div className="area-data-r">
                  <div className="area-chunks area-item">
                    <div className="area-chunks-shape" />
                    <span>
                      {currentChunksOfVillageData.chunksLength + " chunks"}
                    </span>
                  </div>
                  <div className="vertical-dotted-line vertical-dotted-line-1" />
                  <div className="area-core-belt area-item">
                    <div className="area-core-belt-shape" />
                    <span>
                      {currentChunksOfVillageData.chunksCore +
                        " core" +
                        ", " +
                        currentChunksOfVillageData.chunksBelt +
                        " belt"}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="village-summary-data">
              {this.summaryLine(
                "Water bodies",
                village.summary.totalSources,
                "#32c5ff"
              )}
              {this.summaryLine(
                "Sprayed",
                (Math.round(
                  (village.summary.totalSprayed /
                    village.summary.totalSources) *
                    100
                ) || 0) + "%",
                "#41e992"
              )}
              {this.summaryLine(
                "Last Sprayed",
                getFormatDate(getLastSpray(village)),
                "#41e992"
              )}
              {this.summaryLine(
                "Issues",
                village.summary.totalIssued || 0,
                "#ef3b3b"
              )}
              <div style={{ borderColor: "#86dde9" }} className="summary-line">
                <span>{`${village.summary.totalSampled || 0} Sampled`}</span>
                {this.props.sampleGraph && (
                  <span
                    onClick={this.handleShowSampleGraphMode}
                    className="open-village-sample-btn"
                  >
                    (view)
                  </span>
                )}
              </div>
              {this.summaryLine(
                "Avg Anopheles larvae",
                getFixedPercent(village.summary.totalAvgAnopheles),
                "#86dde9"
              )}
              {this.summaryLine(
                "Positive Samples",
                village.summary.totalPositiveAnopheles || 0,
                "#86dde9"
              )}
              <div style={{ borderColor: "#ba86e9" }} className="summary-line">
                <span>{`${village.summary.totalTraps || 0} Traps`}</span>
                {this.props.trapGraph && (
                  <span
                    onClick={this.handleShowTrapGraphMode}
                    className="open-village-trap-btn"
                  >
                    (view)
                  </span>
                )}
              </div>
            </div>
          </div>
          {this.state.sampleGraphMode && (
            <GroupGraph
              dateFieldName={"createdOn"}
              fields={["anopheles", "culex", "pupae"]}
              fetch={() => fetchVillageSampleResults(village.id)}
              onClose={this.handleShowSampleGraphMode}
            />
          )}
        </div>
      );
    return null;
  }
  downloadScanFile = () => {
    this.props.fetchVillageScan(this.props.village.id);
  };

  summaryLine = (name: string, value: number | string, color: string) => {
    return (
      <div
        style={{ borderColor: color }}
        className="summary-line"
      >{`${value} ${name}`}</div>
    );
  };

  handleEditVillageName = (event: any) => {
    this.setState({ villageName: event.target.value });
  };

  handleEditVillageMode = () => {
    this.setState((prevState: IVillageSideBarState) => ({
      editVillageNameMode: !prevState.editVillageNameMode,
    }));
  };

  handleSubmitEditName = (event: any) => {
    if (event.key === "Enter") this.changeVillageName();
  };

  changeVillageName = () => {
    const { campaign, village } = this.props;
    const { villageName } = this.state;
    if (villageName !== village.name) {
      this.props.serverLoading(
        () =>
          this.props.editVillage(
            { name: villageName },
            campaign.id!,
            village.id
          ),
        {
          onSuccess: () =>
            this.props.changeVillageName(this.props.village.id, villageName),
        }
      );
    }
    this.nameInput.blur();
  };

  getStatusActivityType = (status: VillageStatus) => {
    switch (status) {
      case VillageStatus.Defined:
        return "inactive";
      case VillageStatus.InProcess:
        return "active";
      case VillageStatus.Finished:
        return "active";
      default:
        return null;
    }
  };

  handleShowTrapGraphMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      trapGraphMode: !prevState.trapGraphMode,
    }));
  };
  handleShowSampleGraphMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      sampleGraphMode: !prevState.sampleGraphMode,
    }));
  };
}

const initState = (props: IVillageSideBarProps) => {
  return {
    villageId: props.village.id,
    villageName: props.village.name,
    editVillageNameMode: false,
    trapGraphMode: false,
    sampleGraphMode: false,
  };
};
