import {
  addObserverService,
  changeCampaignsService,
  deleteObserverService,
  getObserversService,
  promiseService,
  updateObserverService,
} from "../../services/ObserverService";
import { Observer, UpdateUser } from "../stores/users.interface";
import { Campaign } from "../stores/campaign.interface";
import { Dispatch } from "redux";

export const ADD_OBSERVER = "ADD_OBSERVER";
export const ADD_CAMPAIGNS_TO_OBSERVER = "ADD_CAMPAIGNS_TO_OBSERVER";
export const CHANGE_CAMPAIGNS = "CHANGE_CAMPAIGNS";
export const DELETE_OBSERVER = "DELETE_OBSERVER";
export const GET_ALL_USERS_OF_CAMPAIGN = "GET_ALL_USERS_OF_CAMPAIGN";
export const GET_OBSERVERS = "GET_OBSERVERS";
export const UPDATE_OBSERVER = "UPDATE_OBSERVER";

export const addObserver = (newObserverData: any, campaigns: Campaign[]) => {
  return async (dispatch: Dispatch) => {
    const { data } = await addObserverService(newObserverData);
    dispatch({
      type: ADD_OBSERVER,
      user: data.user,
    });
    dispatch({
      type: ADD_CAMPAIGNS_TO_OBSERVER,
      id: data.user.id,
      campaigns: campaigns,
    });
  };
};

export const addCampaignsToObserver = (id: string, campaigns: Campaign[]) => ({
  type: ADD_CAMPAIGNS_TO_OBSERVER,
  payload: promiseService({ id: id, campaigns: campaigns }),
});

export const changeCampaigns = (observerId: string, campaigns: Campaign[]) => ({
  type: CHANGE_CAMPAIGNS,
  payload: changeCampaignsService(observerId, campaigns),
});

export const deleteObserver = (id: string) => ({
  type: DELETE_OBSERVER,
  payload: deleteObserverService(id),
});

export const getObservers = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getObserversService();
    dispatch({
      type: GET_OBSERVERS,
      observers: data,
    });
  };
};

export const updateObserver = (
  id: string,
  newUserData: UpdateUser,
  campaigns: Campaign[]
) => {
  return async (dispatch: Dispatch) => {
    await updateObserverService(id, newUserData);
    dispatch({
      type: UPDATE_OBSERVER,
      user: newUserData,
      id: id,
    });
    dispatch({
      type: ADD_CAMPAIGNS_TO_OBSERVER,
      id: id,
      campaigns: campaigns,
    });
  };
};
