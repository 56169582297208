import * as React from "react";
import { FunctionComponent } from "react";
import Paper from "@material-ui/core/Paper";
import { StationDetailsBar } from "./StationDetailsBar";

const classes = {
  root: {
    width: "100%",
  },
  button: {
    marginTop: "40px",
    marginRight: "40px",
  },
  actionsContainer: {
    // marginBottom: "40px"
  },
  resetContainer: {
    padding: "5px 20px",
    borderTop: "1px solid black",
  },
};


interface IStationPointProps {
  deleteStation: () => void;
  submitStation: (description: string) => void;
}

export const StationPoint: FunctionComponent<IStationPointProps> = (props) => {
  const handleDeleteVillage = () => {
    props.deleteStation();
  };

  return (
    <div className="create-village-shape">
      <div style={classes.root}>
        <Paper square elevation={0} style={classes.resetContainer}>
          <StationDetailsBar
            submitStation={props.submitStation}
            deleteStation={handleDeleteVillage}
          />
        </Paper>
      </div>
    </div>
  );
};
