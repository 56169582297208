import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  },
});

export const createVillage = (campaignId: string, name: string, cords: any) => {
  return instance.post(`/api/v1/campaigns/${campaignId}/village`, {
    name,
    cords,
  });
};

export const editVillage = (
  body: any,
  campaignId: string,
  villageId: string
) => {
  return instance.patch(`/api/v1/villages/${villageId}`, body);
};

export const getVillages = async (campaignId: string) => {
  return await instance.get(`/api/v1/campaigns/${campaignId}?include=villages`);
};

export const getWaterSourceRange = async (
  campaignId: string,
  startDate: number,
  endDate: number
) => {
  return (await instance.get(`/api/v1/campaigns/${campaignId}/range`, {
    params: { startDate: startDate, endDate: endDate },
  })).data;
};

export const fetchVillageScan = (villageId: string, assignmentId?: string) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  return instance.get(`/api/v1/villages/${villageId}/scanned` + filter);
};
