import { Dispatch } from "redux";
import { normalize } from "normalizr";
import { taskSchema } from "../schemas/Task";
import { addTask, cancelTask, fetchTasks, setStatus } from "../../services/TaksService";
import { ITask } from "../stores/task.interface";
import { createHouse } from "../../services/HouseService";

export enum TaskActions {
  ADD_TASK = "ADD_TASK",
  FETCH_TASKS = "FETCH_TASKS",
  SET_STATUS_ASSIGNMENT = "SET_STATUS_ASSIGNMENT",
  CANCEL_TASK = "CANCEL_TASK",
  TASK_ERROR = "TASK_ERROR",
}

export const setAssignmentStatus = (serverDialogMessage: any, campaignId: string, assignmentId: any, comment: string, status: any) => {
  return async (dispatch: Dispatch) => {
    let data: any;
    try {
     data = await setStatus(campaignId, assignmentId, comment, status);
     data = data.data;
    } catch (e) {
      if (e instanceof Error) {
        serverDialogMessage(e.message);
      } else {
        alert(e);
      }
      return;
    }

    const assignment = data[0];
    assignment.history = assignment.assignmentHistories;


    dispatch({
      type: TaskActions.SET_STATUS_ASSIGNMENT,
      taskId: assignment.taskId,
      newTaskStatus: assignment.status,
      assignment: assignment,
    });
  };
};

export const createHouseAction = (campaignId: string, assignmentId: any) => {
  return async (dispatch: Dispatch) => {
    let data: any;
    try {
      data = await createHouse(campaignId, assignmentId);
      data = data.data;
    } catch (e) {
      alert(e);
      return;
    }
    // dispatch({
    //   type: TaskActions.SET_STATUS_ASSIGNMENT,
    //   id: data,
    //   taskId: taskId
    // });
  };
};

export const cancelTaskAction = (campaignId: string, taskId: any) => {
  return async (dispatch: Dispatch) => {
    let data: any;
    try {
      data = await cancelTask(campaignId, taskId).catch(e => {
        dispatch({
          type: TaskActions.TASK_ERROR,
          error: e.response.data.message
        });
      });
      data = data.data;
    } catch (e) {
      return;
    }

    setActiveAssignment(data);

    dispatch({
      type: TaskActions.CANCEL_TASK,
      taskId: taskId,
      task: data
    });
  };
};


export const addTaskAction = (campaignId: string, newTaskData: ITask) => {
  return async (dispatch: Dispatch) => {
    const { data } = await addTask(campaignId, newTaskData);

    setActiveAssignment(data);

    dispatch({
      type: TaskActions.ADD_TASK,
      task: data,
    });
  };
};

const setActiveAssignmentForTasks = (tasks: any) => {
  tasks.map((task: any) => {
    setActiveAssignment(task);
  });
}

export const setActiveAssignment = (task: any) => {
  if (task.assignments !== undefined) {
    for (let assignment of task.assignments) {
      if (!assignment.isCanceled) {
        task.activeAssignment = assignment;
        break;
      }
    }
  }
}

export const fetchTasksAction = (campaignId: string) => {
  return async (dispatch: Dispatch) => {
    const { data } = await fetchTasks(campaignId);
    setActiveAssignmentForTasks(data);

    const normalizedTasksData = normalize(data, [taskSchema]);

    dispatch({
      type: TaskActions.FETCH_TASKS,
      tasks: normalizedTasksData.entities.tasks || {},
    });
  };
};
