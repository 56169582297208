import { editVillage, createVillage } from "../../services/VillageService";
import { Dispatch } from "redux";
import { handleAddSemiExpired } from "./CampaignsActions";
import { createAppStore } from "../stores/AppStore";

//ACTION NAMES
export const FETCH_VILLAGES = "FETCH_VILLAGES";
export const ADD_VILLAGE = "ADD_VILLAGE";
export const EDIT_VILLAGE = "EDIT_VILLAGE";
export const GET_CHUNKS_V2 = "GET_CHUNKS_V2";
export const FILTER_VILLAGE_SUMMARY = "FILTER_VILLAGE_SUMMARY";
export const REMOVE_SUMMARY_FILTER = "REMOVE_SUMMARY_FILTER";

export const AddVillageAction = (
  campaignId: string,
  name: string,
  drawNewVillage: (name: string) => void,
  coordinates: any
) => {
  return async (dispatch: Dispatch) => {
    const settings = createAppStore.getState().campaigns.campaign.settings;
    const { data } = await createVillage(campaignId, name, coordinates);
    var village = handleAddSemiExpired([data], settings)[0];
    drawNewVillage(village);
    dispatch({
      type: ADD_VILLAGE,
      village,
    });
  };
};

export const editVillageAction = (
  body: any,
  campaignId: string,
  villageId: string
): any => {
  return async (dispatch: Dispatch) => {
    editVillage(body, campaignId, villageId);
    dispatch({
      type: EDIT_VILLAGE,
      villageId,
      body,
    });
  };
};

export const rangeVillageAction = (villagesSummary: any) => {
  return {
    type: FILTER_VILLAGE_SUMMARY,
    villagesSummary,
  };
};
export const removeRangeVillageAction = () => {
  return {
    type: REMOVE_SUMMARY_FILTER,
  };
};

const handleNotFoundVillages = (features: [], onNotFound: () => void) => {
  if (!features) {
    onNotFound();
  }
};
