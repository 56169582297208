import { WaterSource } from "../../../appRedux/stores/waterSources.interface";
import * as mgrs from "mgrs";
import { getControlsSetting } from "../../../services/GlobalService";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import mapboxgl from "mapbox-gl";

export const hideLayout = (id: string, map: any) => {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, "visibility", "none");
  }
};

export const showLayout = (id: string, map: any) => {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, "visibility", "visible");
  }
};
export const getEmptyGeoJson = () => {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };
};
export const getCenterOfVillages = (villages: any) => {
  let latSum = 0.0;
  let lngSam = 0.0;
  villages.forEach((village: any) => {
    lngSam = lngSam + village.center[0];
    latSum = latSum + village.center[1];
  });
  return [lngSam / villages.length, latSum / villages.length];
};

export const getChunkOfWs = (waterSources: any) => {
  if (waterSources) {
    // @ts-ignore
    const temp: { [fieldName: string]: string } = waterSources.reduce(
      (total: any, item: any) => {
        total[item.chunk] = total[item.chunk] + 1 || 1;
        return total;
      },
      {}
    );
    const arraychunkWs = [];
    for (let key in temp) {
      arraychunkWs.push({ center: mgrs.toPoint(key), description: temp[key] });
    }
    return arraychunkWs;
  }
};

export const getZoom = (map: any) => {
  if (map) return Math.floor(map.getZoom());
  return initZoom;
};

export enum MapStyles {
  satellite = "satellite",
  terrain = "terrain",
}

export enum MapStylesUrl {
  terrain = "mapbox://styles/mapbox/streets-v11",
  satellite = "mapbox://styles/200apps/cl1nrd5f9001014pbvbi2kweg",
}

export const whatIsInitStyle = () => {
  return MapStyles.satellite;
};
export const whatIsNotInitStyle = () => {
  return MapStyles.terrain;
};

export const whatIsStyleUrl = (style: MapStyles | any) => {
  switch (style) {
    case MapStyles.satellite:
      return MapStylesUrl.satellite;

    case MapStyles.terrain:
      return MapStylesUrl.terrain;

    default:
      return MapStylesUrl.satellite;
  }
};

export const initZoom = 11;

export const mapOptions = {
  container: "map-layout",
  style: process.env.REACT_APP_MAPBOX_STYLE_LINK, //hosted style id
  zoom: initZoom, // starting zoom
  trackResize: true,
  dragRotate: false,
  touchZoomRotate: false,
};

export const drawOptions = {
  userProperties: true,
  styles: getControlsSetting(),
  defaultMode: "draw_polygon",
  displayControlsDefault: false,
  modes: MapboxDraw.modes,
};

export const geocoderOptions = {
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  types:
    "address,country,region,place,district,postcode,locality,neighborhood,poi,poi.landmark",
  reverseGeocode: true,
  proximity: false,
  zoom: 16,
  marker: true,
  localGeocoder: coordinatesGeocoder,
  mapboxgl: mapboxgl,
  language: "en",
  placeholder: "Search or long, lat",
};

function coordinatesGeocoder(query: any) {
  // match anything which looks like a decimal degrees coordinate pair
  var matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }

  function coordinateFeature(lng: any, lat: any) {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: `Lng: ${lng}, Lat: ${lat}`,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
    };
  }

  var coord1 = Number(matches[1]);
  var coord2 = Number(matches[2]);
  var geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
}

let bla = 0;
export function addCursorIconToLayer(map: any, layerId: string) {
  map.on("mouseenter", layerId, () => {
    bla++;
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", layerId, () => {
    bla--;
    if (bla === 0) map.getCanvas().style.cursor = "";
  });
}
