import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ArrowDropUp } from "@material-ui/icons";
import v1 from "uuid/v1";
import { User } from "../../../../appRedux/stores/users.interface";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Checkbox from "@material-ui/core/Checkbox";
import { Enums } from "../../../../Enums";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// import UnCheckedIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
// import CheckedIcon from 'material-ui/svg-icons/toggle/check-box'

// import { ReactComponent as CheckedIcon } from "./../../assets/CheckBoxIcon.svg";
// import { ReactComponent as UnCheckedIcon } from "./../../assets/UnCheckBoxIcon.svg";
import { getCurrentUserProfile } from "../../../../services/GlobalService";

let counter = 0;
const styles: any = {
  root: {
    backgroundColor: "rgba(246, 246, 247, 1)",
    height: "100%",
    fontWeight: "bold",
    color: "rgba(45, 50, 71, 1)",
    textAlign: "left",
    letterSpacing: "0.02em",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1062px",
    fontSize: "18px",
  },
  titleCell: {
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgba(45, 50, 71, 1)",
    letterSpacing: "0.03em",
    flexDirection: "row",
    borderBottom: "none",
    padding: "0px 0px 0px 1rem !important",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "2rem",
  },
  tableBody: {
    width: "100%",
    padding: "0px 1rem",
  },
  tableBodyContent: {
    overflowY: "auto",
    // maxHeight: "100.8rem",
  },
  tableWrapper: {
    height: "100%",
    paddingTop: "10px",
  },
  head: {
    width: "100% !important",
  },
  input: {
    marginTop: 12,
    height: 25,
  },
  tableHeaderRow: {
    display: "flex",
    marginBottom: "0.6rem",
    height: "unset",
    paddingRight: "1rem",
  },
  tableRow: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0,0.09)",
    width: "100%",
    display: "flex",
    marginTop: "0.5rem",
  },
  editIcons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid rgb(224, 224, 224)",
    justifyContent: "flex-end",
  },
  tableTitle: {
    padding: "15px 0px 0px 15px",
    position: "relative",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentTds: {
    padding: "0px 0px 0px 1rem !important",
    width: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
  actionsBar: {
    width: "4.2rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "1rem",
  },
  actionsBarImage: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
  },
  filterIcon: {
    left: "8px",
    cursor: "pointer",
  },
  /*checkedIconSvgStyle: {
        fill: "rgb(126, 211, 33)",
        borderRadius: "2px",
        outline: "rgb(45, 50, 71)"
    },
    unCheckedIconSvgStyle: {
        fill: "rgb(45, 50, 71)"
    },*/
  checkBoxLabelStyle: {
    fontSize: "16px",
    color: "rgb(45, 50, 71)",
    textAlign: "left",
    letterSpacing: "0.03em",
  },
};

const useStyles = makeStyles(() => styles);
let classes: any;

function desc(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: any, cmp: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getSorting(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
}

const theme = createMuiTheme({
  typography: {},
});

function TableHeadCells(props: any) {
  const { order, orderBy, onRequestSort, cells } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <TableRow className={classes.tableHeaderRow}>
        {cells.map((cell: any, index: number) => (
          <TableCell
            key={cell.id}
            style={{ width: "100%" }}
            padding={cell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === cell.id ? order : false}
            className={
              classes.titleCell + " " + (index % 2 === 0 ? "col-2" : "col-3")
            }
          >
            <Tooltip
              title="Sort"
              placement={cell.numeric ? "bottom-end" : "bottom-start"}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={order}
                onClick={createSortHandler(cell.id)}
                IconComponent={ArrowDropUp}
              >
                {cell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </MuiThemeProvider>
  );
}

TableHeadCells.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const FilterField = (props: any) => {
  const handleFilterTextChange = (e: any) => {
    props.onFilterTextChange(e.target.value);
  };

  return (
    <form noValidate autoComplete="off">
      <TextField
        id="outlined-search"
        placeholder="Name"
        value={props.filterText}
        onChange={handleFilterTextChange}
        type="search"
        variant="outlined"
        fullWidth
        InputProps={{
          className: classes.input,
        }}
        onKeyDown={(event: any) => {
          if (event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        }}
      />
    </form>
  );
};

const ApplyFilterButton = (props: any) => {
  return (
    <div
      className="zz-btn add-employee-btn"
      onClick={props.onApplyFiltersButtonChange}
    >
      <FormattedMessage id="app.apply" />
    </div>
  );
};

class EmployeesTable extends Component<any, any> {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    employees: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnsNames: PropTypes.arrayOf(PropTypes.object).isRequired,
    formatMessage: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    usersType: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
  };

  static childContextTypes = {
    muiTheme: PropTypes.object,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }

  data: User[];
  columnsNames: any[];

  constructor(props: any) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "fullName",
      selected: [],
      setSelected: [],
      // isBackOffice: this.props.columnsNames.length === 3,
      showFilterBubble: false,
      applyFilter: false,
      boxesChecked: [true, true, true, true],
      currentUserProfile: {
        role: getCurrentUserProfile().role,
      },
      filterText: "",
      isIrs: false,
      isLarviciding: false,
      isEntomologist: false,
    };
    this.handleApplyFiltersButtonChange = this.handleApplyFiltersButtonChange.bind(
      this
    );
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.data = props.employees;
    this.columnsNames = props.columnsNames;
    classes = props.classes;
  }

  private getUserTypeText(usersType: number) {
    const userTypeMap = new Map([
      [1, "admin"],
      [2, "backOffice"],
      [3, "observer"],
      [4, "fieldWorkers"],
      [5, "imageAnalyst"],
    ]);
    return userTypeMap.get(usersType);
  }

  private handleRequestSort(event: any, property: any) {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  }

  private handleSelectAllClick(event: any) {
    if (event.target.checked) {
      const newSelecteds = this.data.map((n: any) => n.id);
      this.setState({ setSelected: newSelecteds });
      return;
    }
    this.setState({ setSelected: [] });
  }

  private handleClick(event: any, id: any) {
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ setSelected: newSelected });
  }

  private getTableCells(cell: User) {
    const formatMessage = this.props.formatMessage;
    const table_cells: any = [];
    Object.keys(cell).forEach((propName: any, index: number) => {
      if (propName !== "id") {
        let contentOfCell: any = "";
        if (propName == "ceritification") {
          if (cell.ceritification) {
            if (cell.ceritification.irs) {
              contentOfCell = formatMessage({ id: "app.irs" });
            }
            if (cell.ceritification.larviciding) {
              contentOfCell +=
                (cell.ceritification.irs ? ", " : "") +
                formatMessage({ id: "app.larviciding" });
            }
            if (cell.ceritification.enthomology) {
              contentOfCell +=
                (cell.ceritification.irs || cell.ceritification.larviciding
                  ? ", "
                  : "") + formatMessage({ id: "app.enthomology" });
            }
          }
        } else {
          contentOfCell = cell[propName];
        }
        table_cells.push(
          <TableCell
            className={
              classes.contentTds +
              " " +
              ((index - 1) % 2 === 0 ? "col-2" : "col-3")
            }
            key={table_cells.id + "-" + v1()}
          >
            {contentOfCell}
          </TableCell>
        );
      }
    });
    return table_cells;
  }

  handleFilterTextChange(filterText: any) {
    this.setState({
      filterText: filterText,
    });
  }

  handleApplyFiltersButtonChange() {
    this.setState((prevState: any) => ({
      ...prevState,
      applyFilter: true,
      showFilterBubble: false,
    })),
      () => {
        // this.props.onStationNameFilterTextChange(value);
      };
  }

  handleCheckboxFilterChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: checked,
    })),
      () => {
        // this.props.onStationNameFilterTextChange(value);
      };
  };

  /*
  handleCheckBoxChange = (index: 0) => (event: any) => {
    const temp = [...this.state.boxesChecked];
    temp[index] = event.target.checked;
    this.setState({ boxesChecked: temp });
  };
  */

  componentWillReceiveProps(nextProps: any) {
    this.data = nextProps.employees;
  }

  render() {
    const isSelected = (id: any) => this.state.selected.indexOf(id) !== -1;
    const { filterText, isIrs, isLarviciding, isEntomologist } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableTitle}>
          <span>
            {this.props.formatMessage({
              id:
                "app.addEmployeePage." +
                this.getUserTypeText(this.props.usersType),
            })}
          </span>
          {this.props.usersType !== Enums.USER_TYPES.fieldWorker ? null : (
            <img
              src="/images/filter-icon.svg"
              alt=""
              className={classes.filterIcon}
              onClick={(event: any) =>
                this.setState({
                  showFilterBubble: !this.state.showFilterBubble,
                })
              }
            />
          )}
          {this.state.showFilterBubble ? (
            <div className="filter-bubble-wrapper">
              <div className="filter-bubble-grid">
                <div className="filter-label-grid-area">Filter:</div>
                <div className="filter-field-grid-area">
                  <FilterField
                    filterText={this.state.filterText}
                    onFilterTextChange={this.handleFilterTextChange}
                  />
                </div>
                {/*
                <FormControl className={classes.formControl}>
                  <FormGroup>
              */}
                <div className="irs-certification-checkbox-grid-area">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isIrs}
                        color="primary"
                        value="isIrs"
                        inputProps={{
                          "aria-label": "irs certification filter checkbox",
                        }}
                        onChange={this.handleCheckboxFilterChange("isIrs")}
                      />
                    }
                    label="IRS_certification"
                  />
                </div>
                <div className="larviciding-certification-checkbox-grid-area">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isLarviciding}
                        color="primary"
                        value="isLarviciding"
                        inputProps={{
                          "aria-label":
                            "larviciding certification filter checkbox",
                        }}
                        onChange={this.handleCheckboxFilterChange(
                          "isLarviciding"
                        )}
                      />
                    }
                    label="Larviciding_certification"
                  />
                </div>
                <div className="enthomology-certification-checkbox-grid-area">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isEntomologist}
                        color="primary"
                        value="isEntomologist"
                        inputProps={{
                          "aria-label":
                            "enthomology certification filter checkbox",
                        }}
                        onChange={this.handleCheckboxFilterChange(
                          "isEntomologist"
                        )}
                      />
                    }
                    label="Enthomology_certification"
                  />
                </div>
                {/*
                  </FormGroup>
                </FormControl>
                      */}
                <div className="apply-button-grid-area">
                  <ApplyFilterButton
                    onApplyFiltersButtonChange={
                      this.handleApplyFiltersButtonChange
                    }
                  />
                </div>

                {/*
              <Checkbox
                uncheckedIcon={<UnCheckedIcon />}
                checkedIcon={<CheckedIcon />}
                checked={this.state.boxesChecked[0]}
                onCheck={this.handleCheckBoxChange(0)}
                value={this.state.boxesChecked[0]}
                labelStyle={styles.checkBoxLabelStyle}
                label="Checkbox Label"
              />
            */}
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableBody className={classes.tableBody}>
              <TableHeadCells
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick.bind(this)}
                onRequestSort={this.handleRequestSort.bind(this)}
                rowCount={3}
                cells={this.columnsNames}
              />
            </TableBody>
            <TableBody
              className={`${classes.tableBody} ${classes.tableBodyContent}`}
            >
              {stableSort(
                this.data,
                getSorting(this.state.order, this.state.orderBy)
              ).map((n: any, i: number) => {
                const isItemSelected = isSelected(n.id);

                if (this.state.applyFilter) {
                  if (
                    n.fullName
                      .toUpperCase()
                      .indexOf(filterText.toUpperCase()) === -1
                  ) {
                    return;
                  }
                  if (isIrs && n.ceritification.irs !== isIrs) {
                    return;
                  }
                  if (
                    isLarviciding &&
                    n.ceritification.larviciding !== isLarviciding
                  ) {
                    return;
                  }
                  if (
                    isEntomologist &&
                    n.ceritification.enthomology !== isEntomologist
                  ) {
                    return;
                  }
                }

                return (
                  <TableRow
                    hover
                    onClick={(event) => this.handleClick(event, n.id)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isItemSelected}
                    className={` table-row ${classes.tableRow} ${
                      this.data.length - 1 === i ? "mb5" : ""
                    }`}
                  >
                    {this.getTableCells(n)}
                    <td className={classes.editIcons}>
                      {this.state.currentUserProfile.role !==
                      Enums.USER_TYPES.observer ? (
                        <div className={classes.actionsBar}>
                          <img
                            className={classes.actionsBarImage}
                            onClick={(event) => this.props.handleEdit(n.id)}
                            src="/images/edit-icon.svg"
                            alt=""
                          />
                        </div>
                      ) : null}
                    </td>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(EmployeesTable);
