import * as React from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import { WorkTypeTask } from "../../../Enums";
import { DatePicker } from "../../templates/basic_componnets/DatePicker";
import { ITask } from "../../../appRedux/stores/task.interface";
interface ITaskProps {
  serverDialog: any;
  addTask: any;
  villages: any;
  campaignId: any;
}
export function getFullFormatDate(dateStr: string | number) {
  if (dateStr) return moment(dateStr).format("DD/MM/YYYY");
  return "----";
}

export function getDateAndTime(dateStr: string | number) {
  if (dateStr) return moment(dateStr).format("DD/MM/YY HH:mm");
  return "----";
}

export function getSubstractedYearFormatDate(dateStr: string | number) {
  if (dateStr && dateStr !== '----') {
    const momentDate = moment(dateStr, "DD/MM/YYYY");
    const currentYear = moment().year();
    if (momentDate.year() != currentYear) {
      return momentDate.format("DD/MM/YY");
    } else {
      return momentDate.format("DD/MM");
    }
  }
  return "----";
}

export function getSubctractedYearDateAndTime(dateStr: string | number) {
  if (dateStr && dateStr !== '----') {
    const momentDate = moment(dateStr, "DD/MM/YY HH:mm");
    const currentYear = moment().year();
    if (momentDate.year() != currentYear) {
      return momentDate.format("DD/MM/YY HH:mm");
    } else {
      return momentDate.format("DD/MM HH:mm");
    }
  }
  return "----";
}

export const TaskDialog = React.memo((props: ITaskProps) => {
  const [open, setOpen] = React.useState(false);
  const [openDatePickerStartOn, setOpenDatePickerStartOn] = React.useState(
    false
  );
  const [openDatePickerDueOn, setOpenDatePickerDueOn] = React.useState(false);
  const [village, setVillage] = React.useState("");
  const [workType, setWorkType] = React.useState("");
  const [startOn, setStartOn] = React.useState("");
  const [dueOn, setDueOn] = React.useState("");
  const [isExpiry, setIsExpiary] = React.useState(false);
  const [estimatedTime, setEstimatedTime] = React.useState(0);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkFormValid = () => {
    return !(
      village !== "" &&
      workType !== "" &&
      startOn !== "----" &&
      dueOn !== "----" &&
      estimatedTime > 0
    );
  };
  const hendleCreateTask = () => {
    const task: ITask = {
      villageId: village,
      workType,
      startOn: getFullFormatDate(startOn),
      dueOn: getFullFormatDate(dueOn),
      isExpiry,
      estimatedTime,
    };
    props.serverDialog(() => props.addTask(props.campaignId, task));
    handleClose();
  };

  return (
    <div>
      <div className="task-title-strip">
        <div className="add-task-container">
          <Button className="add-task-btn" onClick={handleClickOpen}>
            Add task
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Task</DialogTitle>
        <DialogContent>
          <InputLabel>Village name</InputLabel>
          <Select
            autoFocus
            label="Village name"
            fullWidth
            value={village}
            onChange={(e: any) =>
              e.target.value ? setVillage(e.target.value) : null
            }
          >
            {props.villages.map((village: any, i: number) => (
              <MenuItem key={i} value={village.id}>
                {village.name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel>Work Type</InputLabel>
          <Select
            autoFocus
            label="work type"
            fullWidth
            value={workType}
            onChange={(e: any) =>
              e.target.value ? setWorkType(e.target.value) : null
            }
          >
            {Object.keys(WorkTypeTask)
              .filter((k) => isNaN(Number(k)))
              .map((k: any) => (
                <MenuItem key={WorkTypeTask[k]} value={k}>
                  {k}
                </MenuItem>
              ))}
          </Select>
          <TextField
            autoFocus
            label="start on"
            fullWidth
            variant="standard"
            value={getFullFormatDate(startOn)}
            onClick={() => setOpenDatePickerStartOn(true)}
            disabled
          />
          <TextField
            autoFocus
            label="due on"
            fullWidth
            variant="standard"
            value={getFullFormatDate(dueOn)}
            onClick={() => setOpenDatePickerDueOn(true)}
            disabled
          />
          <FormControlLabel
            label="is Expiry"
            labelPlacement="top"
            control={
              <Checkbox
                checked={isExpiry}
                value={isExpiry}
                onChange={() => setIsExpiary(!isExpiry)}
              />
            }
          />
          <TextField
            autoFocus
            label="estimated time"
            fullWidth
            type="number"
            variant="standard"
            value={estimatedTime}
            onChange={(e: any) => setEstimatedTime(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={hendleCreateTask} disabled={checkFormValid()}>
            Create
          </Button>
        </DialogActions>
        {openDatePickerStartOn && (
          <DatePicker
            onSelect={(date: any) => setStartOn(date)}
            date={startOn}
            onClose={() => setOpenDatePickerStartOn(false)}
          />
        )}

        {openDatePickerDueOn && (
          <DatePicker
            onSelect={(date: any) => setDueOn(date)}
            date={dueOn}
            onClose={() => setOpenDatePickerDueOn(false)}
          />
        )}
      </Dialog>
    </div>
  );
});
