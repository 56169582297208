import {
  ADD_CAMPAIGNS_TO_OBSERVER,
  ADD_OBSERVER,
  CHANGE_CAMPAIGNS,
  DELETE_OBSERVER,
  GET_ALL_USERS_OF_CAMPAIGN,
  GET_OBSERVERS,
  UPDATE_OBSERVER,
} from "../actions/ObserverActions";
import { User, Observer } from "../../appRedux/stores/users.interface";
import { Campaign } from "../../appRedux/stores/campaign.interface";
import { Enums } from "../../Enums";

export const initialObserversState: any = [];

const FULFILLED = "_FULFILLED";

const ObserverReducer = (state: any = initialObserversState, action: any) => {
  switch (action.type) {
    case ADD_CAMPAIGNS_TO_OBSERVER:
      // find observer in observers and add campaigns
      return state.map((observer: any) => {
        if (observer.id !== action.id) {
          return observer;
        }
        return {
          ...observer,
          campaigns: action.campaigns,
        };
      });
    case ADD_OBSERVER:
      return [...state, action.user];
    case CHANGE_CAMPAIGNS + FULFILLED:
      if (action.payload.status !== 204) return state;
      return state.map((observer: any) => {
        if (observer.id !== action.payload.id) {
          return observer;
        }
        return {
          ...observer,
          campaigns: action.payload.campaigns,
        };
      });
    case DELETE_OBSERVER + FULFILLED:
      const id: string = action.payload;
      return state.filter((user: User) => {
        return user.id !== id;
      });
    case GET_ALL_USERS_OF_CAMPAIGN + FULFILLED:
      const { campaign, users } = action.payload;
      return state.map((observer: any) => {
        const found = users.find(({ id }: User) => id === observer.id);
        if (!found) {
          return observer;
        }

        if (!observer.campaigns) {
          // first campaign for observer so just add to array
          return { ...observer, campaigns: [campaign] };
        }

        // if campaign already on observer, return; otherwise add
        const campaignAlreadyAdded = observer.campaigns.some(
          (observersCampaign: Campaign) => observersCampaign.id === campaign.id
        );

        if (campaignAlreadyAdded) {
          return observer;
        }

        return {
          ...observer,
          campaigns: [...observer.campaigns, campaign],
        };
      });
    case GET_OBSERVERS:
      console.log(
        action.observers.filter(
          (user: User) => user.role === Enums.USER_TYPES.observer
        )
      );
      return action.observers.filter(
        (user: User) => user.role === Enums.USER_TYPES.observer
      );
    case UPDATE_OBSERVER:
      return state.map((observer: Observer) => {
        if (observer.id !== action.id) {
          // This isn't the item we care about - keep it as-is
          return observer;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...observer,
          email: action.user.email,
          fullName: action.user.fullName,
          organization: action.user.organization,
          phoneNumber: action.user.phoneNumber,
        };
      });
    default:
      return state;
  }
};

export default ObserverReducer;
