import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const fetchSampleResults = (ws: string) => {
  return instance.get(`/api/v1/sources/samples/graph/${ws}`);
};

export const fetchVillageSampleResults = (village: string) => {
  return instance.get(`/api/v1/sources/samples/graph/village/${village}`);
};
export const fetchCampaignSampleResults = (campaign: string) => {
  return instance.get(`/api/v1/sources/samples/graph/campaign/${campaign}`);
};
