import * as React from "react";
import { FunctionComponent, useState } from "react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { Campaign } from "../../../../../appRedux/stores/campaign.interface";

interface IStationSideBarProps {
  formatMessage: any;
  backToCampaignSideBar: () => void;
  campaign: Campaign;
  currentStation: any;
  users: any;
}

export const StationSideBar: FunctionComponent<IStationSideBarProps> = (props) => {

  const backToCampaign = () => {
    props.backToCampaignSideBar();
  };
  if (props.currentStation)
    return (
      <div className="r-container house-info">
        <div className={"house-contain"}>
          <div className="village-back-btn" onClick={backToCampaign}>
              <div className={"back-icon"}>
                <img src="/images/back-icon.svg" alt="" />
              </div>
              <div className="back-text">
                {"Campaign " + props.campaign.name}
              </div>
            </div>
          <div className="house-details">
            <div className="house-name">
              {"Station number: " + props.currentStation.id}
            </div>
            <div className="house-long-lat">
              <div className="house-long-lat-icon">
                <img
                  src="/images/coordinates.svg"
                  className="coordinates-icon"
                  alt="coordinates icon"
                />
              </div>
              {` ${props.currentStation.location.longitude.toFixed(
                4
              )}, ${props.currentStation.location.latitude.toFixed(4)}`}
            </div>
            <div className="house-sidebar-property">
              Description: 
              <span>
              {props.currentStation.description}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
};
