import { createStation, getStations } from "../../services/StationService";
import { Dispatch } from "redux";
import { Station } from "../stores/station.interface";
import { normalize } from "normalizr";
import { stationSchema } from "../schemas/Station";

export const ADD_STATION = "ADD_STATION";


export const addStation = (
  campaignId: string,
  lat: number,
  lng: number,
  description: string,
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await createStation(campaignId, lat,lng, description);
    const normalized = normalize(data, [stationSchema])
    dispatch({
      type: ADD_STATION,
      payload: (normalized.entities.stations||{})[normalized.result]
    });
  }
}


