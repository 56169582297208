import * as React from "react";

import { WorkTypeTask } from "../../../../../Enums";
import { User } from "../../../../../appRedux/stores/users.interface";
import { IdialogOptions } from "../../../../../appRedux/actions/ManageApiDialogAction";
import { Campaign } from "../../../../../appRedux/stores/campaign.interface";
import { HOUSE_STATUS } from "../../../../../appRedux/stores/houses.interface";
import { IssueType } from "../../../../../appRedux/stores/waterSources.interface";
import {
  Collapse,
  createStyles,
  Divider,
  IconProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  Block,
  BrokenImage,
  BugReport,
  ExpandLess,
  ExpandMore,
  Help,
  Landscape,
  RemoveCircle,
} from "@material-ui/icons";

import { ReactComponent as HouseIcon } from "../../../../../assets/houses/house.svg";
import { ReactComponent as SurveyedHouseIcon } from "../../../../../assets/houses/surveyed.svg";
import { ReactComponent as NotAtHomeHouseIcon } from "../../../../../assets/houses/not_at_home.svg";
import { ReactComponent as AccessDeniedHouseIcon } from "../../../../../assets/houses/access_denied.svg";

import { ReactComponent as NewWaterIcon } from "../../../../outsiders/tasks/ic_water_source_new.svg";
import { ReactComponent as SampledWaterIcon } from "../../../../outsiders/tasks/ic_water_source_sampled.svg";
import { ReactComponent as SprayedWaterIcon } from "../../../../outsiders/tasks/ic_water_source_sprayed.svg";

import { ReactComponent as HousesIcon } from "../../../../outsiders/tasks/ic_houses.svg";

const HOUSE_ICON: {
  [key in HOUSE_STATUS]: React.FunctionComponent;
} = {
  [HOUSE_STATUS.surveyed]: SurveyedHouseIcon,
  [HOUSE_STATUS.not_at_home]: NotAtHomeHouseIcon,
  [HOUSE_STATUS.access_denied]: AccessDeniedHouseIcon,
};

const ISSUE_ICON: {
  [key in IssueType[keyof IssueType] & string]: React.FunctionComponent;
} = {
  [IssueType.NotFound]: BrokenImage,
  [IssueType.NoWater]: Landscape,
  [IssueType.NoAccess]: RemoveCircle,
  [IssueType.NotPermitted]: Block,
  [IssueType.RefusedAccess]: Block,
  [IssueType.Other]: Help,
};

interface IVillageReviewSideBarProps {
  users: any;
  village: any;
  currentUserProfileRole: any;
  currentUser: User;
  campaign: Campaign;
  serverLoading: (callBack: () => any, options?: IdialogOptions) => void;
  backToCampaignSideBar: () => void;
  assignmentId?: string;
  assignmentTitle?: string;
  taskType: WorkTypeTask;
  taskSummary: any;
  history: any;
}

type StatType = {
  icon?: IconProps | SvgIconProps;
  icon2?: IconProps | SvgIconProps;
  a?: string;
  b?: string | JSX.Element;
  texts?: Array<{ a: string, b: string }>;
  expand?: Array<Array<StatType>>;
  tabbed?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
    icon: {
      marginRight: -20,
    }
  })
);

export function VillageReviewSideBar(props: IVillageReviewSideBarProps) {
  return (
    <>
      <div className="r-container">
        <div className={"v-contain"}>{villageBackBtn(props)}</div>
      </div>
      <div style={{ marginLeft: -25 }}>
        <Stats sections={stats(props.taskType, props.taskSummary)} />
      </div>
    </>
  );
}

const villageBackBtn = (props: IVillageReviewSideBarProps) => {
  return (
    <div
      className="village-back-btn"
      onClick={() => { history.back() }}
    >
      <div className={"back-icon"}>
        <img src="/images/back-icon.svg" alt="" />
      </div>
      <div className="back-text">{props.campaign.name + " Tasks"}</div>
    </div>
  );
};

function waterBodies(summary: any): StatType {
  return {
    icon: (
      <SvgIcon
        component={NewWaterIcon}
        htmlColor="#69e4ff"
        viewBox="0 0 25 36"
      />
    ),
    a: "Water bodies",
    b: getSubtitle(summary.waterSourceCountBefore, summary.newWaterSourceCount)
  }
}


function stats(taskType: WorkTypeTask, summary: any): Array<Array<StatType>> {
  switch (taskType) {
    case WorkTypeTask.survey:
      return surveyStats(summary);
    case WorkTypeTask.spray:
      return sprayStats(summary, true);
    case WorkTypeTask.sample:
      return sampleStats(summary, true);
    default:
      throw Error("unexpected");
  }
}

const Stats = ({
  sections,
  nested,
}: {
  sections: Array<Array<StatType>>;
  nested?: boolean;
}) => {
  return (
    <List>
      {sections.map((section, i) => (
        <>
          {section.map((item, j) => (
            <Stat key={j} stat={item} nested={nested} />
          ))}
          {i < section.length - 1 && <Divider key={i} />}
        </>
      ))}
    </List>
  );
};

const Stat = ({ stat, nested }: { stat: StatType; nested?: boolean }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  const expandable = stat.expand && stat.expand.length > 0;
  return (
    <>
      <ListItem
        button
        className={nested ? classes.nested : ""}
        style={stat.tabbed ? {paddingLeft: 50} : undefined}
        onClick={expandable ? () => setOpen(!open) : undefined}
      >
        {stat.icon && <ListItemIcon className={classes.icon}>{stat.icon}</ListItemIcon>}
        {stat.icon2 && <ListItemIcon>{stat.icon2}</ListItemIcon>}
        {stat.a &&
          <ListItemText primary={stat.a} secondary={stat.b} />}
        {stat.texts && stat.texts.map(({ a, b }) => <ListItemText primary={a} secondary={b} />)}
        {expandable && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {expandable && (
        <Collapse in={open}>
          <Stats sections={stat.expand as Array<Array<StatType>>} nested={true} />
        </Collapse>
      )}
    </>
  );
};

function surveyStats(summary: any): StatType[][] {
  return [
    [
      {
        texts: [
          {
            a: "Total",
            b: `${summary.villageArea}ha`,

          },
          {
            a: "Required",
            b: `${summary.requiredArea}ha`,

          },
          {
            a: "Surveyed",
            b: `${summary.surveyedArea}ha (${summary.surveyedPercentage.toFixed(
              1
            )}%)`,

          },
          {
            a: "Abandoned",
            b: `${summary.notSurveyedAreaPrevSurveyed
              }ha (${summary.notSurveyedAreaPrevSurveyedPercentage.toFixed(1)}%)`,

          }
        ]
      },
    ],
    [
      waterBodies(summary),
    ],
    [
      {
      icon: <SvgIcon component={HouseIcon} />,
      a: `Issues`,
      b: getSubtitle(summary.issuedWaterSourceCountBefore, summary.issuedWaterSource.reduce((sum: Number, current: any) =>
        sum + current.count, 0) | 0),
      expand:
        (summary.issuedWaterSource && summary.issuedWaterSource.length > 0) ? [
          summary.issuedWaterSource.map(
            (issue: {
              type: IssueType[keyof IssueType] & string;
              count: number;
              percentage: number;
            }) => ({
              key: issue.type,
              icon: <SvgIcon component={ISSUE_ICON[issue.type]} />,
              a: `${issue.count} ${issue.type.replaceAll("_", " ")}`,
              b: formatPercentage(issue.percentage),
              tabbed: true
            })
          ),
        ] : undefined
     }
    ],
    [
      {
        icon: <SvgIcon component={HouseIcon} />,
        a: `${summary.newHouse.length} new houses`,
        expand: [summary.newHouse.map(
          (house: {
            status: HOUSE_STATUS;
            count: number;
            percentage: number;
          }) => ({
            key: house.status,
            icon: <SvgIcon component={HOUSE_ICON[house.status]} />,
            a: `${house.count} ${HOUSE_STATUS[house.status].replaceAll(
              "_",
              " "
            )}`,
            b: formatPercentage(house.percentage),
          })
        )]
      },
      {
        icon: <SvgIcon component={HouseIcon} />,
        a: `${summary.houseCountBefore} existing houses`,
        expand: [summary.existingHouse.map(
          (house: {
            prevStatus: HOUSE_STATUS;
            status: HOUSE_STATUS | -1;
            count: number;
            percentage: number;
          }) => ({
            key: house.prevStatus + "_" + house.status,
            icon: <SvgIcon component={HOUSE_ICON[house.prevStatus]} />,
            icon2:
              house.status != -1 ? (
                <SvgIcon component={HOUSE_ICON[house.status as HOUSE_STATUS]} />
              ) : (
                <HouseIcon />
              ),
            a: `${house.count} ${HOUSE_STATUS[house.prevStatus].replaceAll(
              "_",
              " "
            )} ➔ ${house.status != -1
              ? HOUSE_STATUS[house.status].replaceAll("_", " ")
              : "not visited"
              }`,
            b: formatPercentage(house.percentage),

          })
        )]
      },
    ],
    [
      {
        a: `Sprayed`,
        b: getSubtitle(summary.sprayedWaterSourceCountBefore, summary.sprayedWaterSourceCount - summary.sprayedWaterSourceCountBefore),
        expand: sprayStats(summary, false)
      },
      {
        a: `Sampled`,
        b: getSubtitle(summary.sampledWaterSourceCountBefore, summary.sampleResult.sampledWaterSourceCount),
        expand: sampleStats(summary, false)
      },
    ]
  ];
}

function sprayStats(summary: any, includeWaterBodies: boolean): StatType[][] {
  return sprayOrSampleStats(
    {
      ...summary,
      notXedWaterSourceCountBefore: summary.notSprayedWaterSourceCountBefore,
      notXedWaterSourceCountAfter: summary.notSprayedWaterSourceCountAfter,
      xedWaterSourceCount: summary.sprayedWaterSourceCount,
      xedWaterSourcePercentage: summary.sprayedWaterSourcePercentage,
    },
    "sprayed",
    includeWaterBodies
  );
}

function sampleStats(summary: any, includeWaterBodies: boolean): StatType[][] {
  return sprayOrSampleStats(
    {
      ...summary,
      notXedWaterSourceCountBefore: summary.notSampledWaterSourceCountBefore,
      notXedWaterSourceCountAfter: summary.notSampledWaterSourceCountAfter,
      xedWaterSourceCount: summary.sampledWaterSourceCountBefore + summary.sampleResult.sampledWaterSourceCount,
      xedWaterSourcePercentage: (summary.sampledWaterSourceCountBefore + summary.sampleResult.sampledWaterSourceCount) / (summary.waterSourceCountBefore + summary.newWaterSourceCount) * 100,
    },
    "sampled",
    includeWaterBodies
  );
}

const waterIcon = {
  'sprayed': <SvgIcon component={SprayedWaterIcon} viewBox={"0 0 25 36"}/>,
  'sampled': <SvgIcon component={SampledWaterIcon} viewBox={"0 0 28 42"}/>
}

function sprayOrSampleStats(summary: any, xed: 'sprayed' | 'sampled', includeWaterBodies: boolean): StatType[][] {

  let sampleResult = summary.sampleResult;
  let variableLengthSection: StatType[] = [
    {
      icon: waterIcon[xed],
      a: `${summary.xedWaterSourceCount} ${xed}`,
      b: formatPercentage(summary.xedWaterSourcePercentage),
      expand:
        (summary.sampleResult && xed === 'sampled' ?
          [[
            {
              icon: <BugReport />,
              a: `${sampleResult.larvaPupaPositiveCount} positive larva/pupa`,
              b: formatPercentage(sampleResult.larvaPupaPositivePercentage),
            },
            {
              icon: <BugReport />,
              a: `${sampleResult.anophelesPositiveCount} positive anopheles`,
              b: formatPercentage(sampleResult.anophelesPositivePercentage),
            },
            {
              icon: <BugReport />,
              a: `${sampleResult.negativeCount} negative`,
              b: formatPercentage(sampleResult.negativePercentage),
            },
            {
              icon: <BugReport />,
              a: `Avg larva/pupa positive count`,
              b: formatPercentage(sampleResult.averageLarvaPupaPositiveCount),
            }
          ]]
        : undefined)
    }
  ];

  const result = [
    [
      {
        icon: (
          <SvgIcon
            component={NewWaterIcon}
            htmlColor="#69e4ff"
            viewBox="0 0 25 36"
          />
        ),
        a: `Un${xed} remaining`,
        b: getSubtitle(summary.notXedWaterSourceCountBefore, summary.notXedWaterSourceCountAfter - summary.notXedWaterSourceCountBefore)
      },
    ],
    variableLengthSection,
    [
      {
        icon: <SvgIcon component={HousesIcon} htmlColor="#fbc13b" />,
        a: `${summary.notVisitedWaterSourceCount} not visited`,
        b: formatPercentage(summary.notVisitedWaterSourcePercentage),
        expand: [
          [
            {
              icon: <SvgIcon component={HouseIcon} />,
              a: `${summary.newHouse.length} new houses`,
              expand: [summary.newHouse.map(
                (house: {
                  status: HOUSE_STATUS;
                  count: number;
                  percentage: number;
                }) => ({
                  key: house.status,
                  icon: <SvgIcon component={HOUSE_ICON[house.status]} />,
                  a: `${house.count} ${HOUSE_STATUS[house.status].replaceAll(
                    "_",
                    " "
                  )}`,
                  b: formatPercentage(house.percentage),
                })
              )]
            },
            {
              icon: <SvgIcon component={HouseIcon} />,
              a: `${summary.houseCountBefore} existing houses`,
              expand: [summary.existingHouse.map(
                (house: {
                  prevStatus: HOUSE_STATUS;
                  status: HOUSE_STATUS | -1;
                  count: number;
                  percentage: number;
                }) => ({
                  key: house.prevStatus + "_" + house.status,
                  icon: <SvgIcon component={HOUSE_ICON[house.prevStatus]} />,
                  icon2:
                    house.status != -1 ? (
                      <SvgIcon component={HOUSE_ICON[house.status as HOUSE_STATUS]} />
                    ) : (
                      <HouseIcon />
                    ),
                  a: `${house.count} ${HOUSE_STATUS[house.prevStatus].replaceAll(
                    "_",
                    " "
                  )} ➔ ${house.status != -1
                    ? HOUSE_STATUS[house.status].replaceAll("_", " ")
                    : "not visited"
                    }`,
                  b: formatPercentage(house.percentage),

                })
              )]
            }
          ]
        ]
      }
    ]
  ];

  if (includeWaterBodies) {
    result.unshift([waterBodies(summary)])
  }
  return result;
}

function getSubtitle(before: number, count: number) {
  return <>{before} ➔ {before + count} <Typography variant="h6" component='span'>(+{count})</Typography></>;
}

function formatPercentage(x: number) {
  return `${x.toFixed(1)}%`;
}
