import { polygon } from "leaflet";

export enum VillageStatus {
  Defined = 1,
  InProcess = 2,
  Finished = 3,
}

export interface Village {
  location: LatLong;
  creatorId?: string;
  // status?: number;
  status: VillageStatus;
  chunkPrefix?: string;
  timeUpdated?: number;
  timeCreated?: number;
  name: string;
  deleted?: boolean;
  id: string;
  surveyPercentage: number;
  numberOfSampled?: number;
  numberOfSprayed?: number;
  numberOfWaterSources?: number;
  corePolygon: any[];
  beltPolygon: any[];
  hasScannedPixels: boolean;
}

export interface LatLong {
  latitude: number;
  longitude: number;
}

export const initialLatLong: LatLong = {
  latitude: -1,
  longitude: -1,
};

export const initialVillage: Village = {
  location: initialLatLong,
  creatorId: "",
  status: -1,
  chunkPrefix: "",
  timeUpdated: -1,
  timeCreated: -1,
  name: "",
  deleted: false,
  id: "",
  surveyPercentage: -1,
  numberOfSampled: -1,
  numberOfSprayed: -1,
  numberOfWaterSources: -1,
  corePolygon: [],
  beltPolygon: [],
  hasScannedPixels: false,
};
