import * as React from "react";
import { useEffect, useState } from "react";
import { Dialog, Tab, Tabs } from "@material-ui/core";
import Chart from "react-google-charts";
import moment from "moment";
import {
  getFullFormatDate,
} from "../../../../outsiders/trapResults/TrapResultDialog";

interface ITrapsGraphProps {
  onClose?: () => void;
  fetch: any;
  fields: string[];
  dateFieldName: string;
}

const getMaxSize = (dayArray: any) => {
  // @ts-ignore
  return dayArray.reduce((total: number, [id, arr]) => {
    if (arr.length > total) total = arr.length;
    return total;
  }, -1);
};
function avg(arr: any, fieldName: string) {
  return (
    arr.reduce((total: any, item: any) => (total += item[fieldName]), 0) /
    arr.length
  );
}

function graphFormat(arr: any, fieldName: string) {
  // @ts-ignore
  const sortedArray = arr.sort((a, b) => {
    const dateA = moment(a[0], "DD.MM.YY").toDate();
    const dateB = moment(b[0], "DD.MM.YY").toDate();
    return dateA > dateB ? 1 : -1;
  });
  // @ts-ignore
  return sortedArray.map(([day, results]) => [
    day,
    avg(results, fieldName),
    ...results.map((r: any) => r[fieldName]),
  ]);
}

function fillGraphMissing(arr: any, maxSize: number) {
  // @ts-ignore
  return arr.map(([day, avg, ...args]) =>
    [...[day, avg, ...args], ...Array(maxSize).fill(avg)].slice(0, maxSize)
  );
}

export const GroupGraph = React.memo((props: ITrapsGraphProps) => {
  const [state, setState] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    props.fetch().then((res: any) => setState(res.data));
  }, []);
  console.log(state);
  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const getGraph = (results: any) => {
    if (results.length === 0) return <div className="">no results</div>;
    const dayGroup = groupArrayByDate(results, props.dateFieldName);
    const maxSize = getMaxSize(dayGroup);
    const graphFields = [
      { type: "string", label: "x" },
      { type: "number", label: "values" },
    ];
    const graph = fillGraphMissing(
      graphFormat(dayGroup, props.fields[tab]),
      maxSize + graphFields.length
    );
    return (
      <React.Fragment>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {props.fields.map((f) => (
            <Tab key={f} label={f} />
          ))}
        </Tabs>
        <Chart
          height="80"
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            [
              ...graphFields,
              ...Array(maxSize)
                .fill(null)
                .map((x) => ({ id: "i2", type: "number", role: "interval" })),
            ],
            ...graph,
          ]}
          options={{
            title: props.fields[tab],
            curveType: "function",
            series: [{ color: "#E7711B" }],
            intervals: { style: "points", pointSize: 4 },
            legend: "none",
            width: 1200,
            height: 350,
          }}
          rootProps={{ "data-testid": "6" }}
        />
      </React.Fragment>
    );
  };

  return (
    <Dialog maxWidth={false} onClose={props.onClose} open={true}>
      <div className="trap-dialog-graph">
        <div className="close-trap-dialog" onClick={props.onClose}>
          x
        </div>
        {state ? getGraph(state) : <div className="">wait for server</div>}
      </div>
    </Dialog>
  );
});

export function groupArrayByDate(arr: any, dateFieldName = "resultOn") {
  return Object.entries(
    arr.reduce((total: any, item: any) => {
      if (total[getFullFormatDate(item[dateFieldName])])
        total[getFullFormatDate(item[dateFieldName])].push(item);
      else total[getFullFormatDate(item[dateFieldName])] = [item];

      return total;
    }, {})
  );
}
