import { Village } from "../../../../appRedux/stores/village.interface";

export const getVillageTitleLayer = (LayerId: string, sourceId: string) => {
  return {
    id: LayerId,
    minzoom: 11,
    maxzoom: 20,
    type: "symbol",
    source: sourceId,
    layout: {
      "text-field": ["get", "waterSource"],
      "text-size": 18,
      "text-font": ["Open Sans Bold", "Arial Unicode MS Regular"],
      "text-offset": [0, -3.2],
      "text-anchor": "top",
      "text-line-height": 1,
    },
    paint: {
      "text-color": "rgb(255,255,255)",
      "text-halo-color": "rgb(0,0,0)",
      "text-halo-width": 0.5,
    },
  };
};

export const getVillageLayer = (sourceId: string) => {
  return {
    id: sourceId,
    minzoom: 4,
    maxzoom: 13,
    type: "symbol",
    source: sourceId,
    layout: {
      "icon-image": ["get", "icon"],
      "icon-size": 0.75,
      "text-allow-overlap": true,
      "icon-allow-overlap": true,
    },
  };
};

export const getVillageSource = (villages: Village[]) => {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: villages.map((village: Village) => {
        return getVillageFeature(village, village.status);
      }),
    },
  };
};
export const getVillageFeature = (village: any, icon: any) => {
  const summary = village.summary;
  return {
    type: "Feature",
    id: village.id,
    geometry: {
      type: "Point",
      coordinates: village.center,
    },
    properties: {
      id: village.id,
      icon: icon,
      waterSource: `${village.name} \n sources ${summary.totalSources}`,
      scanned: `${village.name} \n scanned ${Math.round(
        summary.totalScanned * 1000
      ) / 10}%`,
      sprayed: `${village.name} \n sprayed ${summary.totalSprayed}/${summary.totalSources}`,
      sampled: `${village.name} \n sampled ${summary.totalSampled}/${summary.totalSources}`,
      sprayedIcon: summary.lastSprayedStatus,
    },
  };
};
