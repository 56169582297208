import { getCurrentOrigin } from "../../../../services/GlobalService";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  hideLayout,
  showLayout,
} from "../utils";
import { getFeature, getLayer, getSource } from "../traps/utils";
import { getStationLayer, getStations } from "./utils/Icon";
import { Station } from "../../../../appRedux/stores/station.interface";

interface IObjectKeys {
  [key: string]: string;
}

export const stationDrawStatus: IObjectKeys = {
  station: "station",
};

class StationDrawer {
  private map: any;
  private layerId = "stationsDraw";
  private stationSelectedId = "stationSelectedMapId";
  private stations: any;
  private iconSize: number = 0.33;

  constructor(map: any) {
    this.map = map;
    this.drawStations();

    addCursorIconToLayer(this.map, this.layerId);
    addCursorIconToLayer(this.map, this.stationSelectedId);
  }

  drawStations = () => {
    this.loadImage().then(() => {
      this.addStations();
      this.map.addLayer(getStationLayer(this.layerId, this.iconSize));
      this.drawSelected();
    });
  };

  drawSelected = () => {
    this.map.addSource(
      this.stationSelectedId,
      getSource(getFeature(stationDrawStatus["station"], [0, 0]))
    );
    this.map.addLayer(
      getLayer(this.stationSelectedId, stationDrawStatus["station"], this.iconSize)
    );

    this.hideSelected();
  };

  addStations = () => {
    this.map.addSource(this.layerId, getEmptyGeoJson());
  };

  private hideSelected = () => {
    hideLayout(this.stationSelectedId, this.map);
  };

  onChangeCampaign = (stations: any, saveStations = true) => {
    if (saveStations) this.stations = stations;
    if (this.map.getSource(this.layerId)) {
      const temp = getStations(Object.values(stations || [])).data;
      this.map.getSource(this.layerId).setData(temp);
      this.hideSelected();
    }
  };

  selectStationIcon = (station: Station) => {
    this.map
      .getSource(this.stationSelectedId)
      .setData(
        getFeature(stationDrawStatus["station"], [
          station.location.longitude,
          station.location.latitude,
        ])
      );
    this.map.moveLayer(this.stationSelectedId, this.layerId);
    showLayout(this.stationSelectedId, this.map);
  };

  loadImage = async () => {
    return Promise.all(
      Object.values(stationDrawStatus).map((path: string) => {
        return new Promise((res: any) => {
          this.map.loadImage(
            `${getCurrentOrigin()}/images/stations/${path}.webp`,
            (error: any, image: any) => {
              this.map.addImage(path, image);
              res();
            }
          );
        });
      })
    );
  };
  
  setLayerVisibility = (showScan: boolean) => {
    if (showScan){
       showLayout(this.layerId, this.map);
       showLayout(this.stationSelectedId, this.map);
    }
    else {
      hideLayout(this.layerId, this.map);
      hideLayout(this.stationSelectedId, this.map);
    }
  };

  clickStationsIcon = (
    handleSelectStation: (stationId: string) => void,
    features: any
  ) => {
    handleSelectStation(features[0].properties.stationId);
    this.selectStationIcon(this.stations[features[0].properties.stationId]);
  };

  changeIconByZoom = (zoomNumber: number) => {
    if (this.map.getLayer(this.layerId)) {
      const newIconSize = 0.75 + 0.15 * (zoomNumber - 15);
      this.map.setLayoutProperty(this.layerId, "icon-size", newIconSize);
      this.map.setLayoutProperty(
        this.stationSelectedId,
        "icon-size",
        newIconSize
      );
    }
  };
}

export default StationDrawer;
