import { connect } from "react-redux";
import { CampaignSideBar } from "./CampaignSideBar";
import {
  IdialogOptions,
  serverDialog,
} from "../../../../appRedux/actions/ManageApiDialogAction";
import { getCurrentUserProfile } from "../../../../services/GlobalService";

const mapStateToProps = (state: any) => {
  return {
    villages: state.villages.villageV1,
    campaign: state.campaigns.campaign,
    users: state.usersReducer.users,
    currentUserProfile: getCurrentUserProfile().role,
    trapGraph: state.campaigns.campaign.summary.totalTraps > 0, //TODO: i realy nead flag for trap result
    sampleGraph: state.campaigns.campaign.summary.totalSampled > 0,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    serverDialog: (
      callBack: any,
      successMsg: string,
      failMsg: string,
      options?: IdialogOptions
    ) => dispatch(serverDialog(callBack, successMsg, failMsg, options)),
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(CampaignSideBar);
