export enum VillageType {
  CORE,
  BELT,
}

export const getLayerFill = (
  layerId: string,
  sourceId: string,
  color: string
) => {
  return {
    id: layerId,
    minzoom: 13,
    maxzoom: 15,
    type: "fill",
    source: sourceId,
    paint: {
      "fill-color": color,
      "fill-opacity": 0.11,
      "fill-outline-color": "rgb(0,0,0)",
    },
  };
};
export const getLayerLine = (
  layerId: string,
  sourceId: string,
  color: string
) => {
  return {
    id: layerId,
    minzoom: 13,
    maxzoom: 24,
    type: "line",
    source: sourceId,
    paint: {
      "line-color": [
        "case",
        ["boolean", ["feature-state", "active"], false],
        "rgb(246, 191, 59)",
        color,
      ],
      "line-width": 3,
    },
  };
};

export const getVillagePolygonSource = (villages: any) => {
  const features = villages.map((village: any) =>
    getVillageFeature(village.id, village.name, village.cords[0])
  );
  const a = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
  return a;
};

export const getVillageFeature = (
  villageId: string,
  name: string,
  villagePolygon: any
) => {
  return {
    type: "Feature",
    id: getAsciiCodeId(villageId),
    geometry: {
      type: "Polygon",
      coordinates: [villagePolygon],
    },
    properties: {
      id: villageId,
      villageName: `lala`,
    },
  };
};

// feature id must be only number
// Convert village id to number
export const getAsciiCodeId = (id: string) => {
  let asciiCode = "";
  for (let char of Array.from(id)) {
    if (isCharDigit(char)) asciiCode = asciiCode + char;
    else asciiCode = asciiCode + (char.charCodeAt(0) - 96);
  }
  return parseInt(asciiCode);
};

function isCharDigit(n: any) {
  return !!n.trim() && n > -1;
}
