import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { TaskDialog } from "./TaskDialog";
import { IdialogOptions } from "../../../appRedux/actions/ManageApiDialogAction";
import "./Task.scss";
import TasksTable from "./TaskTable";
import { ITask } from "../../../appRedux/stores/task.interface";
import { IAssignment } from "../../../appRedux/stores/assignment.interface";

interface ITaskProps {
  serverDialog: (
    callBack: any,
    successMsg: string,
    failMsg: string,
    options?: IdialogOptions
  ) => void;
  serverDialogMessage: (msg: string, header: string) => void;
  serverLoading: (callBack: () => any, options?: IdialogOptions) => void;
  addTask: (campaignId: string, body: ITask) => void;
  fetchTasks: (campaignId: string) => void;
  addAssignment: (newAssignmentData: IAssignment) => void;
  clearAssignment: () => void;
  handleTaskReview: (
    history: any,
    campaignId: string,
    assignmentId: string,
    taskType: number
  ) => void;
  villages: any;
  users: any;
  tasks: any;
  campaign: any;
  getVillageNameById: any;
  history: any;
  error?: string;
  handleError: () => void;
}

export const  Task: FunctionComponent<ITaskProps> = (props) => {
  useEffect(() => {
    if (!props.campaign) return;
    props.clearAssignment();
    props.fetchTasks(props.campaign.id);
  }, [props.campaign]);

  if (!props.campaign || !props.tasks) return null;
  else {
    return (
      <div className="task-container-card">
        <div className="task-card">
          <TaskDialog
            addTask={props.addTask}
            villages={props.villages}
            campaignId={props.campaign.id}
            serverDialog={props.serverDialog}
          />
          <TasksTable
            getVillageNameById={props.getVillageNameById}
            villages={props.villages}
            tasks={props.tasks}
            campaignId={props.campaign.id}
            users={props.users}
            addAssignment={props.addAssignment}
            serverDialog={props.serverDialog}
            serverDialogMessage={props.serverDialogMessage}
            handleTaskReview={props.handleTaskReview}
            history={props.history}
            error={props.error}
            handleError={props.handleError}
          />
        </div>
      </div>
    );
  }
};
