import React, { Component } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { deleteCampaignAction } from "../../../../appRedux/actions/CampaignsActions";
import { connect } from "react-redux";
import _ from "lodash";

import "./campaignManagementComponent.scss";
import CampaignsTable from "./campaignsTable";
import AddCampaignTemplate from "../../../templates/add-campaign-template/add-campaign-template";
import { Campaign } from "../../../../appRedux/stores/campaign.interface";

class CampaignManagementComponent extends Component<any, any> {
  static contextTypes = {
    router: PropTypes.object,
  };

  private campaignsData: Campaign[] = [];
  private campaignsHeadCells: any[] = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "country", numeric: false, disablePadding: true, label: "Country" },
  ];

  private formatMessage: any;

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      minimizeIsPressed: false,
      openModal: false,
      modalMode: 0, //0 - create user , 1 - update user, 2 - delete user,
      campaignId: null,
    };
  }

  render() {
    this.formatMessage = this.props.intl.formatMessage;
    return (
      <div className="campaign-main-wrapper">
        <div className="campaign-main-container">
          {this.createCampaignTitleBox()}
          {this.createSeparator()}
          {this.createCampaignListElements()}
          {this.createSeparator()}
          <div className="campaign-bg-img">
            <img src="/images/campaign-management-img.svg"></img>
          </div>
        </div>
        {this.state.openModal && (
          <AddCampaignTemplate
            callback={this.handleClose.bind(this)}
            modalMode={this.state.modalMode}
            campaignId={this.state.campaignId}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentDidUpdate(prevProps: any, prevState: any) {}

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps, true);
  }

  private init(props: any, receiveProps: boolean = false) {
    if (!_.isEmpty(props.campaigns)) {
      const campaigns: Campaign[] = props.campaigns;
      this.campaignsData = [];
      for (let i = 0; i < campaigns.length; i++) {
        const campaign: Campaign = campaigns[i];
        this.campaignsData.push({
          id: campaign.id,
          name: campaign.name,
          country: campaign.country,
        });
      }
      if (!receiveProps) {
        this.setState({});
      }
    }
  }

  private createSeparator() {
    return (
      <hr
        className={
          "separator-line" +
          (this.state.minimizeIsPressed ? " separator-line-minimized" : "")
        }
      ></hr>
    );
  }

  private createCampaignTitleBox() {
    return (
      <div className="campaigns-title-strip">
        <div className="add-campaign-container">{this.createAddCampaign()}</div>
        {this.createCampaignTitle()}
      </div>
    );
  }

  private createCampaignTitle() {
    return (
      <div className="campaigns-title-container">
        <div className="campaigns-title-icon-strip">
          <img className="campaigns-icon" src="/images/settings-icon.svg"></img>
          <div className="campaigns-title">Campaigns</div>
        </div>
        <div className="campaigns-list">List of all your Campaigns</div>
      </div>
    );
  }

  private createAddCampaign() {
    return (
      <div
        className="zz-btn add-campaign-btn"
        onClick={this.handleAddCampaignPress.bind(this)}
      >
        {"+ " + this.formatMessage({ id: "app.addCampaignPage.AddCampaign" })}
      </div>
    );
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  private handleAddCampaignPress() {
    this.setState({ modalMode: 0, openModal: true, campaignId: null });
  }

  private createCampaignListElements() {
    return (
      <div className="campaigns-container">
        <CampaignsTable
          className="campaigns-tbl-container"
          campaigns={this.campaignsData}
          columnsNames={this.campaignsHeadCells}
          formatMessage={this.formatMessage}
          handleEdit={this.handleEdit.bind(this)}
          handleDelete={this.handleDelete.bind(this)}
        />
      </div>
    );
  }

  private handleEdit(campaignId: string) {
    this.setState({
      campaignId: campaignId,
      modalMode: 1,
      openModal: true,
    });
  }

  private handleDelete(campaignId: string) {
    this.props.deleteCampaignAction(campaignId);
  }
}

const mapStateToProps = (state: any) => {
  const { campaigns } = state.campaigns;
  return { campaigns };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ deleteCampaignAction }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CampaignManagementComponent));
