import mgrs from "mgrs";
import {
  convertChunkToPolygon,
  getDownChunk,
  getLeftChunk,
  getRightChunk,
  getUpChunk,
} from "./utils";
import { Promise } from "bluebird";

interface Point {
  x: number;
  y: number;
}

interface ChunkObj {
  [id: string]: any;
}

function getChunk(p: string) {
  return mgrs.forward(p, 3);
}

function getIncline(p1Arr: any, p2Arr: any) {
  const p1 = convertArrToPoint(p1Arr);
  const p2 = convertArrToPoint(p2Arr);
  return (p2.y - p1.y) / (p2.x - p1.x);
}

function convertArrToPoint(arr: any) {
  const k = 10000000;
  return { x: arr[0] * k, y: arr[1] * k };
}

function isDotsPartition(p1Arr: any, p2Arr: any, p3Arr: any, p4Arr: any) {
  const p1 = convertArrToPoint(p1Arr);
  const p2 = convertArrToPoint(p2Arr);
  const p3 = convertArrToPoint(p3Arr);
  const p4 = convertArrToPoint(p4Arr);
  return intersects(p1, p2, p3, p4);
}

function intersects(p1: Point, p2: Point, p3: Point, p4: Point) {
  let det, gamma, lambda;
  det = (p2.x - p1.x) * (p4.y - p3.y) - (p4.x - p3.x) * (p2.y - p1.y);
  if (det === 0) {
    return false;
  } else {
    lambda =
      ((p4.y - p3.y) * (p4.x - p1.x) + (p3.x - p4.x) * (p4.y - p1.y)) / det;
    gamma =
      ((p1.y - p2.y) * (p4.x - p1.x) + (p2.x - p1.x) * (p4.y - p1.y)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

export function rightUp(p1: any, p2: any) {
  let nextP = getChunk(p1);
  const chunkObj: ChunkObj = {};
  while (nextP !== getChunk(p2)) {
    const chunkBbox = convertChunkToPolygon(nextP);
    if (isDotsPartition(p1, p2, chunkBbox[0], chunkBbox[1])) {
      nextP = getUpChunk(nextP);
    } else {
      nextP = getRightChunk(nextP);
    }
    chunkObj[nextP] = convertChunkToPolygon(nextP);
  }
  return chunkObj;
}

export function rightDown(p1: any, p2: any) {
  let nextP = getChunk(p1);
  const chunkObj: ChunkObj = {};
  while (nextP !== getChunk(p2)) {
    const chunkBbox = convertChunkToPolygon(nextP);
    if (isDotsPartition(p1, p2, chunkBbox[1], chunkBbox[2])) {
      nextP = getRightChunk(nextP);
    } else {
      nextP = getDownChunk(nextP);
    }
    chunkObj[nextP] = convertChunkToPolygon(nextP);
  }
  return chunkObj;
}

export function leftUp(p1: any, p2: any) {
  let nextP = getChunk(p1);
  const chunkObj: ChunkObj = {};
  while (nextP !== getChunk(p2)) {
    const chunkBbox = convertChunkToPolygon(nextP);
    if (isDotsPartition(p1, p2, chunkBbox[0], chunkBbox[1])) {
      nextP = getUpChunk(nextP);
    } else {
      nextP = getLeftChunk(nextP);
    }
    chunkObj[nextP] = convertChunkToPolygon(nextP);
  }
  return chunkObj;
}

export function leftDown(p1: any, p2: any) {
  let nextP = getChunk(p1);
  const chunkObj: ChunkObj = {};
  while (nextP !== getChunk(p2)) {
    const chunkBbox = convertChunkToPolygon(nextP);
    if (isDotsPartition(p1, p2, chunkBbox[2], chunkBbox[3])) {
      nextP = getDownChunk(nextP);
    } else {
      nextP = getLeftChunk(nextP);
    }
    chunkObj[nextP] = convertChunkToPolygon(nextP);
  }
  return chunkObj;
}

function handlePositiveIncline(p1: any, p2: any) {
  if (convertArrToPoint(p1).y < convertArrToPoint(p2).y) {
    return rightUp(p1, p2);
  }
  return leftDown(p1, p2);
}

function handleNegativeIncline(p1: any, p2: any) {
  if (convertArrToPoint(p1).y < convertArrToPoint(p2).y) {
    return leftUp(p1, p2);
  }
  return rightDown(p1, p2);
}

function combineObj(obj1: any, obj2: any) {
  return Object.assign(obj1, obj2);
}

export function getRasterBorder(coordinate: any) {
  return new Promise((resolve, reject) => {
    let rasterBorderPolygon: ChunkObj = {};
    for (let i = 0; i < coordinate.length - 1; i++) {
      if (Math.abs(getIncline(coordinate[i], coordinate[i + 1])) === 0)
        reject("incline is 0");

      if (getIncline(coordinate[i], coordinate[i + 1]) > 0) {
        rasterBorderPolygon = combineObj(
          rasterBorderPolygon,
          handlePositiveIncline(coordinate[i], coordinate[i + 1])
        );
      } else {
        rasterBorderPolygon = combineObj(
          rasterBorderPolygon,
          handleNegativeIncline(coordinate[i], coordinate[i + 1])
        );
      }
    }
    resolve(rasterBorderPolygon);
  });
}
export async function limitRasterTime(coordinate: any) {
  return Promise.race([limitTime(), getRasterBorder(coordinate)]);
}

function limitTime() {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject("timeout"), 2000);
  });
}
