import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editVillageAction } from "../../../../../appRedux/actions/VillagesActions";
import { VillageReviewSideBar } from "./VillageReviewSideBar";
import {
  IdialogOptions,
  serverDialog,
  serverLoading,
} from "../../../../../appRedux/actions/ManageApiDialogAction";
import { getCurrentUserProfile } from "../../../../../services/GlobalService";

const mapStateToProps = (state: any, ownProps: any) => {
  const village = state.villages.villageV1[ownProps.villageId];
  const campaign = state.campaigns.campaign;

  const { users } = state.usersReducer;
  return {
    village: village,
    users,
    scanFiles: state.campaigns.scans,
    currentUserProfileRole: getCurrentUserProfile().role,
    campaign: campaign,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    editVillage: (body: any, campaignId: string, villageId: string) =>
      dispatch(editVillageAction(body, campaignId, villageId)),
    serverLoading: (callBack: () => any, options?: IdialogOptions) =>
      dispatch(serverLoading(callBack, options)),
  };
};

// export default connect(
//   mapStateToProps,
//   matchDispatchToProps
// )(VillageReviewSideBar);


const VillageReviewSideBarContainer = connect(
  mapStateToProps,
  matchDispatchToProps
)(VillageReviewSideBar);

export default withRouter(VillageReviewSideBarContainer);