import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import Checkbox from "material-ui/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

interface IStationsFiltersProps {
  showStationsLayer: (show: boolean) => void;
  zoom: number;
  formatMessage: any;
}

export const StationsFilters: FunctionComponent<IStationsFiltersProps> = (
  props
) => {
  const [showStations, setShowStation] = useState(true);

  const handleCheckShowStations = (e: any) => {
    setShowStation((prevState) => !prevState);
    props.showStationsLayer(e.target.checked);
  };

  if (props.zoom < 14) return null;

  return (
    // TODO: why is it a dropdown
    <div id="ws-selector" className="dropdown">
      <Tooltip title={"Show Stations"}>
        <Checkbox
          checked={showStations}
          onCheck={handleCheckShowStations}
          color="primary"
        />
      </Tooltip>
    </div>
  );
};
