import { Spinner } from "./Spinner";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    isSpinner: state.spinner.isSpinner,
  };
};

export default connect(mapStateToProps, null)(Spinner);
