import { FETCH_STATIONS } from "../actions/CampaignsActions";
import {
  ADD_STATION,
} from "../actions/StationActions";


const StationReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_STATIONS:
      return action.payload;
    case ADD_STATION: {
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    }
    default:
      return state;
  }
};
export default StationReducer;