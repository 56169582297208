import * as React from "react";
import { capitalizeFirstLetter } from "../../../../../../Formatting";
import { truncateCoord } from "../../../../../../services/GlobalService";
import {
  Issue,
  Sample,
  Spray,
  WaterAction,
  WaterActionType,
} from "../../../../../../appRedux/stores/waterSources.interface";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { ImageDialog } from "../../../../../templates/basic_componnets/ImageDialog";
import {
  fetchSampleResults,
} from "../../../../../../services/TrapService";
import { GroupGraph } from "../VillagesTrapsGraph";
import {
  getFormatDate,
  getFullFormatDate,
} from "../../../../../outsiders/trapResults/TrapResultDialog";

interface IWaterSourceProps {
  formatMessage: any;
  handleVillageOnSideBar: any;
  getVillageNameById: any;
  waterSource: any;
  users: any;
}

interface IWaterSourceState {
  openImage: boolean;
  openSampleGraph: boolean;
}

export class WaterSource extends React.Component<
  IWaterSourceProps,
  IWaterSourceState
> {
  constructor(props: IWaterSourceProps) {
    super(props);
    this.state = {
      openImage: false,
      openSampleGraph: false,
    };
  }

  handleOpenImageMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      openImage: !prevState.openImage,
    }));
  };
  handleOpenSampleGraphMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSampleGraph: !prevState.openSampleGraph,
    }));
  };

  getSprayGroupByDay = (sprays: any) => {
    const spraysObj = sprays.reduce((total: any, item: any) => {
      total[getFormatDate(item.reportedOn)] = item;
      return total;
    }, {});
    return Object.keys(spraysObj).length;
  };

  render() {
    const {
      formatMessage,
      getVillageNameById,
      handleVillageOnSideBar,
      waterSource,
    } = this.props;

    if (waterSource) {
      let waterActions: Array<WaterAction> = [];
      waterActions.push(
        ...waterSource.issues.map((x: Issue) => ({
          type: WaterActionType.Issue,
          action: x,
        }))
      );
      waterActions.push(
        ...waterSource.samples.map((x: Sample) => ({
          type: WaterActionType.Sample,
          action: x,
        }))
      );
      waterActions.push(
        ...waterSource.sprays.map((x: Spray) => ({
          type: WaterActionType.Spray,
          action: x,
        }))
      );
      waterActions.sort(
        (a: WaterAction, b: WaterAction) =>
          b.action.reportedOn - a.action.reportedOn
      );

      console.log(waterActions);
      return (
        <div className="r-container">
          {this.state.openSampleGraph && (
            <GroupGraph
              dateFieldName={"reportedOn"}
              fields={["anopheles", "culex", "pupae"]}
              fetch={() => fetchSampleResults(waterSource.id)}
              onClose={this.handleOpenSampleGraphMode}
            />
          )}
          <div
            className="ws-back-btn"
            onClick={() => handleVillageOnSideBar(waterSource.village)}
          >
            <div className="back-icon">
              <img src="/images/back-icon.svg" alt="" />
            </div>
            <div className="back-text">
              {"Village " + getVillageNameById(waterSource.village)}
            </div>
          </div>
          {/* Title */}
          <div className="ws-name">
            {capitalizeFirstLetter(
              formatMessage({ id: "app.type." + waterSource.type })
            )}
          </div>
          {/* Created */}
          <div className="ws-created">
            <span className="b-waight">
              {formatMessage({ id: "app.created" })}
            </span>
            {": " + new Date(waterSource.reportedOn).toLocaleString("en-GB")}
          </div>
          <div className="ws-created-by">{`Created By: ${waterSource.createdBy}`}</div>
          <div className="ws-id">
            {" "}
            {formatMessage({ id: "app.waterSourceId" })}
            {waterSource.id}{" "}
          </div>
          <div className="gray-line"></div>

          <div className="ws-description">
            {/* Coords */}
            <div className="mb2 flx align-items-center">
              <img
                src="/images/coordinates.svg"
                className="coordinates-icon"
                alt="coordinates icon"
              />
              {` ${truncateCoord(waterSource.center[1], 6)}, ${truncateCoord(
                waterSource.center[0],
                6
              )}`}
            </div>
            {/* Water body desc */}
            <div className="flx align-items-center">
              <img
                src="/images/water-bubble-icon.svg"
                className="water-bubble-icon"
                alt="water source description icon"
              />
              {formatMessage({ id: "app.size." + waterSource.size })}
              {", "}
              {formatMessage({ id: "app.type." + waterSource.type })}
              {", "}
              {formatMessage({
                id: waterSource.isShaded
                  ? "app.shade.shady"
                  : "app.shade.notShaded",
              })}
              {", "}
              {formatMessage({
                id: waterSource.temporary
                  ? "app.temporary.yes"
                  : "app.temporary.no",
              })}
            </div>
            {waterSource.deleted &&
              typeof waterSource.deleted === "boolean" &&
              waterSource.deleted && (
                <div className="deleted-drone-ws">Deleted</div>
              )}
          </div>
          {waterSource.images && waterSource.images.length && (
            <div onClick={this.handleOpenImageMode} className="ws-picture">
              <img src={waterSource.images[0]} alt="" />
            </div>
          )}
          {this.state.openImage && (
            <ImageDialog
              onClose={this.handleOpenImageMode}
              src={waterSource.images[0]}
            />
          )}
          {/* Times Sprayed */}
          <div className="bb-waight">{`Sprayed ${this.getSprayGroupByDay(
            waterSource.sprays
          )} times`}</div>
          {waterSource.sprays.length > 0 && (
            <div className="ws-last-visit">
              <span className="bb-waight">Last spray</span>
              {": " +
                new Date(
                  waterSource.sprays.reduce(function(a: any, b: any) {
                    return a.reportedOn >= b.reportedOn ? a : b;
                  }, {}).reportedOn
                ).toLocaleString("en-GB")}
            </div>
          )}

          {/* Sample graph */}
          {waterSource.isSampled && (
            <div
              onClick={this.handleOpenSampleGraphMode}
              className="open-sample-btn"
            >
              View Sample Graph
            </div>
          )}
          {waterActions.map((waterAction: any) => {
            const action = waterAction.action;
            switch (waterAction.type) {
              case WaterActionType.Issue:
                return (
                  <div key={action.id} className="ws-not-found">
                    <img src="/images/not-found-icon.svg" alt="" />
                    {formatMessage({ id: "app.issue." + action.type })}
                    <span>{`  (${getFullFormatDate(action.reportedOn)}`}</span>
                    <span>{` ${action.createdBy}`}</span>
                  </div>
                );
              case WaterActionType.Spray:
                return (
                  <div key={action.id}>
                    <div className="data-from-field">
                      <div className="data-item">
                        <div className="data-shape-2 green"></div>
                        <span className="ws-sprayed pl-2">
                          <div
                            className="data-item data-item-2-icon mb1"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span className="bold">
                              {formatMessage({
                                id: action.expired
                                  ? "app.expiredSpray"
                                  : "app.sprayed",
                              })}
                            </span>
                            <span
                              className="pl-1"
                              style={{ marginLeft: "5rem" }}
                            >
                              <PersonOutlineIcon
                                fontSize="small"
                                className="_green"
                              />
                              {`By ${action.createdBy}`}
                            </span>
                          </div>
                          <br />
                          <span
                            className={
                              new Date(action.expired) < new Date()
                                ? "color-lust"
                                : ""
                            }
                          >
                            {new Date(action.reportedOn).toLocaleString(
                              "en-GB"
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              case WaterActionType.Sample:
                let positive =
                  (action.anopheles && action.anopheles > 0) ||
                  (action.culex && action.culex > 0) ||
                  (action.pupae && action.pupae > 0);
                return (
                  <div key={action.id}>
                    <div className="data-from-field">
                      <div className="data-item">
                        <div className="data-shape-1 purple"></div>
                        <span className="ws-sampled pl-2">
                          <span className="bold">
                            {formatMessage({
                              id: action.expired
                                ? "app.expiredSample"
                                : "app.sampled",
                            })}
                            <span style={{ color: positive ? "red" : "green" }}>
                              {positive ? " - POSITIVE" : "- NEGATIVE"}
                            </span>
                          </span>
                          <br />
                          <span
                            className={
                              new Date(action.expired) < new Date()
                                ? "color-lust"
                                : ""
                            }
                          >
                            {new Date(action.reportedOn).toLocaleString(
                              "en-GB"
                            )}
                          </span>
                        </span>
                      </div>
                      {action.anopheles && action.anopheles > 0 ? (
                        <>
                          <div className="vertical-dotted-line purple"></div>
                          <div className="data-item">
                            <div className="data-shape-2 orange"></div>
                            <span className="pl-2">
                              <span className="ws-larva-positive">
                                {`${formatMessage({
                                  id: "app.larva.anopheles",
                                })} ${action.anopheles}`}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        undefined
                      )}
                      {action.culex && action.culex > 0 ? (
                        <>
                          <div className="vertical-dotted-line orange"></div>
                          <div className="data-item">
                            <div className="data-shape-2 orange"></div>
                            <span className="pl-2">
                              <span className="ws-larva-positive">
                                {`${formatMessage({
                                  id: "app.larva.culex",
                                })} ${action.culex}`}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        undefined
                      )}
                      {action.pupae && action.pupae > 0 ? (
                        <>
                          <div className="vertical-dotted-line orange"></div>
                          <div className="data-item">
                            <div className="data-shape-2 orange"></div>
                            <span className="pl-2">
                              <span className="ws-larva-positive">
                                {`${formatMessage({
                                  id: "app.larva.pupae",
                                })} ${action.pupae}`}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        undefined
                      )}
                    </div>
                  </div>
                );
            }
          })}
        </div>
      );
    }
    return null;
  }
}
