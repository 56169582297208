import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ArrowDropUp } from "@material-ui/icons";
import v1 from "uuid/v1";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { Enums } from "../../../../Enums";
import { Campaign } from "../../../../appRedux/stores/campaign.interface";
import { getCurrentUserProfile } from "../../../../services/GlobalService";
import YesOrNoTemplate from "../../../templates/yes-or-no-template/yes-or-no-template";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";

const styles: any = {
  root: {
    backgroundColor: "rgba(246, 246, 247, 1)",
    height: "100%",
    fontWeight: "bold",
    color: "rgba(45, 50, 71, 1)",
    textAlign: "left",
    letterSpacing: "0.02em",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1062px",
    fontSize: "18px",
  },
  titleCell: {
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgba(45, 50, 71, 1)",
    letterSpacing: "0.03em",
    flexDirection: "row",
    borderBottom: "none",
    padding: "0px 0px 0px 1rem !important",
    width: "100%",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "2rem",
  },
  tableBody: {
    width: "100%",
    padding: "0px 1rem",
  },
  tableBodyContent: {
    overflowY: "auto",
    maxHeight: "10.8rem",
  },
  tableWrapper: {
    height: "100%",
    paddingTop: "10px",
  },
  head: {
    width: "100% !important",
  },
  tableHeaderRow: {
    width: "60%",
    display: "flex",
    marginBottom: "0.6rem",
    height: "unset",
    paddingRight: "1rem",
  },
  tableRow: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0,0.09)",
    width: "100%",
    display: "flex",
    marginTop: "0.5rem",
  },
  editIcons: {
    minWidth: "40%",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid rgb(224, 224, 224)",
    justifyContent: "flex-end",
  },
  tableTitle: {
    padding: "15px 0px 0px 15px",
    position: "relative",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colsContainer: {
    width: "60%",
    display: "flex",
  },
  contentTds: {
    padding: "0px 0px 0px 1rem !important",
    width: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
  actionsBar: {
    width: "4.2rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "1rem",
  },
  actionsBarImage: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
  },
  filterIcon: {
    left: "8px",
    cursor: "pointer",
  },
  checkBoxLabelStyle: {
    fontSize: "16px",
    color: "rgb(45, 50, 71)",
    textAlign: "left",
    letterSpacing: "0.03em",
  },
};

let classes: any;

function desc(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: any, cmp: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getSorting(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
}

const theme = createMuiTheme({
  typography: {},
});

function TableHeadCells(props: any) {
  const { order, orderBy, onRequestSort, cells } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <TableRow style={styles.tableHeaderRow}>
        {cells.map((cell: any) => (
          <TableCell
            key={cell.id}
            style={styles.titleCell}
            padding={cell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === cell.id ? order : false}
          >
            <Tooltip
              title="Sort"
              placement={cell.numeric ? "bottom-end" : "bottom-start"}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={order}
                onClick={createSortHandler(cell.id)}
                IconComponent={ArrowDropUp}
              >
                {cell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </MuiThemeProvider>
  );
}

TableHeadCells.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class CampaignsTable extends Component<any, any> {
  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnsNames: PropTypes.arrayOf(PropTypes.object).isRequired,
    formatMessage: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
  };

  static childContextTypes = {
    muiTheme: PropTypes.object,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }

  data: Campaign[];
  columnsNames: any[];

  constructor(props: any) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "fullName",
      selected: [],
      setSelected: [],
      boxesChecked: [true, true, true, true],
      currentUserProfile: {
        role: getCurrentUserProfile().role,
      },
      deleteDialogCard: {
        openCard: false,
        campaignId: "",
      },
    };
    this.data = props.campaigns;
    this.columnsNames = props.columnsNames;
    classes = props.classes;
  }

  private handleRequestSort(event: any, property: any) {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  }

  private handleSelectAllClick(event: any) {
    if (event.target.checked) {
      const newSelecteds = this.data.map((n: any) => n.id);
      this.setState({ setSelected: newSelecteds });
      return;
    }
    this.setState({ setSelected: [] });
  }

  private handleClick(event: any, id: any) {
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ setSelected: newSelected });
  }

  private getTableCells(cell: Campaign) {
    const table_cells: any = [];
    Object.keys(cell).forEach((propName: any) => {
      if (propName !== "id") {
        table_cells.push(
          <TableCell
            style={styles.contentTds}
            key={table_cells.id + "-" + v1()}
          >
            {cell[propName]}
          </TableCell>
        );
      }
    });
    return table_cells;
  }

  handleCheckBoxChange = (index: 0) => (event: any) => {
    const temp = [...this.state.boxesChecked];
    temp[index] = event.target.checked;
    this.setState({ boxesChecked: temp });
  };

  componentWillReceiveProps(nextProps: any) {
    this.data = nextProps.campaigns;
  }
  handleDelete = (id: string) => {
    this.setState({ deleteDialogCard: { campaignId: id, openCard: true } });
  };
  handleDeleteDialog = (status: boolean) => {
    this.setState({
      deleteDialogCard: { ...this.state.deleteDialogCard, openCard: status },
    });
  };
  render() {
    const isSelected = (id: any) => this.state.selected.indexOf(id) !== -1;
    const classes = styles;
    return (
      <Paper style={classes.root}>
        <div style={classes.tableTitle}>
          <span>
            {this.props.formatMessage({ id: "app.addCampaignPage.tblTitle" })}
          </span>
        </div>
        <div style={classes.tableWrapper}>
          <Table style={classes.table} aria-labelledby="tableTitle">
            <TableBody style={classes.tableBody}>
              <TableHeadCells
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick.bind(this)}
                onRequestSort={this.handleRequestSort.bind(this)}
                rowCount={3}
                cells={this.columnsNames}
              />
            </TableBody>
            <TableBody className={``}>
              {stableSort(
                this.data,
                getSorting(this.state.order, this.state.orderBy)
              ).map((n: any, i: number) => {
                const isItemSelected = isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event: any) => this.handleClick(event, n.id)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isItemSelected}
                    style={classes.tableRow}
                    className={`${this.data.length - 1 === i ? "mb5" : ""}`}
                  >
                    {this.getTableCells(n)}
                    <td style={classes.editIcons}>
                      {this.state.currentUserProfile.role !==
                      Enums.USER_TYPES.observer ? (
                        <div style={classes.actionsBar}>
                          <img
                            style={classes.actionsBarImage}
                            onClick={(event) => this.props.handleEdit(n.id)}
                            src="/images/edit-icon.svg"
                            alt=""
                          />
                        </div>
                      ) : null}
                    </td>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <YesOrNoTemplate
            textMessage={"Are you sure "}
            open={this.state.deleteDialogCard.openCard}
            close={() => {
              this.handleDeleteDialog(false);
            }}
            continue={() => {
              this.props.handleDelete(this.state.deleteDialogCard.campaignId);
            }}
          />
        </div>
      </Paper>
    );
  }
}

export default CampaignsTable;
