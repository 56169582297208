import axios from "axios";
import { Campaign } from "../appRedux/stores/campaign.interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const getCampaign = (campaignId: string, assignmentId?: string) => {
  let filter = assignmentId ? "&assignmentId=" + assignmentId : "";
  return instance.get(
    "/api/v1/campaigns/" +
      campaignId +
      "?include=settings,summary,villages,village.chunks,village.sources,village.summary" +
      filter
  );
};

export const getAssignmentStats = (campaignId: string, assignmentId: string) => {
  let filter = assignmentId ? "&assignmentId=" + assignmentId : "";
  return instance.get(
    "/api/v1/campaigns/" +
      campaignId +
      "?include=villages,village.summary,village.sources" +
      filter
  );
};

export const getAnimalShelter = (campaignId: string, assignmentId?: string) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  // filter = ''
  // console.log('REMOVE THIS CHEAPHAX')
  return instance.get(
    "/api/v1/animalShelters/" +campaignId + filter
  );
};

export const getAnimalShelterByVillageId = (campaignId: string, villageId: string, assignmentId?: string) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  return instance.get(
    "/api/v1/animalShelters/" +campaignId + "/village/"+villageId+filter
  );
};

export const getCampaignSettings = (CampaignId: string) => {
  return instance.get("/campaigns/" + CampaignId + "?include=settings");
};

export const getCampaigns = () => {
  return instance.get("/campaigns/");
};

export const fetchCampaignFileScans = (
  campaignId: string,
  assignmentId?: string
) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  return instance.get(`/api/v1/campaigns/${campaignId}/scans` + filter);
};

export const fetchCampaignScanned = (campaignId: string, assignmentId?: string) => {
  let filter = assignmentId ? "?assignmentId=" + assignmentId : "";
  return instance.get(`/api/v1/campaigns/${campaignId}/scanned` + filter);
};

export const triggerScansFiles = (campaignId: string) => {
  return instance.get(`/api/v1/campaigns/${campaignId}/generateScan`);
};

export const deleteCampaign = (campaignId: string) => {
  return new Promise((resolve, reject) => {
    if (!localStorage.getItem("token")) {
      resolve(false);
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/campaign/" + campaignId, {
        headers: headers,
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      })
      .then((result: any) => {
        resolve(campaignId);
      });
  });
};

export const addCampaign = (newCampaign: Campaign) => {
  return instance.post("/campaigns/", newCampaign);
};

export const updateCampaign = (
  newCampaignData: Campaign,
  campaignId: string
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const body = newCampaignData;
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/campaigns/${campaignId}`,
    body,
    {
      headers: headers,
    }
  );
};

export const uploadCampaignImages = (campaignId: string, upload: FormData) => {
  const headers = {
    "content-type": "multipart/form-data",
    authorization: localStorage.getItem("token"),
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/campaigns/${campaignId}/upload/sources`,
    upload,
    {
      headers: headers,
    }
  );
};
export const uploadCampaignVillages = (upload: FormData, campaign: string) => {
  const headers = {
    "content-type": "multipart/form-data",
    authorization: localStorage.getItem("token"),
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/recommended/${campaign}/villages/createVillages`,
    upload,
    {
      headers: headers,
    }
  );
};
export const reportCampaignWorkerZip = (upload: FormData) => {
  const headers = {
    "content-type": "multipart/form-data",
    // "content-encoding": "gzip",
    authorization: localStorage.getItem("token"),
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/campaigns/1234/upload/activity/zip`,
    upload,
    {
      headers: headers,
    }
  );
};

export const exportData = async (
  campaignId: string,
  email: string,
  date: number
) => {
  await instance.get(`/api/v1/campaigns/${campaignId}/export/sources`, {
    params: { before: date, email: email },
  });
};
