import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { VillageDetailsBar } from "./components/VillageDetailsBar";
import { TopBar } from "./components/TopBar";
import "./CreateMode.scss";
import { VillageShape } from "./components/VillageShape";
import { UploadFile } from "./components/UploadFile";
import { normalize } from "normalizr";
import { chunkSchema } from "../../../../appRedux/schemas/Chunk";

interface ICreateModeProps {
  map: any;
  draw: any;
  campaignId: any;
  createVillagePolygon: any;
  serverDialog: any;
  serverDialogMessage: any;
  drawNewVillage: any;
  serverLoading: any;
  AddVillageAction: any;
  handleBackFromCreationMode: any;
}

export enum ECreateVillageMode {
  MANUAL,
  UPLOAD,
}

export const CreateMode = React.memo((props: ICreateModeProps) => {
  const [createMode, setCreateMode] = useState(ECreateVillageMode.MANUAL);
  const [drawVillageIds, setDrawVillageIds] = useState<any>([]);

  useEffect(() => {
    props.map.on("draw.create", createVillage);
    props.map.on("draw.update", updateVillage);

    return () => {
      props.map.off("draw.create", createVillage);
      props.map.off("draw.update", updateVillage);
    };
  }, []);

  const createVillage = async (e: any) => {
    const feature = e.features[0];
    if (drawVillageIds.length === 0) {
      setTimeout(() => props.draw.changeMode("draw_polygon"), 500);
    }
    if (drawVillageIds.length === 1) {
      props.draw.setFeatureProperty(feature.id, "portColor");
    }

    setDrawVillageIds((prevState: any) => prevState.concat(feature.id));
    await handleDrawShadowRaster(feature);
  };

  const handleDrawShadowRaster = async (feature: any) => {
    try {
      await props.createVillagePolygon.drawShadowVillage(
        feature.id,
        feature.geometry.coordinates[0]
      );
    } catch (e) {
      deleteVillage();
      props.serverDialogMessage(
        `Something went wrong with the raster, try again \n reason: ${e}`
      );
    }
  };

  const updateVillage = async (e: any) => {
    await props.createVillagePolygon.onEditVillage(
      e.features[0].id,
      e.features[0].geometry.coordinates[0]
    );
  };

  const deleteVillage = () => {
    setDrawVillageIds([]);
    props.draw.deleteAll();
    props.createVillagePolygon.onCancelCreation();
    props.draw.changeMode("draw_polygon");
  };

  const submitCreation = (name: string) => {
    const features = drawVillageIds.map((id: string) => props.draw.get(id));
    const core = features[0].geometry.coordinates[0];
    const belt = features[1] && features[1].geometry.coordinates[0];
    const coordinates = belt ? [core, belt] : [core];
    props.serverLoading(() =>
      props.AddVillageAction(
        props.campaignId,
        name,
        props.drawNewVillage,
        coordinates
      )
    );
    cancelCreateVillageMode();
  };

  const cancelCreateVillageMode = () => {
    setDrawVillageIds([]);
    props.handleBackFromCreationMode();
  };

  useEffect(() => {
    if (createMode === ECreateVillageMode.UPLOAD) props.draw.trash();
    else addControl();
  }, [createMode]);

  const handleCreateVillageMode = (mode: ECreateVillageMode) => {
    setCreateMode(mode);
    deleteVillage();
  };

  const submitCreate = (name: string) => {
    if (createMode === ECreateVillageMode.MANUAL) {
      submitCreation(name);
    }
  };

  const addControl = () => {
    props.draw.changeMode("draw_polygon");
  };
  console.log(drawVillageIds);

  return (
    <div className="create-village-mode">
      <TopBar
        addControl={addControl}
        cancelCreate={cancelCreateVillageMode}
        createMode={createMode}
        setCreateMode={handleCreateVillageMode}
      />
      {/*<EditVillageBar/>*/}
      {/*{props.drawVillageIds.length && }*/}
      {/*{createMode === ECreateVillageMode.MANUAL && <VerticalLinearStepper/>}*/}
      {createMode === ECreateVillageMode.UPLOAD ? (
        <UploadFile
          closeCard={cancelCreateVillageMode}
          serverDialog={props.serverDialog}
          campaignId={props.campaignId}
        />
      ) : (
        <VillageShape
          addControl={addControl}
          disableDraw={() => props.draw.trash()}
          isManualVillage={createMode == ECreateVillageMode.MANUAL}
          submitVillage={submitCreate}
          drawVillageIds={drawVillageIds}
          showAddBelt={!!drawVillageIds.length}
          deleteVillage={deleteVillage}
        />
      )}
    </div>
  );
});
