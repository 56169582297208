export const getFeature = (id: string, coordinates: [number, number]) => {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        id: id,
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          id: id,
          icon: id,
        },
      },
    ],
  };
};

export const getSource = (features: any) => {
  return {
    type: "geojson",
    data: features,
  };
};

export const getLayer = (
  sourceId: any,
  icon: string,
  iconSize: number = 1.5
) => {
  return {
    id: sourceId,
    minzoom: 16,
    maxzoom: 24,
    type: "symbol",
    source: sourceId,
    layout: {
      visibility: "visible",
      "icon-image": icon,
      "icon-size": iconSize,
      "icon-allow-overlap": true,
    },
  };
};
