import { GET_LOGIN, FORGOT_PASSWORD } from "../actions/AuthActions";

// INITIALIZE STATE
const initialAuthState = {
  token: "",
};

const FULFILLED = "_FULFILLED";
const PENDING = "_PENDING";

// REDUCER
const AuthReducer = (state: any = initialAuthState, action: any) => {
  const payload = action.payload;
  switch (action.type) {
    case GET_LOGIN + FULFILLED:
      if (payload.data) {
        state.token = payload.data.token;
      }
      return {
        ...state,
      };
    case FORGOT_PASSWORD + FULFILLED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default AuthReducer;
