export interface House {
  id: number;
  villageId: number;
  status: number;
  location: Location;
  history: Action[];
  createdById: number;
  reportedOn: number;
  modifiedOn: number;
}

export interface HouseHistory {
  id: number;
  status: number;
  createdByUser: User;
  reportedOn: number;
}

export interface Action {
  id: number;
  createdByUser: User;
  status: number;
  reportedOn: number;
}

export interface User {
  id: number;
  name: string;
}

export interface Location {
  longitude: number;
  latitude: number;
}

export enum HOUSE_STATUS {
  "surveyed",
  "not_at_home",
  "access_denied",
}
