export const getWaterSourcePolygonSelectedLayer = (layerId: string) => {
  return {
    id: layerId,
    minzoom: 10,
    maxzoom: 24,
    type: "line",
    source: layerId,
    paint: {
      "line-color": "#000000",
      "line-width": 3,
    },
  };
};

// export const getWsSelectedPolygonSource = (coordinates: number[]) => {
//     return {
//         type: "geojson",
//         data: {
//             type: "FeatureCollection",
//             features: [getWaterPolygonFeature([coordinates])]
//         }
//     }
// };

export const getWaterSourcePolygonLayer = (layerId: string) => {
  return {
    id: layerId,
    minzoom: 16,
    maxzoom: 24,
    type: "fill",
    source: layerId,
    paint: {
      "fill-opacity": 0.7,
      "fill-color": "#9976ff",
    },
  };
};

export const getWsPolygonSource = (wss: any) => {
  const features = wss
    .filter((w: any) => w.feature)
    .map((w: any) => {
      const feature = JSON.parse(w.feature);
      return {
        ...feature,
        properties: { ...feature.properties, deleted: w.deleted },
      };
    });
  const a = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
  // console.log(a)
  return a;
};

export const getWaterPolygonFeature = (coordinates: any) => {
  return {
    type: "Feature",
    properties: {
      wsId: "ofirvenir",
      color: "rgb(68, 215, 182)",
    },
    geometry: {
      type: "Polygon",
      coordinates,
    },
  };
};
