import {
  GET_CAMPAIGNS,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_CAMPAIGNS_SCANS_FILE,
  DOWNLOAD_SCANS_FILE,
  REFRESH_SCAN_FILE_DOWNLOADED,
  FETCH_CAMPAIGN,
  FETCH_ASSIGNMENT_STATS,
  CLEAR_ASSIGNMENT,
} from "../actions/CampaignsActions";
import { Campaign } from "../stores/campaign.interface";

export interface CampaignsState {
  campaigns: Campaign[];
  campaignId: string;
  scans: any;
  assignmentStats: {[index: number]: any};
}

// INITIALIZE STATE
const initialCampaignsState: CampaignsState = {
  campaigns: [],
  campaignId: "",
  scans: {},
  assignmentStats: {},
};

// REDUCER
const CampaignsReducer = (
  state: CampaignsState = initialCampaignsState,
  action: any
) => {
  switch (action.type) {
    case FETCH_CAMPAIGN: {

      return {
        ...state,
        campaign: action.campaign,
        campaignId: action.campaignId,
        assignmentId: action.assignmentId,
        assignmentTitle: action.assignmentTitle,
        taskType: action.taskType,
        taskSummary: action.taskSummary,
      };
    }

    case CLEAR_ASSIGNMENT: {
      return {
        ...state,
        assignmentId: null,
      };
    }

    case FETCH_ASSIGNMENT_STATS: {
      return {
        ...state,
        assignmentStats: {...state.assignmentStats, [action.id]: action.stats},
      };
    }

    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.campaigns,
      };

    case ADD_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.concat(action.campaign),
      };

    case UPDATE_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign: Campaign) => {
          return campaign.id !== action.id
            ? campaign
            : {
                ...campaign,
                name: action.campaign.name,
                country: action.campaign.country,
                settings: action.campaign.settings,
              };
        }),
      };
    case GET_CAMPAIGNS_SCANS_FILE:
      return {
        ...state,
        scans: initScanObject(Object.values(action.scans)),
      };
    case DOWNLOAD_SCANS_FILE: {
      const scan = state.scans[action.scan];
      scan.downloaded = true;
      return {
        ...state,
        scans: { ...state.scans, [action.scan]: scan },
      };
    }

    case REFRESH_SCAN_FILE_DOWNLOADED:
      return {
        ...state,
        scans: initScanObject(Object.keys(state.scans)),
      };

    default:
      return state;
  }
};
export function initScanObject(scan: any) {
  return scan.reduce((total: any, item: any) => {
    total[item] = { downloaded: false };
    return total;
  }, {});
}

export default CampaignsReducer;
