import { Modal } from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import PropTypes from "prop-types";
import "./error-template.scss";

interface Props {
  open: boolean;
  close: () => void;
  errorMessage: string;
  intl: any;
  disableBackdropClick?: boolean;
}

class ErrorTemplate extends Component<Props, any> {
  modalStyles: any = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static childContextTypes = {
    muiTheme: PropTypes.object,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }

  render() {
    const { formatMessage } = this.props.intl;

    const theme = createMuiTheme({
      typography: {},
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Modal
          className="add-employee-template"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.close}
          disableBackdropClick={this.props.disableBackdropClick ? true : false}
        >
          <div>
            <div
              style={this.modalStyles}
              className="add-employee-modal-done-contant"
            >
              <div className="modal-title">
                {formatMessage({ id: "app.appError.error" })}
              </div>
              <div className="modal-description">{this.props.errorMessage}</div>

              <div className="modal-done-txt">
                <div className="ok-btn" onClick={this.props.close}>
                  <div className="active-btn">
                    <span>{formatMessage({ id: "app.OK" })}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </MuiThemeProvider>
    );
  }
}

const styles: any = (theme: any) => ({
  paper: {
    position: "absolute",
    flex: "0 0 50%",
    maxWidth: "879px",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "15px",
    borderRadius: "10px",
  },
});

export default withStyles(styles)(injectIntl(ErrorTemplate));
