import * as React from "react";
import { useState } from "react";
import { Dialog } from "@material-ui/core";
import { uploadCampaignVillages } from "../../../../../services/CampaignsService";

interface IUploadFileProps {
  serverDialog: any;
  closeCard: any;
  campaignId: string;
}

export const UploadFile = React.memo((props: IUploadFileProps) => {
  const [mgrs, setMGRS] = useState<any>(null);
  const [villages, setVillages] = useState<any>(null);

  const uploadFile = () => {
    const onSuccess = () => {
      clearFiles();
    };

    const data = new FormData();
    data.append("villages", villages);
    data.append("mgrs", mgrs);
    props.closeCard();
    props.serverDialog(
      () => uploadCampaignVillages(data, props.campaignId),
      "The process should take up to 10 mins",
      "Upload fail",
      { onSuccess }
    );
  };

  const handleMgrsFile = (event: any) => {
    setMGRS(event.target.files[0]);
  };
  const handleVillageFile = (event: any) => {
    setVillages(event.target.files[0]);
  };
  const clearFiles = () => {
    setMGRS(null);
    setVillages(null);
  };

  return (
    <div className="add-villages-dialog">
      <div className="file-header">Upload villages json:</div>
      <input
        type="file"
        accept=".json"
        name="file"
        onChange={handleVillageFile}
      />
      <div
        className={`upload-file ${!villages ? "no-file" : ""}`}
        onClick={uploadFile}
      >
        Upload file
      </div>
    </div>
  );
});
