import { connect } from "react-redux";
import { ChunkSideBar } from "./ChunkSideBar";

const mapStateToProps = (state: any, ownProps: any) => {
  const wsPerChuck = Object.values(state.waterSource.waterSource).filter(
    (ws: any) => ws.chunk === ownProps.chunkId
  );

  return {
    currentChunk: state.villages.chunks_v2[ownProps.chunkId],
    currentChunkWaterSourcesCount: wsPerChuck.length,
    currentChunkSprayedWaterSourcesCount: wsPerChuck.filter(
      (ws: any) => ws.currentStatus === "sprayed"
    ).length,
  };
};

export default connect(mapStateToProps, null)(ChunkSideBar);
