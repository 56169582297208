import axios from "axios";
import { Campaign } from "../appRedux/stores/campaign.interface";
import { UpdateUser } from "../appRedux/stores/users.interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    authorization: localStorage.getItem("token"),
  },
});

export const promiseService = (payload: any) => {
  return new Promise((resolve, reject) => {
    resolve(payload);
  });
};

export const addObserverService = async (
  newObserverData: any
): Promise<any> => {
  if (!localStorage.getItem("token")) {
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  const data = await instance.post("/user", newObserverData);
  return data;
};

export const changeCampaignsService = (id: string, campaigns: Campaign[]) => {
  const campaignIds = campaigns.map((campaign: Campaign) => campaign.id);

  return new Promise((resolve, reject) => {
    instance
      .put("/api/v1/users/" + id + "campaigns", { campaignIds: campaignIds })
      .then((res: any) => {
        resolve({
          campaigns: campaigns,
          id: id,
          status: res.status,
        });
      })
      .catch((err: any) => {
        console.log(err.message);
        reject({ errorMessage: err.message });
      });
  });
};

export const deleteObserverService = (id: string) => {
  return new Promise((resolve, reject) => {
    if (!localStorage.getItem("token")) {
      resolve(false);
    }

    instance
      .delete("/api/v1/users/" + id)
      .then((result: any) => {
        resolve(id);
      })
      .catch((err: any) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getObserversService = () => {
  return instance.get("/user");
};

export const updateObserverService = async (
  id: string,
  newObserverData: UpdateUser
) => {
  const data = await instance.put("/api/v1/users/" + id, newObserverData);
  return data;
};
