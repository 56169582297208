import * as React from "react";
import { TextField } from "material-ui";
import { Enums } from "../../../../Enums";
import ExportData from "./exprotData/ExportData";
import { MiniVillagesTable, VillageTable } from "./villages_table/VillageTable";

import "./Campain.scss";
import { IdialogOptions } from "../../../../appRedux/actions/ManageApiDialogAction";
import { GroupGraph } from "./village/VillagesTrapsGraph";
import {
  fetchCampaignSampleResults,
} from "../../../../services/TrapService";
const styles = {
  underlineStyle: {
    borderBottomColor: "#e9e9e9",
  },
  floatingLabelStyle: {
    color: "#bababa",
    fontWeight: 300,
    top: "33px",
  },
  floatingLabelFocusStyle: {
    color: "#bababa",
  },
  inputStyle: {
    color: "rgb(45, 50, 71)",
    paddingBottom: "15px",
  },
  tlListItemStyle: {
    padding: "7px 14px 0px 14px",
  },
};

interface ICampaignProps {
  campaign: any;
  currentUserProfile: any;
  createVillage: () => void;
  createStation: () => void;
  handleFocusOnVillage: (village: any) => void;
  villages: any;
  users: any;
  serverDialog: (
    callBack: any,
    successMsg: string,
    failMsg: string,
    options?: IdialogOptions
  ) => void;
  sampleGraph: boolean;
}

interface ICampaignState {
  inputSearchVillage: string;
  openExportData: boolean;
  openVillagesTable: boolean;
  openUploadVillages: boolean;
  villages: any;
  mgrs: any;
  openSampleGraph: boolean;
}

export class CampaignSideBar extends React.Component<
  ICampaignProps,
  ICampaignState
> {
  constructor(props: ICampaignProps) {
    super(props);
    this.state = {
      inputSearchVillage: "",
      openExportData: false,
      openVillagesTable: false,
      openUploadVillages: false,
      openSampleGraph: false,
      villages: null,
      mgrs: null,
    };
  }

  handleSearchChange = (event: any) => {
    this.setState({ inputSearchVillage: event.target.value });
  };
  handleOpenExportData = (open: boolean) => {
    this.setState({ openExportData: open });
  };
  handleOpenVillageTableData = () => {
    this.setState((prevState) => ({
      ...prevState,
      openVillagesTable: !prevState.openVillagesTable,
    }));
  };

  handleOpenSampleGraph = () => {
    this.setState((prevState) => ({
      ...prevState,
      openSampleGraph: !prevState.openSampleGraph,
    }));
  };
  handleOpenUploadVillages = () => {
    this.setState((prevState) => ({
      ...prevState,
      openUploadVillages: !prevState.openUploadVillages,
    }));
  };

  render() {
    const { currentUserProfile, campaign } = this.props;
    return (
      <div className="r-container">
        {this.state.openExportData && (
          <ExportData
            campaignId={campaign.id}
            campaignName={campaign.name}
            handleExportData={this.handleOpenExportData}
            openExportData={this.state.openExportData}
          />
        )}
        {this.state.openSampleGraph && (
          <GroupGraph
            dateFieldName={"createdOn"}
            fields={["anopheles", "culex", "pupae"]}
            fetch={() => fetchCampaignSampleResults(campaign.id)}
            onClose={this.handleOpenSampleGraph}
          />
        )}
        {this.state.openVillagesTable && (
          <VillageTable
            campaignSummary={campaign.summary}
            onClick={this.props.handleFocusOnVillage}
            users={this.props.users}
            villages={this.props.villages}
            onClose={this.handleOpenVillageTableData}
            open={this.state.openVillagesTable}
          />
        )}
        <div className="campaigns-actions">
          <div
            onClick={this.handleOpenVillageTableData}
            className="villages-table-btn"
          >
            Villages Table
          </div>
          <div
            onClick={() => this.handleOpenExportData(true)}
            className="export-data"
          >
            Export data
          </div>
        </div>
        <div className="txt-container">
          <div className="camp-name">{campaign.name}</div>
          <div className="villages-count">
            <img src="/images/villages-icon.svg" alt="" />
            <span>{`${Object.keys(this.props.villages).length} villages`}</span>
          </div>

          <div className="area-data-container">
            <div className="area-km area-item">
              <img src="/images/distance-icon.svg" alt="" />
              <span>
                {getFixedPercent(campaign.summary.totalChunks / 100, 1) + "km"}
                <sup>2</sup>
              </span>
            </div>

            <div className="area-scanned area-item">
              <div className="area-scanned-icon area-scanned-icon-2" />
              <span className="now-rap">{`${getFixedPercent(
                campaign.summary.totalScanned * 100,
                0
              )}% scanned`}</span>
            </div>

            <div className="area-ws area-item">
              <img src="/images/water-bubble-icon.svg" alt="" />
              <span>{`${campaign.summary.totalSources} water bodies`}</span>
            </div>

            <div className="area-sprayed area-item">
              <div className="area-sprayed-icon area-sprayed-icon-1" />
              <span>{`${
                campaign.summary.totalSprayed
              } sprayed ${getFixedPercent(
                (campaign.summary.totalSprayed * 100) /
                  campaign.summary.totalSources
              )}%`}</span>
            </div>

            {
              <div className="area-sampled area-sampled-top area-item">
                <img src="/images/sampled-water-icon.svg" alt="" />
                <span>{`${campaign.summary.totalSampled} Samples`}</span>
                {this.props.sampleGraph && (
                  <span
                    onClick={this.handleOpenSampleGraph}
                    className="open-village-trap-btn"
                  >
                    (view)
                  </span>
                )}
              </div>
            }

            <div className="area-sampled area-sampled-top area-item">
              <img src="/images/sampled-water-icon.svg" alt="" />
              <span>{`${campaign.summary.totalPositiveAnopheles} Positive`}</span>
            </div>
            <div className="area-sampled area-sampled-top area-item">
              <img src="/images/sampled-water-icon.svg" alt="" />
              <span>{`${getFixedPercent(
                campaign.summary.totalAvgAnopheles
              )} Avg anopheles`}</span>
            </div>

            <div className="area-sampled area-sampled-top area-item">
              <div className="area-sprayed-icon area-issue-icon-1" />
              <span className="traps-count">{`Issued ${campaign.summary.totalIssued}`}</span>
            </div>
          </div>
        </div>
        <div className="search-village">
          <div className="search-container">
            <TextField
              className="txt-search-field"
              id="txt-search"
              floatingLabelText={"village name"}
              value={this.state.inputSearchVillage}
              onChange={this.handleSearchChange}
              underlineStyle={styles.underlineStyle}
              underlineFocusStyle={styles.underlineStyle}
              floatingLabelStyle={styles.floatingLabelStyle}
              floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
              inputStyle={styles.inputStyle}
            />
            <img src="/images/search-icon.svg" alt="" className="search-icon" />
          </div>

          <MiniVillagesTable
            search={this.state.inputSearchVillage}
            villages={this.props.villages}
            onClick={this.props.handleFocusOnVillage}
          />
        </div>

        {currentUserProfile !== Enums.USER_TYPES.observer && (
          <div className="new-village-btn">
            <div className="zz-btn" onClick={this.props.createVillage}>
              new village
            </div>
          </div>
        )}
        {currentUserProfile !== Enums.USER_TYPES.observer && (
         <div className="new-village-btn">
            <div className="zz-btn" onClick={this.props.createStation}>
              New Station
            </div>
          </div>
        )}
      </div>
    );
  }
}
export const getFixedPercent = (percent: number, fixed = 1) => {
  return percent ? parseFloat(percent.toFixed(fixed)) : 0;
};
