import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  },
});

export const getStations = (campaignId: string) => {
  return instance.get(`/api/v1/stations/${campaignId}`);
};

export const createStation = (campaignId: string, lat: number, lng: number, description: string) => {
  return instance.post(`/api/v1/stations/${campaignId}`, 
    [{
      "location": {
          "latitude":  lat,
          "longitude": lng
      },
      "description": description
    }]
  );
};

