export function capitalizeFirstLetter(body: string) {
  return body.charAt(0).toUpperCase() + body.slice(1);
}

export function formatDuration(startTime: any, endTime: any) {
  if (
    startTime === undefined ||
    endTime === undefined ||
    endTime <= 0 ||
    startTime <= 0
  ) {
    return "N/A";
  }
  let startTimeMS = new Date(startTime).getTime();
  let endTimeMS = new Date(endTime).getTime();
  let timeDifferenceMS = endTimeMS - startTimeMS;
  return formatDurationMS(timeDifferenceMS);
}

export function formatDurationMS(milliseconds: number) {
  if (milliseconds < 0) {
    return "N/A";
  }
  let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));

  let formattedTime = "";
  if (days > 0) {
    formattedTime += days + "d ";
  }
  formattedTime += (hours < 10 ? "0" : "") + hours + "h  " + minutes + "m";

  return formattedTime;
}
