export const Enums: Enums = {
  USER_TYPES: {
    admin: 1,
    officeWorker: 2,
    observer: 3,
    fieldWorker: 4,
    imageAnalyst: 5,
  },
  VILLAGE_STATUS: {
    defined: 1,
    inProcess: 2,
    finished: 3,
  },
  CHUCK_TYPES: {
    core: 1,
    belt: 2,
  },
  PIXEL_STATUS: {},
  WATER_SOURCE_TYPE: {
    puddle: 1,
    storage: 2,
    ditch: 3,
    swamp: 4,
    manMade: 5,
    agriculture: 6,
    other: 7,
  },
  WORK_MODES: {
    survey: 1,
    spray: 2,
    sample: 3,
    treat: 4,
  },
};

export interface Enums {
  USER_TYPES: {
    admin: number;
    officeWorker: number;
    observer: number;
    fieldWorker: number;
    imageAnalyst: number;
  };
  VILLAGE_STATUS: {
    defined: number;
    inProcess: number;
    finished: number;
  };
  CHUCK_TYPES: {
    core: number;
    belt: number;
  };
  PIXEL_STATUS: {};
  WATER_SOURCE_TYPE: {
    puddle: number;
    storage: number;
    ditch: number;
    swamp: number;
    manMade: number;
    agriculture: number;
    other: number;
  };
  WORK_MODES: {
    survey: number;
    spray: number;
    sample: number;
    treat: number;
  };
}

export enum ModalMode {
  Create = 0,
  Edit = 1,
}

export enum WorkModeMap {
  survey = 1,
  spray = 2,
  sample = 3,
  treat = 4,
}

export enum WorkTypeTask {
  sample = 0,
  spray = 1,
  survey = 2,
  qaSurvey = 3,
  qaSpray = 4,
}

export enum StatusTask {
  UNKNOWN = -1,
  PENDING = 0,
  WIP = 1,
  COMPLETED = 2,
  CANCELLED = 3,
  QA = 4,
  REDO = 5,
  APPROVED = 6,
  AUTO_APPROVED = 7,
  CONTINUE = 8
}
