import { getCurrentOrigin } from "../../../../services/GlobalService";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  showLayout,
  hideLayout
} from "../utils";
import { getShelterLayer, getShelters } from "./utils/Icon";

interface IObjectKeys {
  [key: string]: string;
}

export const shelterDrawStatus: IObjectKeys = {
  shelter: "shelter",
};

class ShelterDrawer {
  private map: any;
  private layerId = "sheltersDraw";
  private iconSize: number = 0.33;

  constructor(map: any) {
    this.map = map;
    this.drawShelters();

    addCursorIconToLayer(this.map, this.layerId);
  }

  drawShelters = () => {
    this.loadImage().then(() => {
      this.addShelters();
      this.map.addLayer(getShelterLayer(this.layerId, this.iconSize));
    });
  };

  addShelters = () => {
    this.map.addSource(this.layerId, getEmptyGeoJson());
  };


  onChangeCampaign = (shelters: any, saveShelters = true) => {
    if (this.map.getSource(this.layerId)) {
      const temp = getShelters(Object.values(shelters || [])).data;
      this.map.getSource(this.layerId).setData(temp);
    }
  };

  setLayerVisibility = (showScan: boolean) => {
    if (showScan){
       showLayout(this.layerId, this.map);
    }
    else {
      hideLayout(this.layerId, this.map);
    }
  };


  loadImage = async () => {
    return Promise.all(
      Object.values(shelterDrawStatus).map((path: string) => {
        return new Promise((res: any) => {
          this.map.loadImage(
            `${getCurrentOrigin()}/images/shelters/shelter.webp`,
            async (error: any, image: any) => {
              this.map.addImage(path, image);
              res();
            }
          );
        });
      })
    );
  };

  changeIconByZoom = (zoomNumber: number) => {
    if (this.map.getLayer(this.layerId)) {
      const newIconSize = 0.75 + 0.15 * (zoomNumber - 15);
      this.map.setLayoutProperty(this.layerId, "icon-size", newIconSize);
    }
  };
}

export default ShelterDrawer;
