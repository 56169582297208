import * as React from "react";
import { useState } from "react";
import { Dialog } from "@material-ui/core";
import InfiniteCalendar from "react-infinite-calendar";

interface IDatePickerProps {
  onSelect: any;
  date: any;
  onClose: any;
  disabledDates?: any;
}

export const DatePicker = React.memo((props: IDatePickerProps) => {
  const [state, setState] = useState("");

  const handleSelect = (date: Date) => {
    props.onClose();
    props.onSelect(new Date(date).getTime());
  };

  return (
    <Dialog open={true} className="">
      <InfiniteCalendar
        width={350}
        height={280}
        selected={props.date}
        onSelect={handleSelect}
        disabledDates={props.disabledDates || []}
      />
    </Dialog>
  );
});
