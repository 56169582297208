import "./add-campaign-template.scss";

import React, { Component, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { createMuiTheme, createStyles, makeStyles, MuiThemeProvider, Theme, useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import TextField from "material-ui/TextField";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Checkbox from "material-ui/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ReactComponent as CheckedIcon } from "./../../../assets/CheckBoxIcon.svg";
import { ReactComponent as UnCheckedIcon } from "./../../../assets/UnCheckBoxIcon.svg";
import {
  addCampaignAction,
  updateCampaignAction,
} from "../../../appRedux/actions/CampaignsActions";
import { Campaign } from "../../../appRedux/stores/campaign.interface";
import jQuery from "jquery";
import mapboxgl from "mapbox-gl";
// @ts-ignore
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Observable, timer } from "rxjs";
import { Enums, ModalMode, WorkModeMap } from "../../../Enums";
import { serverDialog } from "../../../appRedux/actions/ManageApiDialogAction";
import {
  FormValidator,
  IFormValidator,
  isArrayEmpty,
  isArrayNotEmpty,
  isNumericPositive,
} from "../../../validation/FormValidator";
import validate from "validator";
import { getCampaignSettings } from "../../../services/CampaignsService";
import _ from "lodash";
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import { WaterSourceType } from "../../../appRedux/stores/waterSources.interface";

const MILLISECONDS_IN_DAY: number = 86400000;
const BlackRadio: any = withStyles({
  root: {
    color: "rgb(45, 50, 71)",
    "&$checked": {
      color: "rgb(45, 50, 71)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

interface campaignState {
  open: boolean;
  campaign: {
    country: string;
    name: string;
    small: number;
    medium: number;
    large: number;
    huge: number;
    sampleExpiration: number;
    sprayExpiration: number;
    sprayLongerExpiration: number;
    surveyBeltRadius: number;
    surveyCoreRadius: number;
    surveyExpiration: number;
    workModes: string[];
    zoomLimit: number;
    supportedWaterSourceTypes: string[];
  };
  campaignId: any;
}

interface WaterSourceTypeSelectProps {
  supportedWaterSourceTypes: string[];
  handleSupportedWaterSourceTypesChange: (updatedList: string[]) => void;
}

const WaterSourceTypeSelect: React.FC<WaterSourceTypeSelectProps> = ({
  supportedWaterSourceTypes: supportedWaterSourceTypes,
  handleSupportedWaterSourceTypesChange,
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
        maxWidth: 300,
      },
      chips: {
        display: "flex",
        flexWrap: "wrap",
        overflowY: 'auto',
        maxHeight: '100%'
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getStyles = (name: string, personName: any, theme: Theme)=> {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const updatedList = event.target.value as string[];
    handleSupportedWaterSourceTypesChange(updatedList);
  };

  return (
    <FormControl variant='outlined'
      className={`${classes.formControl} ${classes.noLabel}`}>
      <Select
        className="txt-field form-style"
        labelId="select-multiple-watersource"
        multiple
        value={supportedWaterSourceTypes}
        onChange={handleChange}
        input={<Input id="select-multiple-watersource" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {Object.values(WaterSourceType).map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, supportedWaterSourceTypes, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  };


class AddCampaignTemplate extends Component<any, campaignState> {

  static propTypes = {
    callback: PropTypes.func.isRequired,
    modalMode: PropTypes.number.isRequired, //0 - create user , 1 - update user, 2 - delete user,
    //currentCampaign: PropTypes.object.isRequired
  };

  modalStyles: any = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    minHeight: "80%",
  };

  field_styles: any = {
    inputStyle: {
      border: "1px solid rgba(151, 151, 151, 1)",
      borderRadius: "6px",
      margin: "0px",
      height: "42px",
      maxWidth: "262px",
      width: "100%",
      padding: "0px 1rem",
    },
    errorStyle: {
      color: "#ff5b78",
      top: "3px",
      textAlign: "left",
    },
    underlineStyle: {
      display: "none",
    },
    floatingLabelStyle: {
      display: "none",
    },
    floatingLabelFocusStyle: {
      display: "none",
    },
    labelStyle: {
      color: "#051640",
      textAlign: "left",
      maxWidth: "262px",
      paddingBottom: "5px",
      fontWeight: "500",
      letterSpacing: "0.03em",
    },
    checkBoxContainerStyle: {
      width: "unset",
    },
    checkBoxIconStyle: {
      width: "unset",
      marginRight: "25px",
    },
    checkBoxLabelStyle: {
      fontSize: "14px",
      color: "rgb(45, 50, 71)",
      textAlign: "left",
      letterSpacing: "0.03em",
      width: "unset",
    },
    radioLblStyle: {
      color: "rgb(45, 50, 71)",
    },
  };

  tabs_styles: any = {
    containerStyle: {
      backgroundColor: "#ffffff",
      width: "280px",
      left: "45px",
      display: "flex",
      justifyContent: "space-between",
    },
    boxStyle: {
      backgroundColor: "#ffffff",
      color: "rgb(94, 94, 94)",
      fontSize: "14px",
    },
    buttonStyle: {
      alignItems: "flex-start",
    },
    inkBarStyle: {
      backgroundColor: "rgb(251, 193, 59)",
      width: "280px",
      left: "unset",
    },
    tab1Style: {
      width: "85.51px",
      float: "left",
    },
    tab2Style: {
      width: "95.98px",
      float: "right",
    },
  };

  private getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }

  private campaignCheckboxFields: string[] = [
    "sample",
    "spray",
    "survey",
    "qaSurvey",
    "qaSpray",
  ];

  private validate: any;
  private currentCampaign: Campaign | null = null;
  private geocoderCountryElm: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      campaignId: props.campaignId,
      open: false,
      campaign: {
        country: "",
        name: "",
        small: 5,
        medium: 5,
        large: 5,
        huge: 5,
        sampleExpiration: 7,
        sprayExpiration: 7,
        sprayLongerExpiration: 14,
        surveyBeltRadius: 30,
        surveyCoreRadius: 15,
        surveyExpiration: 365,
        workModes: [],
        zoomLimit: 17,
        supportedWaterSourceTypes: [],
      },
    };
    this.validate = new FormValidator(this.campaignFormValidate());
  }

  handleSupportedWaterSourceTypesChange = (updatedList: string[]) => {
    this.setState((prevState) => ({
      campaign: {
        ...prevState.campaign,
        supportedWaterSourceTypes: updatedList,
      },
    }));
  };

  async componentDidMount() {
    switch (this.props.modalMode) {
      case ModalMode.Edit: {
        const data = await this.getCurrentCampaignById(this.state.campaignId);
        const { name, country, settings } = data;
        this.setState({
          open: true,
          campaign: {
            name: name,
            country: country,
            sprayExpiration: settings.sprayExpiration
              ? settings.sprayExpiration / MILLISECONDS_IN_DAY
              : 0,
            sprayLongerExpiration: settings.spraySemiExpiration
              ? settings.spraySemiExpiration / MILLISECONDS_IN_DAY
              : 0,
            sampleExpiration: settings.sampleExpiration
              ? settings.sampleExpiration / MILLISECONDS_IN_DAY
              : 0,
            surveyExpiration: settings.surveyExpiration
              ? settings.surveyExpiration / MILLISECONDS_IN_DAY
              : 0,
            small: settings.small,
            medium: settings.medium,
            large: settings.large,
            huge: settings.huge,
            surveyCoreRadius: settings.surveyCoreRadius,
            surveyBeltRadius: settings.surveyBeltRadius,
            zoomLimit: settings.zoomLimit,
            workModes: settings.workModes,
            supportedWaterSourceTypes: settings.supportedWaterSourceTypes || []
          },
        });
        this.validate = new FormValidator(this.campaignFormValidate());
      }
      case ModalMode.Create: {
        this.setState({ open: true });
      }
      default:
        break;
    }

    // timer(1).subscribe(() => {
    // const txtElm: any = document.querySelector(
    //     "#geocoder-country-container input"
    // );
    // txtElm.value = this.state.campaign.country || "";
    // });
  }

  getCurrentCampaignById = async (campaignId: string) => {
    const { data } = await getCampaignSettings(campaignId);
    return data;
  };

  static childContextTypes = {
    muiTheme: PropTypes.object,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }

  handleClose = () => {
    this.props.callback();
    this.setState({ open: false });
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState: any) => ({
      campaign: {
        ...prevState.campaign,
        [name]: value,
      },
    }));
    this.validate.setValidate(name, value);
  };


  getFieldsFromToIndexs(field: string, asset = "") {
    const fieldName =
      field.charAt(0).toUpperCase() +
      field
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ");
    return (
      <div>
        <div style={this.field_styles.labelStyle}>{fieldName + asset}</div>
        <TextField
          autoComplete={"off"}
          className="txt-field form-style"
          // @ts-ignore
          value={this.state.campaign[field]}
          name={field}
          onChange={(event) => {
            this.handleChange(event);
          }}
          errorText={
            !this.validate.getValidate(field) && this.validate.getMessage(field)
          }
          errorStyle={this.field_styles.errorStyle}
          underlineStyle={this.field_styles.underlineStyle}
          underlineFocusStyle={this.field_styles.underlineStyle}
          floatingLabelStyle={this.field_styles.floatingLabelStyle}
          floatingLabelFocusStyle={this.field_styles.floatingLabelFocusStyle}
          inputStyle={this.field_styles.inputStyle}
        />
      </div>
    );
  }

  getCheckBoxFieldsFromToIndexs(
    fields: string[],
    fromIndex: number,
    toIndex: number
  ): any {
    const { formatMessage } = this.props.intl;
    const fieldsElements: any = [];
    for (let i = fromIndex; i <= toIndex; i++) {
      const fieldName = fields[i];
      fieldsElements.push(
        <div key={"checkbox-field-" + fieldName} className="checkbox-field">
          <Checkbox
            name={fieldName}
            style={this.field_styles.checkBoxContainerStyle}
            iconStyle={this.field_styles.checkBoxIconStyle}
            uncheckedIcon={<UnCheckedIcon />}
            checkedIcon={<CheckedIcon />}
            checked={this.state.campaign.workModes.includes(fieldName)}
            onCheck={(event) => this.handleCheckBoxChange(event)}
            labelStyle={this.field_styles.checkBoxLabelStyle}
            label={formatMessage({ id: "app.addCampaignPage." + fieldName })}
          />
        </div>
      );
    }
    return fieldsElements;
  }

  handleCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    const arr = [...this.state.campaign.workModes];
    if (checked) arr.push(name);
    else {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    }

    this.setState((prevState) => ({
      campaign: { ...prevState.campaign, workModes: arr },
    }));
    this.validate.setValidate("workModes", arr);
  };

  render() {
    console.log(this.state.campaign.workModes);
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { supportedWaterSourceTypes } = this.state.campaign;
    let modalTitle: string = "";

    switch (this.props.modalMode) {
      case ModalMode.Create:
        modalTitle = "app.addCampaignPage.AddCampaign";
        break;
      case ModalMode.Edit:
        modalTitle = "app.addCampaignPage.EditCampaign";
        break;
      default:
        break;
    }

    const theme = createMuiTheme({
      typography: {},
    });

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.open && (
          <div
            style={this.modalStyles}
            className={classes.paper + " add-campaign-modal-contant"}
          >
            <div className="close-btn-container">
              <img
                src="/images/close-icon.svg"
                alt=""
                className="close-btn"
                onClick={this.handleClose}
              />
            </div>
            <div className="modal-title-campaign">
              <div className="add-campaign-title">
                {formatMessage({ id: modalTitle })}
              </div>
            </div>
            <div className="modal-description">
              <div className="tab-content">
                <div className="col-5">
                  {this.getFieldsFromToIndexs("name")}
                  <div className="search-country" style={classes.labelStyle}>
                    Search country
                  </div>
                  <div
                    id="geocoder-country-container"
                    className="country-container"
                    ref={(ref: any) => {
                      if (!this.geocoderCountryElm) {
                        this.geocoderCountryElm = ref;
                        this.addGeocoderToElm(ref);
                      }
                    }}
                  />
                  <div className="controls-title checkboxs-title">
                    {formatMessage({
                      id: "app.addCampaignPage.workTypesForcampaign",
                    })}
                  </div>
                  <div className="checkboxs-container">
                    <div className="col-6">
                      {this.getCheckBoxFieldsFromToIndexs(
                        this.campaignCheckboxFields,
                        0,
                        4
                      )}
                    </div>
                  </div>
                  {this.getFieldsFromToIndexs("zoomLimit")}
                  {(supportedWaterSourceTypes) ?
                    (<>
                      <div className="search-country" style={classes.labelStyle}>
                        Select water source types
                      </div>
                      <WaterSourceTypeSelect
                        supportedWaterSourceTypes={supportedWaterSourceTypes}
                        handleSupportedWaterSourceTypesChange={
                          this.handleSupportedWaterSourceTypesChange
                        }
                      />
                    </>
                    ) : undefined
                  }
                </div>
                <div className="col-5">
                  {this.getFieldsFromToIndexs("sprayExpiration", "(days)")}
                  {this.getFieldsFromToIndexs(
                    "sprayLongerExpiration",
                    "(days)"
                  )}
                  {this.getFieldsFromToIndexs("sampleExpiration", "(days)")}
                  {this.getFieldsFromToIndexs("surveyExpiration", "(days)")}
                  <div style={this.field_styles.labelStyle}>Dips:</div>
                  <div className="sample-dips">
                    {this.getFieldsFromToIndexs("small")}
                    {this.getFieldsFromToIndexs("medium")}
                    {this.getFieldsFromToIndexs("large")}
                    {this.getFieldsFromToIndexs("huge")}
                  </div>
                  {this.getFieldsFromToIndexs("surveyCoreRadius", "(meters)")}
                  {this.getFieldsFromToIndexs("surveyBeltRadius", "(meters)")}
                </div>
                <div className="col-2 continue-col">
                  <div
                    className={`send-campaign-btn ${this.validate.isFromValid()
                        ? "active-btn"
                        : "inactive-btn"
                      }`}
                    onClick={this.handleSubmit}
                  >
                    <span>{formatMessage({ id: "app.done" })}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hide-elm" id="map-layout"></div>
          </div>
        )}
      </MuiThemeProvider>
    );
  }

  campaignFormValidate = () => {
    return {
      name: {
        method: validate.isLength,
        options: { min: 1 },
        message: "This is a required field",
        value: this.state.campaign.name,
      },
      country: {
        method: validate.isLength,
        options: { min: 1 },
        message: "This is a required field",
        value: this.state.campaign.country,
      },
      small: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.small,
      },
      medium: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.medium,
      },
      large: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.large,
      },
      huge: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.huge,
      },
      sampleExpiration: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.sampleExpiration,
      },
      sprayExpiration: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.sprayExpiration,
      },
      sprayLongerExpiration: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.sprayLongerExpiration,
      },
      surveyBeltRadius: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.surveyBeltRadius,
      },
      surveyCoreRadius: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.surveyCoreRadius,
      },
      surveyExpiration: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.surveyExpiration,
      },
      workModes: {
        method: isArrayNotEmpty,
        message: "pi",
        value: this.state.campaign.workModes,
      },
      zoomLimit: {
        method: isNumericPositive,
        message: "This is a required number field",
        value: this.state.campaign.zoomLimit,
      },
    };
  };

  private addGeocoderToElm(elm: any) {
    mapboxgl.accessToken = "" + process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: "map-layout",
    });
    const geocoder = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      types: "country",
    });
    geocoder.on("result", (res: any) => {
      this.validate.setValidate("country", res.result.place_name);
      this.setState((prevState: any) => ({
        campaign: {
          ...prevState.campaign,
          country: res.result.place_name,
        },
      }));
    });
    elm.appendChild(geocoder.onAdd(map));
  }

  private handleSubmit = () => {
    if (this.validate.isFromValid()) {
      const { campaign } = this.state;
      const newCampaign = {
        country: campaign.country,
        name: campaign.name,
        settings: {
          sprayExpiration: MILLISECONDS_IN_DAY * campaign.sprayExpiration,
          spraySemiExpiration:
            MILLISECONDS_IN_DAY * campaign.sprayLongerExpiration,
          sampleExpiration: MILLISECONDS_IN_DAY * campaign.sampleExpiration,
          zoomLimit: Number(campaign.zoomLimit),
          small: Number(campaign.small),
          medium: Number(campaign.medium),
          large: Number(campaign.large),
          huge: Number(campaign.huge),
          surveyExpiration: MILLISECONDS_IN_DAY * campaign.surveyExpiration,
          surveyCoreRadius: Number(campaign.surveyCoreRadius),
          surveyBeltRadius: Number(campaign.surveyBeltRadius),
          workModes: campaign.workModes,
          supportedWaterSourceTypes: campaign.supportedWaterSourceTypes.length === 0 ? null : campaign.supportedWaterSourceTypes
        },
      };
      console.log(newCampaign)
      switch (this.props.modalMode) {
        case ModalMode.Create:
          this.props.serverDialog(
            () => this.props.addCampaignAction(newCampaign),
            "Create Success",
            "Create Fail"
          );
          break;
        case ModalMode.Edit:
          if (this.props.campaignId) {
            this.props.serverDialog(
              () =>
                this.props.updateCampaignAction(
                  newCampaign,
                  this.props.campaignId
                ),
              "Edit Success",
              "Edit Fail"
            );
          }
          break;
      }
      this.props.callback(false);
    }
  };
}

const styles: any = (theme: any) => ({
  paper: {
    position: "absolute",
    flex: "0 0 50%",
    maxWidth: "879px",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "15px",
    borderRadius: "10px",
  },
});

const mapStateToProps = (state: any, ownProps: any) => {
  const { campaignId } = ownProps;
  return { campaignId };
};
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { addCampaignAction, updateCampaignAction, serverDialog },
    dispatch
  );

// EXPORT COMPONENT
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(AddCampaignTemplate)));
