import mgrs from "mgrs";

export const getShadowLayer = (sourceId: string) => {
  return {
    id: sourceId,
    minzoom: 2,
    maxzoom: 24,
    type: "fill",
    source: sourceId,
    paint: {
      "fill-opacity": 0.2,
      "fill-color": ["get", "color"],
      "fill-outline-color": "#ffffff",
    },
  };
};

export const getShadowSource = (id: string, features: any) => {
  return {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: Object.values(features),
      },
      properties: {
        id: id,
      },
    },
  };
};
export const getSource = (chunks: any) => {
  const features = chunks.map((chunk: any, index: number) => {
    return Object.values(chunk).map((chunk: any) =>
      toFeature(chunk, index > 0)
    );
  });
  const a = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [].concat.apply([], features),
    },
  };
  return a;
};

export const toFeature = (chunk: any, isCore: boolean) => {
  return {
    type: "Feature",
    properties: {
      color: isCore ? "#48fced" : "#fb69f2",
    },
    geometry: {
      type: "Polygon",
      coordinates: [chunk],
    },
  };
};

export const getRightChunk = (chunkCode: string) => {
  let box = mgrs.inverse(chunkCode);
  let right = [box[2] + 0.00001, box[3] - 0.00001];
  return mgrs.forward(right, 3);
};
export const getLeftChunk = (chunkCode: string) => {
  let box = mgrs.inverse(chunkCode);
  let left = [box[0] - 0.00001, box[1] + 0.00001];
  return mgrs.forward(left, 3);
};
export const getUpChunk = (chunkCode: string) => {
  let box = mgrs.inverse(chunkCode);
  let up = [box[2] - 0.00001, box[3] + 0.00001];
  return mgrs.forward(up, 3);
};
export const getDownChunk = (chunkCode: string) => {
  let box = mgrs.inverse(chunkCode);
  let down = [box[0] + 0.00001, box[1] - 0.00001];
  return mgrs.forward(down, 3);
};

export function convertChunkToPolygon(chunkCode: string) {
  const bbox = mgrs.inverse(chunkCode);
  const leftChunkMgrsStr = getLeftChunk(chunkCode);
  const leftChunkBbox = mgrs.inverse(leftChunkMgrsStr);
  const rightChunkMgrsStr = getRightChunk(chunkCode);
  const rightChunkBbox = mgrs.inverse(rightChunkMgrsStr);
  return [
    [leftChunkBbox[2], leftChunkBbox[3]],
    [bbox[2], bbox[3]],
    [rightChunkBbox[0], rightChunkBbox[1]],
    [bbox[0], bbox[1]],
    [leftChunkBbox[2], leftChunkBbox[3]],
  ];
}
