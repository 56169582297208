import { Station } from "../../../../../appRedux/stores/station.interface";
import { houseDrawStatus } from "../../houses/HousesDrawer";
import { stationDrawStatus } from "../StationsDrawer";

export const getStationLayer = (layerId: string, iconSize: number) => {
  return {
    id: layerId,
    minzoom: 12,
    maxzoom: 24,
    type: "symbol",
    source: layerId,
    layout: {
      visibility: "visible",
      "icon-image": ["get", "icon"],
      "icon-size": iconSize,
      "icon-allow-overlap": true,
    },
    filter: ["in", "icon"].concat(Object.keys(stationDrawStatus)),
  };
};

export const getStations = (stations: Station[]) => {
  const features: any = stations.map((station: Station) => {
    return getStationIconFeature(station);
  });
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features,
    },
  };
};

export const getStationIconFeature = (station: Station) => {
  return {
    type: "Feature",
    id: station.id,
    geometry: {
      type: "Point",
      coordinates: [station.location.longitude, station.location.latitude],
    },
    properties: {
      stationId: station.id,
      icon: "station",
    },
  };
};
