import { postForgotPassword, postLogin } from "../../services/AuthService";

//ACTION NAMES
export const GET_LOGIN = "GET_LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const getLoginAction = (body: any) => ({
  type: GET_LOGIN,
  payload: postLogin(body),
});

export const forgotPasswordAction = (body: any) => ({
  type: FORGOT_PASSWORD,
  payload: postForgotPassword(body),
});
