import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token")
  },
});
export const fetchWaterSourceById = (waterSourceId: string, assignmentId?: string) => {
  let url = "/waterSources/" + waterSourceId

  if (assignmentId != null) {
    url += "?assignmentId=" + assignmentId
  }
  return instance.get(url, {
    headers: {
      "Campaign-Id": localStorage.getItem("lastCampaign")
    }
  });
};
