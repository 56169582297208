import { FETCH_WATER_SOURCE } from "../actions/CampaignsActions";

const waterSource = (state: any = {}, action: any) => {
  switch (action.type) {
    case FETCH_WATER_SOURCE:
      return {
        ...state,
        waterSource: action.waterSource,
      };
    default:
      return state;
  }
};

export default waterSource;
