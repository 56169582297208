import { getCurrentOrigin } from "../../../../services/GlobalService";
import { House } from "../../../../appRedux/stores/houses.interface";
import {
  addCursorIconToLayer,
  getEmptyGeoJson,
  hideLayout,
  showLayout,
} from "../utils";
import { getFeature, getLayer, getSource } from "../traps/utils";
import { getHouseLayer as getHousesLayer, getHouses } from "./utils/Icon";

interface IObjectKeys {
  [key: string]: string;
}

export const houseDrawStatus: IObjectKeys = {
  surveyed: "surveyed",
  not_at_home: "not_at_home",
  access_denied: "access_denied",
};

class HousesDrawer {
  private map: any;
  private layerId = "housesDraw";
  private houseSelectedId = "houseSelectedMapId";
  private houses: any;
  private iconSize: number = 0.33;

  constructor(map: any) {
    this.map = map;
    this.drawHouses();

    addCursorIconToLayer(this.map, this.layerId);
    addCursorIconToLayer(this.map, this.houseSelectedId);
  }

  drawHouses = () => {
    this.loadImage().then(() => {
      this.addHouses();

      this.map.addLayer(getHousesLayer(this.layerId, this.iconSize));
      this.drawSelected();
    });
  };

  drawSelected = () => {
    this.map.addSource(
      this.houseSelectedId,
      getSource(getFeature(houseDrawStatus.selected, [0, 0]))
    );
    this.map.addLayer(
      getLayer(this.houseSelectedId, houseDrawStatus.selected, this.iconSize)
    );

    this.hideSelected();
  };

  addHouses = () => {
    this.map.addSource(this.layerId, getEmptyGeoJson());
  };

  private hideSelected = () => {
    hideLayout(this.houseSelectedId, this.map);
  };

  onChangeCampaign = (houses: any, saveHouses = true) => {
    if (saveHouses) this.houses = houses;
    if (this.map.getSource(this.layerId)) {
      const temp = getHouses(Object.values(houses || [])).data;
      this.map.getSource(this.layerId).setData(temp);
      this.hideSelected();
    }
  };

  selectHouseIcon = (house: House) => {
    this.map
      .getSource(this.houseSelectedId)
      .setData(
        getFeature(houseDrawStatus.selected, [
          house.location.longitude,
          house.location.latitude,
        ])
      );
    this.map.moveLayer(this.houseSelectedId, this.layerId);
    showLayout(this.houseSelectedId, this.map);
  };

  loadImage = async () => {
    return Promise.all(
      Object.values(houseDrawStatus).map((path: string) => {
        return new Promise((res: any) => {
          this.map.loadImage(
            `${getCurrentOrigin()}/images/houses/${path}.webp`,
            (error: any, image: any) => {
              this.map.addImage(path, image);
              res();
            }
          );
        });
      })
    );
  };

  setLayerVisibility = (layerNames: string[]) => {
    if (this.map.getLayer(this.layerId)) {
      console.log(layerNames);
      const filterArray = ["in", "icon"];
      this.map.setFilter(this.layerId, filterArray.concat(layerNames));
    }
  };

  clickHousesIcon = (
    handleSelectHouse: (houseId: string) => void,
    features: any
  ) => {
    handleSelectHouse(features[0].properties.houseId);
    this.selectHouseIcon(this.houses[features[0].properties.houseId]);
  };

  changeIconByZoom = (zoomNumber: number) => {
    if (this.map.getLayer(this.layerId)) {
      const newIconSize = 0.75 + 0.15 * (zoomNumber - 15);
      this.map.setLayoutProperty(this.layerId, "icon-size", newIconSize);
      this.map.setLayoutProperty(
        this.houseSelectedId,
        "icon-size",
        newIconSize
      );
    }
  };
}

export default HousesDrawer;
