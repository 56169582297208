import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  HouseHistory,
  HOUSE_STATUS,
} from "../../../../../../appRedux/stores/houses.interface";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

interface IHouseSideBarProps {
  formatMessage: any;
  handleVillageOnSideBar: any;
  getVillageNameById: any;
  currentHouse: any;
  users: any;
}

export const HouseSideBar: FunctionComponent<IHouseSideBarProps> = (props) => {
  if (props.currentHouse)
    return (
      <div className="r-container house-info">
        <div className={"house-contain"}>
          <div
            className="house-back-btn"
            onClick={() => {
              props.handleVillageOnSideBar(props.currentHouse.villageId);
            }}
          >
            <div className={"back-icon"}>
              <img src="/images/back-icon.svg" alt="" />
            </div>
            <div className="back-text">
              {`Village ${props.getVillageNameById(
                props.currentHouse.villageId
              )}`}
            </div>
          </div>
          <div className="house-details">
            <div className="house-name">
              {"id house " + props.currentHouse.id}
            </div>
            <div className="house-long-lat">
              <div className="house-long-lat-icon">
                <img
                  src="/images/coordinates.svg"
                  className="coordinates-icon"
                  alt="coordinates icon"
                />
              </div>
              {`${props.currentHouse.location.longitude.toFixed(
                4
              )},${props.currentHouse.location.latitude.toFixed(4)}`}
            </div>
            <div className="house-sidebar-property">
              {props.formatMessage({ id: "app.houseCreatedOn" })} :{" "}
              <span>
                {new Date(props.currentHouse.reportedOn).toLocaleString(
                  "en-GB"
                )}
              </span>
            </div>
            <div className="house-sidebar-property">
              {props.formatMessage({ id: "app.houseCurrentStatus" })} :{" "}
              <span>{HOUSE_STATUS[props.currentHouse.status]}</span>
            </div>
          </div>
          {props.currentHouse.history
            ? props.currentHouse.history
                .sort((a: any, b: any) => b.reportedOn - a.reportedOn)
                .map((house: HouseHistory) => (
                  <div key={house.id}>
                    <div className="data-from-field">
                      <div className="data-item">
                        <div className="data-shape-2 green"></div>
                        <span className="ws-sprayed pl-2">
                          <span className="bold">
                            {props.formatMessage({
                              id: "app.houseChecked",
                            })}
                          </span>
                          <br />
                          <span>
                            {new Date(house.reportedOn).toLocaleString("en-GB")}
                          </span>
                        </span>
                      </div>
                      <div className="vertical-dotted-line green"></div>
                      <div className="data-item">
                        <div className="data-shape-2 green"></div>
                        <span className="ws-sprayed pl-2">
                          <span className="bold">
                            {props.formatMessage({
                              id: "app.houseStatus",
                            })}
                          </span>
                          <br />
                          <span>{HOUSE_STATUS[house.status]}</span>
                        </span>
                      </div>
                      <div className="vertical-dotted-line green"></div>
                      <div className="data-item data-item-2-icon mb1">
                        <span className="pl-1">
                          <PersonOutlineIcon
                            fontSize="small"
                            className="_green"
                          />
                          {`By ${house.createdByUser.name}`}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
            : null}
        </div>
      </div>
    );
  return null;
};
