import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const postLogin = (body: any) => {
  return instance.post("/auth/login", body);
};

export const postForgotPassword = (body: any) => {
  return instance.post("/users/resetPassword", body);
};
