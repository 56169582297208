import * as React from "react";
import "./styles.scss";

export function BTextField(
  title: string,
  value: any,
  onchange: any,
  disabled?: boolean,
  name?: any,
  handleKeyPress?: any,
  type?: any
) {
  return (
    <div className="b_text-field">
      <div className="input_title">{title}</div>
      <input
        autoComplete="off"
        onKeyDown={handleKeyPress}
        type={type}
        name={name}
        className="input"
        onChange={(e) => onchange(e.target.value)}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}
