import * as React from "react";
import { useState } from "react";
import {
  Dialog,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./villagesTable.scss";
import { getFormatDate } from "../../../../outsiders/trapResults/TrapResultDialog";
import lodash from "lodash";
import { getFixedPercent } from "../CampaignSideBar";
import moment from "moment";
import { BSelect } from "../../../../templates/basic_componnets/Select";

interface IVillageTableProps {
  open: boolean;
  onClose: () => void;
  villages: any;
  users: any;
  onClick: any;
  campaignSummary: any;
}

const sort = (item: any) => {
  return item.sort((a: any, b: any) => (a.createdOn > b.createdOn ? -1 : 1));
};

export const getLastSpray = (village: any) => {
  const sortedSpray = village.sources.sort((a: any, b: any) =>
    (a.lastSprayedOn != null ? a.lastSprayedOn : 0) >
    (b.lastSprayedOn != null ? b.lastSprayedOn : 0)
      ? -1
      : 1
  );
  return sortedSpray[0] ? sortedSpray[0].lastSprayedOn : "---";
};

export const VillageTable = React.memo((props: IVillageTableProps) => {
  const [filter, setFilter] = useState("");
  const [isAsc, setIsAsc] = useState(false);

  const handleSetFilter = (e: any) => {
    const role = e.target.getAttribute("role");
    console.log(e.target);
    setIsAsc((prevState) => (role === filter ? !prevState : true));
    setFilter(role);
  };

  const mappingVillageData = () => {
    return [
      ...Object.values(props.villages).map((village: any) => {
        return {
          id: village.id,
          name: village.name,
          chunks: village.summary.totalChunks / 100 || 0,
          scan: getFixedPercent(village.summary.totalScanned * 100),
          sources: village.summary.totalSources,
          totalSprayed: village.summary.totalSprayed,
          totalIssued: village.summary.totalIssued,
          unsprayed:
            village.summary.totalSources -
            village.summary.totalSprayed -
            village.summary.totalIssued,
          lastSprayed: getLastSpray(village),
          sampled: village.summary.totalSampled,
          positive: village.summary.totalPositiveAnopheles,
          AvgAnopheles: getFixedPercent(village.summary.totalAvgAnopheles),
        };
      }),
      ...[
        {
          id: "campaign",
          name: "Campaign",
          chunks: "---",
          scan: "---",
          sources: "---",
          totalSprayed: "---",
          unsprayed: "---",
          sampled: "---",
          positive: "---",
          AvgAnopheles: "---",
        },
      ],
    ];
  };
  //TODO: remove or improve its sould be not clickable
  // @ts-ignore
  return (
    <Dialog
      maxWidth={false}
      onClose={props.onClose}
      open={props.open}
      className=""
    >
      <div className="villages-table">
        <Table stickyHeader className={"village-table-comp"}>
          <TableHead>
            <TableRow onClick={handleSetFilter}>
              <TableCell role={"name"} className="col">
                Village name
              </TableCell>
              <TableCell role={"chunks"} className="col col-bold">
                Area km <sup>2</sup>
              </TableCell>
              <TableCell role={"scan"} className="col">
                scanned
              </TableCell>
              <TableCell role={"sources"} className="col col-bold">
                Water bodies
              </TableCell>
              <TableCell role={"totalSprayed"} className="col col-bold">
                sprayed
              </TableCell>
              <TableCell role={"totalIssued"} className="col">
                issued
              </TableCell>
              <TableCell role={"unsprayed"} className="col">
                unsprayed
              </TableCell>
              <TableCell role={"lastSprayed"} className="col">
                last spray
              </TableCell>
              <TableCell role={"sampled"} className="col col-bold">
                Sampled
              </TableCell>
              <TableCell role={"positive"} className="col">
                Number positive
              </TableCell>
              <TableCell role={"AvgAnopheles"} className="col">
                Average Anopheles
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappingVillageData()
              .sort(
                (a: any, b: any) =>
                  (a[filter] > b[filter] ? 1 : -1) * (isAsc ? -1 : 1)
              )
              .map((village: any) => (
                <TableRow
                  className="table-row"
                  onClick={() => props.onClick(village.id)}
                  key={village.id}
                >
                  <TableCell className="col col-link">{village.name}</TableCell>
                  <TableCell className="col">{village.chunks}</TableCell>
                  <TableCell className="col">{village.scan + "%"}</TableCell>
                  <TableCell className="col">{village.sources}</TableCell>
                  <TableCell className="col">{village.totalSprayed}</TableCell>
                  <TableCell className="col">{village.totalIssued}</TableCell>
                  <TableCell className="col">{village.unsprayed}</TableCell>
                  <TableCell className="col">
                    {getFormatDate(village.lastSprayed)}
                  </TableCell>
                  <TableCell className="col">{village.sampled}</TableCell>
                  <TableCell className="col">{village.positive}</TableCell>
                  <TableCell className="col">{village.AvgAnopheles}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Dialog>
  );
});

interface miniTable {
  villages: any;
  search: string;
  onClick: any;
}

export const MiniVillagesTable = React.memo((props: miniTable) => {
  return (
    <div className="mini-villages-table">
      <Table className={""}>
        <TableHead>
          <TableRow>
            <TableCell className="small-col">Name</TableCell>
            <TableCell className="small-col">Last visit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(props.villages)
            .filter((village: any) =>
              village.name.toLowerCase().includes(props.search.toLowerCase())
            )
            .map((village: any) => (
              <TableRow
                className="table-row"
                onClick={() => props.onClick(village.id)}
                key={village.id}
              >
                <TableCell className="small-col">{village.name}</TableCell>
                <TableCell className="small-col">
                  {village.modifiedOn !== village.createdOn
                    ? moment(village.modifiedOn).format("DD.MM.YYYY")
                    : "------------"}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
});
