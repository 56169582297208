import * as React from "react";
import { FunctionComponent, useState } from "react";

interface ITopBarProps {
  cancelCreate: () => void;
}

export const TopBar: FunctionComponent<ITopBarProps> = (props) => {

  return (
    <div className="cancelation-bar">
      <div className="cancel-btn" onClick={props.cancelCreate}>
        Cancel creation
      </div>
    </div>
  );
};
