import * as React from "react";
import "./Spinner.scss";

interface IProps {
  isSpinner?: boolean;
}

interface IState {}

export class Spinner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.isSpinner) {
      return (
        <div className="spinner-background">
          <img className="spinner-spinner" src="/images/spinner.png" />
        </div>
      );
    }
    return null;
  }
}
