import { connect } from "react-redux";
import { StationSideBar } from "./StationSideBar";

const mapStateToProps = (state: any, ownProps: any) => {
  const campaign = state.campaigns.campaign;

  return {
    currentStation: state.stations[ownProps.id],
    campaign: campaign,
    users: state.usersReducer.users,
  };
};

export default connect(mapStateToProps, null)(StationSideBar);
