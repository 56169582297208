import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./yes-or-no-template.scss";
import { buttonColor } from "../../outsiders/tasks/TaskTable";

interface Props {
  open: boolean;
  close: () => void;
  continue: () => void;
  textMessage: string;
  intl: any;
}

class YesOrNoTemplate extends Component<Props, any> {
  modalStyles: any = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static childContextTypes = {
    muiTheme: PropTypes.object,
  };

  getChildContext() {
    return {
      muiTheme: getMuiTheme(),
    };
  }
  handleAccept = () => {
    this.props.continue();
    this.props.close();
  };

  render() {
    const { formatMessage } = this.props.intl;
    const theme = createMuiTheme({
      typography: {},
    });
    return (
      <MuiThemeProvider theme={theme}>
        <Modal
          className="yes-or-no-template"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <Fade in={this.props.open}>
          <div>
            <div style={this.modalStyles} className="yes-or-no-modal-contant">
              <div className="modal-title">Alert</div>
                <div className="modal-description">{this.props.textMessage}</div>
                <div className="modal-btns">
                  <Button
                    style={{backgroundColor: "#dc3545"}}
                    variant="contained" color="secondary"
                    onClick={this.props.close}>
                    Cancel
                  </Button>
                  <Button
                    style={{backgroundColor: "#28a745", marginLeft: 10}}
                    variant="contained" color="primary"
                    onClick={this.handleAccept}>
                    Okay
                  </Button>
                </div>
            </div>
          </div>
          </Fade>
        </Modal>
      </MuiThemeProvider>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {};
};

// EXPORT COMPONENT
export default connect(
  mapStateToProps,
  null
)(injectIntl(YesOrNoTemplate));
