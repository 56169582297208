import axios from "axios";
import { ITask } from "../appRedux/stores/task.interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

export const addTask = (campaignId: string, newTask: ITask) => {
  return instance.post(`/api/v1/tasks/${campaignId}`, newTask);
};

export const fetchTasks = (campaignId: string) => {
  return instance.get(`/api/v1/tasks/${campaignId}/?include=history,summary`);
};

export const fetchAssignments = (campaignId: string) => {
  return instance.get(`/api/v1/assignments/${campaignId}`);
};

export const cancelTask = (campaignId: string, taskId: string) => {
  return instance.put(`/api/v1/tasks/${campaignId}`,
  {
          "taskId": taskId,
          "status": "CANCELLED"
  });
};

export const setStatus = (campaignId: string, assignmentId: any, comment: any, status: any) => {
  return instance.put(`/api/v1/assignments/${campaignId}`,
  [{
          "id": assignmentId,
          "status": status,
          "notes": comment
  }]);
};

export const setCompleted = (campaignId: string, assignmentId: any) => {
  return instance.put(`/api/v1/assignments/${campaignId}`,
  [{
    "id": assignmentId,
    "startedOn": 1670235382639,
    "completedOn": 1670335401590
  }]);
}
